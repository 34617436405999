import { useEffect } from "react";
import { useSelector } from "react-redux";
import { getEnvVariables } from "../../../store/global/global.slice";

const CookieConsent = () => {
    const envVariables = useSelector(getEnvVariables);
    const cookieConsentDomain = envVariables?.cookie_consent_domain || "";

    useEffect(() => {
        if (cookieConsentDomain) {
            const script = document.createElement("script");
            script.setAttribute("src", "https://cdn.cookielaw.org/scripttemplates/otSDKStub.js");
            script.setAttribute("type", "text/javascript");
            script.setAttribute("charset", "UTF-8");
            script.setAttribute("data-domain-script", `${cookieConsentDomain}`);
            document.body.appendChild(script);
        }
    }, [cookieConsentDomain]);

    return <></>;
};

export default CookieConsent;
