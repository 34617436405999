import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    domainAddUpdateStatus,
    domainDataAvilable,
    domainDeleteStatus,
    getDomainList,
    getDomainListData,
    resetAddUpdateDomainStatus,
    resetDomainDeleteStatus,
    resetDomainListData,
    updateDomainListData,
} from "../../../store/CompanyManagement/slice";
import DomainGrid from "./DomainGrid";
import DomainInput from "./DomainInput";
import { showAlert } from "../../../common-ui-modules/service/redux-helper/store/slice/globalAlertHandler.slice";

const Domain = (props: any) => {
    const [updateDomain, setUpdateDomain] = useState(false);
    const [currentDomain, setCurrentDomain] = useState("");
    const [domainStatus, setDomainStatus] = useState("");
    const { selectedCompany, companyId } = props;
    const dispatch = useDispatch();

    const rowData = useSelector(getDomainListData);
    const domainDataAvailable = useSelector(domainDataAvilable);
    const domainAddUpdateSuccess = useSelector(domainAddUpdateStatus);
    const domainDeleteSuccess = useSelector(domainDeleteStatus);

    useEffect(() => {
        if (selectedCompany) {
            dispatch(getDomainList(selectedCompany));
        }
    }, [selectedCompany]);

    useEffect(() => {
        if (!domainDataAvailable) {
            dispatch(resetDomainListData());
        }
    }, [domainDataAvailable]);

    useEffect(() => {
        if (domainAddUpdateSuccess) {
            if (updateDomain) {
                dispatch(updateDomainListData(currentDomain, domainStatus));
                dispatch(
                    showAlert({
                        type: "success",
                        message: "Domain updated successfully.",
                    }),
                );
            } else {
                dispatch(getDomainList(selectedCompany));
                dispatch(
                    showAlert({
                        type: "success",
                        message: "Domain created successfully.",
                    }),
                );
            }
            dispatch(resetAddUpdateDomainStatus());
        }
    }, [domainAddUpdateSuccess]);

    useEffect(() => {
        if (domainDeleteSuccess) {
            dispatch(getDomainList(selectedCompany));
            dispatch(
                showAlert({
                    type: "success",
                    message: "Domain deleted successfully.",
                }),
            );
            dispatch(resetDomainDeleteStatus());
        }
    }, [domainDeleteSuccess]);

    return (
        <React.Fragment>
            {selectedCompany && (
                <DomainInput
                    rowData={rowData}
                    companyId={companyId}
                    setUpdateDomain={setUpdateDomain}
                />
            )}
            {selectedCompany && (
                <DomainGrid
                    rowData={rowData}
                    companyId={companyId}
                    setUpdateDomain={setUpdateDomain}
                    setCurrentDomain={setCurrentDomain}
                    setDomainStatus={setDomainStatus}
                />
            )}
        </React.Fragment>
    );
};

export default Domain;
