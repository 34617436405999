import ClayLayout from "@clayui/layout";
import ClayButton from "@clayui/button";
import "./PageNotFound.scss";

function PageNotFound() {
    return (
        <div className="error-container">
            <ClayLayout.ContainerFluid view size={false}>
                <ClayLayout.Row>
                    <ClayLayout.Col size={6}>
                        <ClayLayout.Row>
                            <h1 style={{ color: "#CC092F" }}>
                                {`The page you requested couldn't be found `}
                            </h1>
                        </ClayLayout.Row>
                        <ClayLayout.Row>
                            <ClayLayout.Col className="div-section">
                                <h2 style={{ color: "#CC092F" }} className="pt-3 pl-3 pb-1">
                                    {" "}
                                    What Could Have Caused This?
                                </h2>
                                <p
                                    className="pl-3 pr-3"
                                    style={{ color: "#4F575E", lineHeight: "1.8" }}
                                >
                                    There may have been a technical error on our site.
                                    <br />
                                    We might have removed the page during a website redesign.
                                    <br />
                                    The link you clicked could be old and no longer works.
                                    <br />
                                    Or, maybe you accidentally typed the wrong URL in the address
                                    bar. <br />
                                </p>
                            </ClayLayout.Col>
                        </ClayLayout.Row>

                        <ClayLayout.Row className=" pt-4">
                            <ClayLayout.Col className="div-section">
                                <h2 className="pt-3 pl-3 pb-1"> What Can You Do ?</h2>
                                <p className="pl-3 pr-3" style={{ color: "#4F575E" }}>
                                    You might retype the URL and try again.
                                    <br />
                                    <br />
                                    Or we could take you back to the{" "}
                                    <span>
                                        <a href="https://www.broadcom.com/"> Broadcom homepage.</a>
                                    </span>{" "}
                                    The site map and site search box, located in the top navigation
                                    header of this and every page, can also help you find what
                                    you’re looking for.
                                    <br />
                                    <br />
                                    One more thing: If you want to help us fix this issue,{" "}
                                    <span>
                                        <a href="/group/ecx/contact-support">please contact us </a>
                                    </span>
                                    and let us know what went wrong. Be sure to let us know what Web
                                    Browser and Operating System you were using when this occurred.
                                    <br />
                                    <br />
                                    Thanks,
                                    <br />
                                    The Broadcom Web Team <br />
                                </p>
                            </ClayLayout.Col>
                        </ClayLayout.Row>
                        <ClayLayout.Row justify={"start"} className="pt-4">
                            <ClayLayout.Col size={4}>
                                <a href="/company-management">
                                    <ClayButton>Back to Home</ClayButton>
                                </a>
                            </ClayLayout.Col>
                        </ClayLayout.Row>
                    </ClayLayout.Col>
                </ClayLayout.Row>
            </ClayLayout.ContainerFluid>
        </div>
    );
}
export { PageNotFound };
