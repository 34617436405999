import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";

import ClayCard from "@clayui/card";
import ClayForm from "@clayui/form";
import ClayLayout from "@clayui/layout";
import ClayButton from "@clayui/button";

import { Formik } from "formik";
import { FormControl, Alert } from "../../../../../common-ui-modules/components";
import * as Yup from "yup";

import { loadUserDetails } from "../../../../../store/CustomerQueryTool/slice";


const SearchPanel = () => {
    const dispatch = useDispatch();
    const formRef = useRef<any>("");
    const [formData, setFormData] = useState({
        email: "",
    });

    const validationSchema = Yup.object().shape({
        email: Yup.string().email("Email is invalid").required("Email is required"),
    });

    const onSubmit = (formData: any) => {

        const email = formData.email.trim();
        dispatch(loadUserDetails(email));
    };

    return (
        <React.Fragment>            
            <ClayCard>
                <ClayCard.Body>
                    <Alert
                        isToast={false}
                        alertText={`Enter email address and click "Query". This is for External Users only.`}
                    />

                    <Formik
                        initialValues={formData}
                        onSubmit={onSubmit}
                        validationSchema={validationSchema}
                        innerRef={formRef}
                        validateOnBlur={false}
                    >
                        {(formik) => (
                            <ClayForm onSubmit={formik.handleSubmit}>
                                <ClayLayout.Row justify="start" className="pt-3">
                                    <ClayLayout.Col size={2}>
                                        <div className="mt-2">
                                            Login (Email):
                                        </div>
                                    </ClayLayout.Col>
                                    <ClayLayout.Col size={4}>
                                        <FormControl
                                            control="text"
                                            id="email"
                                            name="email"
                                            size="md"
                                            placeholder="Enter Email Id"
                                            value={formik.values.email}
                                        />
                                    </ClayLayout.Col>
                                    <ClayLayout.Col size={3}>
                                        <ClayButton type="submit" displayType="primary" className="ml-2">
                                            Query
                                        </ClayButton>
                                    </ClayLayout.Col>
                                </ClayLayout.Row>
                            </ClayForm>
                        )}
                    </Formik>
                </ClayCard.Body>
            </ClayCard>
        </React.Fragment>
    );
};

export default SearchPanel;