import React from "react";
import ClayAlert from "@clayui/alert";
import { claySpriteImagePath } from "../../service/util/claySpriteImagePath";
import { IAlert } from "../../interfaces/interfaces";

const Alert = (props: IAlert) => {
    const { text, displayType, isToast = true, alertText, onClose } = props;

    const getAlert = (props) => {
        return (
            <ClayAlert
                displayType={displayType ? displayType : "info"}
                //autoClose={5000}
                key={text}
                onClose={onClose}
                spritemap={claySpriteImagePath()}
                title={text}
                {...props}
            >
                {alertText ? alertText : null}
            </ClayAlert>
        );
    };

    return (
        <React.Fragment>
            {isToast ? (
                <ClayAlert.ToastContainer>{getAlert()}</ClayAlert.ToastContainer>
            ) : (
                <React.Fragment>{getAlert()}</React.Fragment>
            )}
        </React.Fragment>
    );
};

export { Alert };
