import { AES } from "crypto-js";
import { BROADCOM_DOT_COM_DOMAIN, BROADCOM_DOT_NET_DOMAIN, VMWARE_DOT_COM } from "./constants/constants";
import cryptoRandomString from "crypto-random-string";
import * as FileSaver from "file-saver";

//To get value from cookie provided cookie name
export const getCookie = (cname: string) => {
    const name = cname + "=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === " ") {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
};

//Check if user is loggedIn
export const isLoggedIn = (envVars: any) => {
    return envVars?.csrf?.loginCookieKey
        ? getCookie(envVars.csrf.loginCookieKey).toString() === "true"
        : false;
};

export const isHavingSessionCookie = (envVars: any) => {
    return envVars?.okta?.cookieKey ? !!getCookie(envVars.okta.cookieKey).toString() : false;
};

export const setCookie = (cName: string, cValue: string) => {
    document.cookie = cName + "=" + cValue + "; " + "; path=/" + ";secure" + ";SameSite=Strict";
};

export const deleteCookie = (name: string) => {
    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:01 GMT;";
};

export const getEncryptedCookie = (email: string) => {
    const secret = cryptoRandomString({ length: 32, type: "base64" });
    const cipherText = AES.encrypt(email, secret).toString();
    return `${secret}${cipherText}`;
};

//To check if email Id is Internal email (broadcom.com/broadcom.net)
export const isInternalUser = (email: string) => {
    const emailId = email.trim().toLowerCase();
    const domain = emailId.split("@")[1];
    if (domain === BROADCOM_DOT_COM_DOMAIN || domain === BROADCOM_DOT_NET_DOMAIN) {
        return true;
    }
    return false;
};

//To check if email Id is Vmware email (vmware.com)
export const isVmWareUser = (email: string) => {
    const emailId = email.trim().toLowerCase();
    const domain = emailId.split("@")[1];
    if (domain === VMWARE_DOT_COM) {
        return true;
    }
    return false;
};

//To Download xlsx byte stream content
export const downloadFile = (response: any, fileName: string) => {
    const contentDisposition = response.headers["content-disposition"];
    const filename = contentDisposition ? contentDisposition.split("filename=")[1] : fileName;
    const blob = new Blob([response.data], { type: "application/octet-stream; charset=UTF-8" });
    FileSaver.saveAs(blob, filename);
};

export const checkIfStatePresent = (stateOptions: any, value: any) => {
    return (
        (value && stateOptions.length && stateOptions.map((i) => i.value).includes(value)) || false
    );
};
