import { useState, useEffect } from "react";

import { ClayToggle } from "@clayui/form";

function ToggleRenderer(props: any) {
    const { title, rowData } = props;
    const [toggledBool, setToggleBool] = useState(false);
    const { alertTypes } = rowData;

    useEffect(() => {
        if (rowData[title] === "A" || rowData[title] === "Y") {
            setToggleBool(true);
        } else {
            setToggleBool(false);
        }
    }, [alertTypes]);

    const onToggle = (val: any) => {
        props.onToggle(val, rowData);
        setToggleBool(val);
    };

    return (
        <div>
            <ClayToggle onToggle={(val) => onToggle(val)} toggled={toggledBool} />
        </div>
    );
}

export default ToggleRenderer;
