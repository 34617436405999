import { Fragment } from "react";
import { getLoderStatus } from "../../../service/redux-helper/store/slice/globalAPILoader.slice";
import { useSelector } from "react-redux";
import LoadingGif from "./../../../../common-ui-modules/images/loading.gif";

function GlobalAPILoader(props) {
    const { showLoader = false } = props;
    const loader = useSelector(getLoderStatus);
    return (
        <Fragment>
            {(loader || showLoader) && (
                <div className="global-loader-wrapper">
                    <img className="global-loader-icon" src={LoadingGif} />
                </div>
            )}
        </Fragment>
    );
}

export { GlobalAPILoader };
