import { api } from "../../../../common-ui-modules";
import { MY_PROFILE_API_URL } from "../constants";

export const UpdateUserInfo = (infoData) => {
    let data = {};
    if (infoData.isEnterprise) {
        data = {
            email: infoData.email,
            firstName: infoData.firstName,
            lastName: infoData.lastName,
            jobTitle: infoData.jobTitle,
            phone: infoData.phone,
            addressLine1: infoData.addressLine1,
            addressLine2: infoData.addressLine2,
            city: infoData.city,
            stateName: infoData.stateName,
            postalCode: infoData.postalCode,
            countryId: infoData.country,
            customerId: infoData.customerId,
            companyId: infoData?.company?.companyId || infoData?.companyId || null,
            otherCompanyName: infoData.otherCompanyName,
            supportId: infoData.supportId,
            sourceId: infoData.sourceId,
            registrationId: infoData.registrationId,
            // timezone:infoData.timezone,
            uuid: null,
            siteId: null,
            source: "PORTAL",
        };
    } else {
        data = {
            email: infoData.email,
            firstName: infoData.firstName,
            lastName: infoData.lastName,
            customerId: infoData.customerId,
            companyId: infoData?.company?.companyId || infoData?.companyId || null,
            sourceId: 3,
            registrationId: null,
            source: "PORTAL",
        };
    }

    const headers = {
        "Content-Type": "application/json",
    };

    return api({
        url: MY_PROFILE_API_URL.UPDATE_USER_INFO,
        method: "POST",
        data: JSON.stringify(data),
        headers,
    }).then((response) => {
        return response.data;
    });
};
