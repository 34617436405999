const SESIcon = function () {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            id="Icon_set"
            data-name="Icon set"
            viewBox="0 1 100 98"
            height="25"
            width="25"
        >
            <path d="M92.87,23.89A2,2,0,0,0,89.41,26,45.49,45.49,0,0,1,27.55,88.79l2.93-3.74L16.92,83.12,22,95.84,25,92a49.68,49.68,0,0,0,25.49,7c1.61,0,3.23-.08,4.84-.23A49.62,49.62,0,0,0,85.5,84.53,49,49,0,0,0,99.68,55.18,49.71,49.71,0,0,0,92.87,23.89Z" />
            <path d="M17.36,18.33a45.48,45.48,0,0,1,55.09-7.12L69.52,15l13.56,1.93L78,4.16,75,8A49.51,49.51,0,0,0,14.5,15.47,49,49,0,0,0,.32,44.82,49.71,49.71,0,0,0,7.13,76.11,2,2,0,0,0,10.59,74,45.35,45.35,0,0,1,17.36,18.33Z" />
            <path d="M47.68,64.46v2.83a2.32,2.32,0,1,0,4.64,0V64.46a5,5,0,1,0-4.64,0Z" />
            <path d="M26.11,50.17V74a4.63,4.63,0,0,0,4.63,4.62H69.26A4.63,4.63,0,0,0,73.89,74V50.17a4.64,4.64,0,0,0-4.63-4.63H30.74A4.64,4.64,0,0,0,26.11,50.17Zm44.1,0V74a1,1,0,0,1-.95,1H30.74a1,1,0,0,1-.95-1V50.17a1,1,0,0,1,.95-1H69.26A1,1,0,0,1,70.21,50.17Z" />
            <path d="M67.63,39.69v-.37A18,18,0,0,0,49.69,21.39h0A18,18,0,0,0,31.76,39.32v.37h3.67v-.37a14.26,14.26,0,1,1,28.52,0v.37Z" />
        </svg>
    );
};

export { SESIcon };
