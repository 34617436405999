const AlertTriangleIcon = function (props: any) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            style={{ position: "relative", left: "4px", top: "-3px" }}
        >
            <g clipPath="url(#clip0)">
                <path
                    d="M5.15339 1.63499L1.02706 8.50862C0.94198 8.65563 0.896965 8.82231 0.896488 8.99207C0.896012 9.16183 0.940091 9.32875 1.02434 9.47624C1.10859 9.62372 1.23007 9.74662 1.3767 9.83271C1.52333 9.91881 1.69 9.96509 1.86012 9.96695H10.1128C10.2829 9.96509 10.4496 9.91881 10.5962 9.83271C10.7428 9.74662 10.8643 9.62372 10.9486 9.47624C11.0328 9.32875 11.0769 9.16183 11.0764 8.99207C11.0759 8.82231 11.0309 8.65563 10.9458 8.50862L6.81951 1.63499C6.73267 1.49213 6.61038 1.37401 6.46446 1.29203C6.31854 1.21006 6.15391 1.16699 5.98645 1.16699C5.81899 1.16699 5.65436 1.21006 5.50844 1.29203C5.36252 1.37401 5.24024 1.49213 5.15339 1.63499V1.63499Z"
                    stroke="white"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M5.98633 4.375V6.31944"
                    stroke="white"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M5.98633 8.26367H5.99216"
                    stroke="white"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0">
                    <rect
                        width="11.6921"
                        height="11.6667"
                        fill="white"
                        transform="translate(0.140625)"
                    />
                </clipPath>
            </defs>
        </svg>
    );
};

export { AlertTriangleIcon };
