const CrossIcon = function (props: any) {
    let color = "";
    switch (props.color) {
        case "grey":
            color = "#4F575E";
            break;
        case "black":
            color = "#101213";
            break;
        case "red":
            color = "#cc092f";
            break;
        default:
            color = "#4F575E";
            break;
    }
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M18 6L6 18"
                stroke={color}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M6 6L18 18"
                stroke={color}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export { CrossIcon };
