import { DeleteIcon } from "../../../../common-ui-modules/components/icons/Delete";
import ClayButton from "@clayui/button";

export const DeleteButton = (props: any) => {
    const { rowData, onDelete } = props;
    return (
        <ClayButton
            displayType="link"
            data-tooltip-align="top"
            title={"Delete"}
            onClick={() => onDelete(rowData)}
        >
            <DeleteIcon color="blue" />
        </ClayButton>
    );
};
