const SortAscIcon = function (props: any) {
    let color = "";
    switch (props.color) {
        case "blue":
            color = "#005C8A";
            break;
        case "grey":
            color = "#ADB5BD";
            break;
        case "black":
            color = "#101213";
            break;
        default:
            color = "#101213";
            break;
    }

    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.1298 6.69852C11.2146 6.78334 11.257 6.88379 11.257 6.99986C11.257 7.11593 11.2146 7.21638 11.1298 7.3012C11.045 7.38602 10.9445 7.42843 10.8285 
        7.42843H4.82847C4.7124 7.42843 4.61196 7.38602 4.52713 7.3012C4.44231 7.21638 4.3999 7.11593 4.3999 6.99986C4.3999 6.88379 4.44231 6.78334 4.52713 6.69852L7.52713 
        3.69852C7.61196 3.6137 7.7124 3.57129 7.82847 3.57129C7.94454 3.57129 8.04499 3.6137 8.12981 3.69852L11.1298 6.69852Z"
                fill={color}
            />
        </svg>
    );
};

export { SortAscIcon };
