const TickBoldIcon = function (props: any) {
    let color = "";
    switch (props.color) {
        case "white":
            color = "white";
            break;
        case "light-grey":
            color = "#ADB5BD";
            break;
        case "dark-grey":
            color = "#4F575E";
            break;
        case "blue":
            color = "#005C8A";
            break;
        default:
            color = "white";
            break;
    }
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            {...props}
        >
            <path
                fill={color}
                d="M5.91535 10.74L5.90695 10.7484L2.19981 7.00024C1.8085 6.6046 1.81025 5.96718 2.20373 5.5737C2.59442 5.18301 3.22786 5.18301 3.61855 5.5737L3.62243 5.57761L5.89431 7.87466L10.2617 3.50732C10.6603 3.10866 11.3067 3.10866 11.7053 3.50732C12.104 3.90598 12.104 4.55234 11.7053 4.951L5.91581 10.7405L5.91535 10.74Z"
            />
        </svg>
    );
};

export { TickBoldIcon };
