const RoundTickIcon = function (props: any) {
    let color = "green";
    switch (props.color) {
        case "success":
        case "approved":
        case "green":
            color = "#74b816";
            break;
        case "disabled":
        case "light-grey":
            color = "#ced4da";
            break;
        case "pending":
        case "yellow":
            color = "#e9a100";
            break;
        default:
            color = "#ced4da";
            break;
    }
    return (
        <svg
            width="21"
            height="20"
            viewBox="0 0 21 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <ellipse cx="10.1536" cy="10" rx="10.0218" ry="10" fill={color} />
            <path
                d="M9.15134 11.5858L13.7573 6.98926L14.4664 7.69626L9.15134 12.9998L5.9624 9.81776L6.67094 9.11076L9.15134 11.5858Z"
                fill="white"
            />
        </svg>
    );
};

export { RoundTickIcon };
