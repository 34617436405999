import ActionRenderer from "./ActionRenderer";
import { DeleteButton } from "./DeleteIconRenderer";
import { StatusDataRenderer } from "./StatusRenderer";

function TableCellRenderer(props: any) {
    const { type } = props;

    switch (type) {
        case "action":
            return <ActionRenderer {...props} />;

        case "status":
            return <StatusDataRenderer {...props} />;
        // case "toggle":
        //     return <ToggleRenderer {...props} />;

        case "delete":
            return <DeleteButton {...props} />;

        default:
            return null;
    }
}

export default TableCellRenderer;
