const BPIcon = function () {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            id="Icon_set"
            data-name="Icon set"
            viewBox="0 12 100 76"
            height="25"
            width="25"
        >
            <path d="M4.93,34.49H28.47V31H4.93a1.45,1.45,0,0,1-1.44-1.45V17.09a1.45,1.45,0,0,1,1.44-1.45H57.47a1.45,1.45,0,0,1,1.44,1.45v7.49H62.4V17.09a4.94,4.94,0,0,0-4.93-4.94H4.93A4.94,4.94,0,0,0,0,17.09V29.55A4.94,4.94,0,0,0,4.93,34.49Z" />
            <rect x="10.03" y="21.58" width="19.14" height="3.49" />
            <path d="M4.93,58.64H28.47V55.15H4.93A1.45,1.45,0,0,1,3.49,53.7V41.23a1.45,1.45,0,0,1,1.44-1.44H28.47V36.3H4.93A4.93,4.93,0,0,0,0,41.23V53.7A4.94,4.94,0,0,0,4.93,58.64Z" />
            <rect x="10.03" y="45.73" width="19.14" height="3.49" />
            <path d="M3.49,77.85V65.38a1.45,1.45,0,0,1,1.44-1.44H28.1V60.45H4.93A4.93,4.93,0,0,0,0,65.38V77.85a4.94,4.94,0,0,0,4.93,4.94H22.47V79.3H4.93A1.45,1.45,0,0,1,3.49,77.85Z" />
            <rect x="10.03" y="69.87" width="19.14" height="3.49" />
            <path d="M44.33,23.32a2.29,2.29,0,1,0-2.28,2.29A2.28,2.28,0,0,0,44.33,23.32Z" />
            <path d="M52.09,23.32a2.29,2.29,0,1,0-2.28,2.29A2.28,2.28,0,0,0,52.09,23.32Z" />
            <path d="M32.51,33.55V70.22H36V33.55a2.24,2.24,0,0,1,2.24-2.25h49a2.25,2.25,0,0,1,2.24,2.25V70.22h3.49V33.55a5.74,5.74,0,0,0-5.73-5.73h-49A5.74,5.74,0,0,0,32.51,33.55Z" />
            <path d="M87.19,70.22V38.67a5.06,5.06,0,0,0-5-5.05H43.29a5.05,5.05,0,0,0-5,5.05V70.22h3.48V38.67a1.56,1.56,0,0,1,1.56-1.56H82.14a1.56,1.56,0,0,1,1.56,1.56V70.22Z" />
            <path d="M85.45,78.32H40a1.74,1.74,0,0,0,0,3.48H85.45a1.74,1.74,0,0,0,0-3.48Z" />
            <path d="M99.5,82.57,94.36,74a3.49,3.49,0,0,0-3-1.69H33.75a3.49,3.49,0,0,0-3,1.69l-5.14,8.6a3.48,3.48,0,0,0,3,5.28H96.51a3.48,3.48,0,0,0,3-5.28ZM28.6,84.36l5.15-8.6H91.36l5.15,8.6Z" />
        </svg>
    );
};

export { BPIcon };
