
const UserIcon = function (props: any) {
    let color = "";
    switch (props.color) {
        case "blue":
            color = "#005C8A";
            break;
        case "grey":
            color = "#ADB5BD";
            break;
        case "darkgrey":
            color = "#58606E";
            break;
        default:
            color = "#005C8A";
            break;
    }
    return (
        <svg
            width="10"
            height="10"
            viewBox="0 0 10 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fill={color}
                d="M6.88223 5.9375C6.28965 5.9375 6.00472 6.25 5.03223 6.25C4.05974 6.25 3.77687 5.9375 3.18223 5.9375C1.6502 5.9375 0.407227 7.11328 0.407227 8.5625V9.0625C0.407227 9.58008 0.851144 10 1.3983 10H8.66615C9.21331 10 9.65723 9.58008 9.65723 9.0625V8.5625C9.65723 7.11328 8.41426 5.9375 6.88223 5.9375ZM8.66615 9.0625H1.3983V8.5625C1.3983 7.63281 2.19941 6.875 3.18223 6.875C3.48368 6.875 3.97302 7.1875 5.03223 7.1875C6.09969 7.1875 6.57871 6.875 6.88223 6.875C7.86504 6.875 8.66615 7.63281 8.66615 8.5625V9.0625ZM5.03223 5.625C6.67369 5.625 8.00544 4.36523 8.00544 2.8125C8.00544 1.25977 6.67369 0 5.03223 0C3.39076 0 2.05901 1.25977 2.05901 2.8125C2.05901 4.36523 3.39076 5.625 5.03223 5.625ZM5.03223 0.9375C6.12447 0.9375 7.01437 1.7793 7.01437 2.8125C7.01437 3.8457 6.12447 4.6875 5.03223 4.6875C3.93998 4.6875 3.05008 3.8457 3.05008 2.8125C3.05008 1.7793 3.93998 0.9375 5.03223 0.9375Z"
            />
        </svg>
    );
};

export { UserIcon };
