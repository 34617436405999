
const ViewIcon = function (props: any) {
  
    return (
        <svg
            width="22"
            height="18"
            viewBox="0 0 22 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M11 0C16.392 0 20.878 3.88 21.819 9C20.879 14.12 16.392 18 11 18C5.608 18 1.122 14.12 0.181 9C1.121 3.88 5.608 0 11 0ZM11 16C13.0395 15.9996 15.0184 15.3068 16.6129 14.0352C18.2073 12.7635 19.3229 10.9883 19.777 9C19.3212 7.0133 18.2049 5.24 16.6106 3.97003C15.0163 2.70005 13.0383 2.00853 11 2.00853C8.9617 2.00853 6.98369 2.70005 5.38938 3.97003C3.79506 5.24 2.67877 7.0133 2.223 9C2.6771 10.9883 3.79267 12.7635 5.38714 14.0352C6.98161 15.3068 8.96053 15.9996 11 16V16ZM11 13.5C9.80653 13.5 8.66193 13.0259 7.81802 12.182C6.97411 11.3381 6.5 10.1935 6.5 9C6.5 7.80653 6.97411 6.66193 7.81802 5.81802C8.66193 4.97411 9.80653 4.5 11 4.5C12.1935 4.5 13.3381 4.97411 14.182 5.81802C15.0259 6.66193 15.5 7.80653 15.5 9C15.5 10.1935 15.0259 11.3381 14.182 12.182C13.3381 13.0259 12.1935 13.5 11 13.5ZM11 11.5C11.663 11.5 12.2989 11.2366 12.7678 10.7678C13.2366 10.2989 13.5 9.66304 13.5 9C13.5 8.33696 13.2366 7.70107 12.7678 7.23223C12.2989 6.76339 11.663 6.5 11 6.5C10.337 6.5 9.70107 6.76339 9.23223 7.23223C8.76339 7.70107 8.5 8.33696 8.5 9C8.5 9.66304 8.76339 10.2989 9.23223 10.7678C9.70107 11.2366 10.337 11.5 11 11.5Z"
                fill="#005C8A"
            />
        </svg>
    );
};

export { ViewIcon };
