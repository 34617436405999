import React, { useEffect, useState } from "react";
import ClayLayout from "@clayui/layout";
import { PageTitle } from "../../common-ui-modules/components";
import { COMPANY_MANAGEMENT_HEADING } from "./constants";
import CompanyManagement from "./CompanyManagement";
import { useAppSelector } from "../../store/hook";
import { getUserData } from "../../store/global/global.slice";
import { CM_TAB } from "../../utils/constants/constants";
import { AccessDenied } from "../../common-ui-modules/components/AccessDenied/AccessDenied";
import { NoAccess } from "../../common-ui-modules/components/NoAccess/NoAccess";

export default function CompanyManagementHome() {
    const userData = useAppSelector(getUserData);
    const isOEMUser = userData?.userVO?.isBSNAssistUser || false;
    document.title = CM_TAB;
    const [noAccess, setNoAccess] = useState(false);

    useEffect(() => {
        if (
            !userData?.userVO ||
            (userData && userData.userVO && !userData.userVO.isAdminUser && !isOEMUser)
        ) {
            setNoAccess(true);
        }
    }, [userData]);

    return (
        <React.Fragment>
            {isOEMUser ? (
                <AccessDenied />
            ) : noAccess ? (
                <NoAccess />
            ) : (
                <div className="body-bg pb-6" id="re-container">
                    <div className="mx-5">
                        <ClayLayout.ContainerFluid>
                            <ClayLayout.Row justify="start" className="align-items-center">
                                <ClayLayout.Col className="d-flex align-items-left ml-4" size={8}>
                                    <PageTitle title={COMPANY_MANAGEMENT_HEADING} />
                                </ClayLayout.Col>
                            </ClayLayout.Row>
                            <CompanyManagement />
                        </ClayLayout.ContainerFluid>
                    </div>
                </div>
            )}
        </React.Fragment>
    );
}
