const TokenIcon = function (props: any) {
    return (
        <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.1214 2.06508C16.35 1.97831 16.6025 1.97831 16.8311 2.06508L27.3073 6.04141C27.6957 6.18881 27.9525 6.56095 27.9525 6.97633V16.2544C27.9525 20.6722 25.0577 24.2388 22.3774 26.6125C21.0171 27.8172 19.6615 28.7601 18.6478 29.4013C18.1399 29.7226 17.7152 29.9698 17.4152 30.1379C17.2651 30.222 17.1461 30.2864 17.0631 30.3305C17.0217 30.3525 16.9892 30.3694 16.9665 30.3813L16.9396 30.3951L16.9318 30.3991L16.9293 30.4003L16.9284 30.4008C16.9281 30.401 16.9278 30.4011 16.4762 29.5089C16.0247 30.4011 16.0244 30.401 16.0241 30.4008L16.0232 30.4003L16.0207 30.3991L16.0128 30.3951L15.986 30.3813C15.9632 30.3694 15.9308 30.3525 15.8893 30.3305C15.8064 30.2864 15.6874 30.222 15.5373 30.1379C15.2373 29.9698 14.8126 29.7226 14.3047 29.4013C13.291 28.7601 11.9354 27.8172 10.5751 26.6125C7.89482 24.2388 5 20.6722 5 16.2544V6.97633C5 6.56095 5.25679 6.18881 5.64514 6.04141L16.1214 2.06508ZM16.4762 29.5089L16.0247 30.4011C16.3086 30.5448 16.6439 30.5448 16.9278 30.4011L16.4762 29.5089ZM16.4762 28.3714C16.7471 28.2189 17.1262 27.9973 17.5785 27.7111C18.5291 27.1098 19.7926 26.2301 21.0514 25.1153C23.6092 22.85 25.9525 19.7893 25.9525 16.2544V7.66638L16.4762 4.06961L7 7.66638V16.2544C7 19.7893 9.3433 22.85 11.9011 25.1153C13.1599 26.2301 14.4233 27.1098 15.3739 27.7111C15.8263 27.9973 16.2054 28.2189 16.4762 28.3714Z"
                fill="#639C13"
            />
            <g clipPath="url(#clip0)">
                <path
                    d="M15.5 15.7661C15.7517 16.1211 16.0728 16.4148 16.4416 16.6274C16.8103 16.8399 17.2181 16.9663 17.6373 16.998C18.0564 17.0296 18.4771 16.9658 18.8708 16.8109C19.2645 16.656 19.622 16.4135 19.9191 16.1L21.6774 14.2451C22.2112 13.662 22.5066 12.8811 22.4999 12.0705C22.4932 11.2599 22.185 10.4845 21.6417 9.91134C21.0984 9.33815 20.3634 9.01302 19.595 9.00598C18.8267 8.99893 18.0864 9.31054 17.5337 9.87368L16.5257 10.931"
                    stroke="#639C13"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M17.5 14.2456C17.2483 13.8906 16.9272 13.5969 16.5584 13.3844C16.1897 13.1718 15.7819 13.0454 15.3627 13.0138C14.9436 12.9821 14.5229 13.0459 14.1292 13.2008C13.7355 13.3558 13.378 13.5982 13.0809 13.9117L11.3226 15.7666C10.7888 16.3497 10.4934 17.1306 10.5001 17.9412C10.5068 18.7518 10.815 19.5272 11.3583 20.1004C11.9016 20.6736 12.6366 20.9987 13.405 21.0057C14.1733 21.0128 14.9136 20.7012 15.4663 20.138L16.4685 19.0807"
                    stroke="#639C13"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0">
                    <rect width="14" height="14" fill="white" transform="translate(9.5 8.00586)" />
                </clipPath>
            </defs>
        </svg>
    );
};

export { TokenIcon };
