const SortDescIcon = function (props: any) {
    let color = "";
    switch (props.color) {
        case "blue":
            color = "#005C8A";
            break;
        case "grey":
            color = "#ADB5BD";
            break;
        case "black":
            color = "#101213";
            break;
        default:
            color = "#101213";
            break;
    }

    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.1298 9.26995C11.2146 9.35477 11.257 9.45522 11.257 9.57129C11.257 9.68736 11.2146 9.78781 11.1298 9.87263L8.12981 12.8726C8.04499 12.9574 7.94454 12.9999 7.82847 
        12.9999C7.7124 12.9999 7.61196 12.9574 7.52713 12.8726L4.52713 9.87263C4.44231 9.78781 4.3999 9.68736 4.3999 9.57129C4.3999 9.45522 4.44231 9.35477 4.52713 
        9.26995C4.61196 9.18513 4.7124 9.14272 4.82847 9.14272H10.8285C10.9445 9.14272 11.045 9.18513 11.1298 9.26995Z"
                fill={color}
            />
        </svg>
    );
};

export { SortDescIcon };
