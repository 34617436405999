import { useEffect } from "react";
import { withErrorBoundary, useErrorBoundary } from "react-use-error-boundary";
import { showAlert } from "../../../service/redux-helper/store/slice/globalAlertHandler.slice";
import { useAppDispatch } from "../../../../utils/constants/hook";

const ErrorBoundary = withErrorBoundary((props: any) => {
    const dispatch = useAppDispatch();
    const [error] = useErrorBoundary();

    useEffect(() => {
        if (error && error.name === "ChunkLoadError")
            dispatch(
                showAlert({
                    display: "unauthenticated",
                    clossable: false,
                }),
            );
    }, [error]);

    if (error) {
        if (error.name == "ChunkLoadError") {
            return <></>;
        } else {
            const message = error instanceof Error ? error.message : (error as string);
            return (
                <>
                    <div>
                        <h1>Something went wrong</h1>
                        <div>Error: {message}</div>
                    </div>
                </>
            );
        }
    }

    return props.children;
});

export { ErrorBoundary };
