import React, { useState } from "react";
import ClayLayout from "@clayui/layout";
import CompanySearch from "./CompanySearch/CompanySearch";
import Domain from "./DomainDetails/Domain";

export default function CompanyManagement() {
    const [selectedCompany, setSelectedCompany] = useState("");
    const [companyId, setCompanyId] = useState(0);

    return (
        <React.Fragment>
            <ClayLayout.Container className="mx-3">
                <CompanySearch
                    setSelectedCompany={setSelectedCompany}
                    setCompanyId={setCompanyId}
                />
            </ClayLayout.Container>
            {selectedCompany && (
                <ClayLayout.Container className="mx-3">
                    <Domain selectedCompany={selectedCompany} companyId={companyId} />
                </ClayLayout.Container>
            )}
        </React.Fragment>
    );
}
