import ClayForm, { ClayInput } from "@clayui/form";
import { ErrorMessage, Field } from "formik";
import { IEmail } from "../../../interfaces/interfaces";

import TextError from "./TextError";

const Email = (props: IEmail) => {
    const { label, id, name, placeholder, validate, required } = props;
    return (
        <div>
            {label && (
                <label htmlFor={name} className="text-size-sm">
                    {required && <span className="text-red">* </span>}
                    {label}
                </label>
            )}
            <Field name={name} validate={validate}>
                {({ form, field }: { form: any; field: any }) => {
                    const { value } = field;

                    return (
                        <ClayForm.Group
                            className={
                                "form-group-lg " +
                                (form.errors[name] && form.touched[name] !== undefined
                                    ? "has-error"
                                    : "")
                            }
                        >
                            <ClayInput
                                type="email"
                                id={id}
                                value={value}
                                placeholder={placeholder || label}
                                {...field}
                                {...props}
                            />
                            <ErrorMessage name={name} component={TextError} />
                        </ClayForm.Group>
                    );
                }}
            </Field>
        </div>
    );
}

export default Email;
