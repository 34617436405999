import { DeleteIcon } from "./../../icons/Delete";
import React from "react";
import ClayLayout from "@clayui/layout";
import ClayButton from "@clayui/button";
import { EditIcon } from "./../../icons/Edit";
import { ViewIcon } from "../../icons/view";
function ActionRenderer(props: any) {
    const { onEditClick, onDeleteClick, rowData, onApprovalsViewClick, value } = props;
    const {
        showEditIcon = false,
        showDeleteIcon = false,
        showViewIcon = false,
        showApprovalsAction = false,
    } = props;
    return (
        <React.Fragment>
            <div className="container-fluid">
                <ClayLayout.Row justify="start" className="align-items-center ">
                    {showEditIcon && (
                        <div className="pe-cursor">
                            <EditIcon
                                onClick={() => {
                                    onEditClick && onEditClick();
                                }}
                            />
                        </div>
                    )}
                    {showDeleteIcon && (
                        <ClayButton
                            displayType="link"
                            data-tooltip-align="top"
                            title={"Delete"}
                            onClick={() => onDeleteClick(rowData)}
                        >
                            <DeleteIcon color="blue" />
                        </ClayButton>
                    )}
                    {showViewIcon && (
                        <div className="pe-cursor mr-2">
                            <DeleteIcon
                                onClick={() => {
                                    onEditClick && onEditClick();
                                }}
                            />
                        </div>
                    )}
                    {showApprovalsAction && (
                        <div className="d-flex justify-content-between">
                            <div
                                className="pe-cursor"
                                onClick={() => {
                                    onApprovalsViewClick(value);
                                }}
                            >
                                <ViewIcon />
                            </div>
                            <div
                                className="pe-cursor"
                            >
                                <svg
                                    width="18"
                                    height="20"
                                    viewBox="0 0 18 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M4 4V1C4 0.734784 4.10536 0.48043 4.29289 0.292893C4.48043 0.105357 4.73478 0 5 0H17C17.2652 0 17.5196 0.105357 17.7071 0.292893C17.8946 0.48043 18 0.734784 18 1V15C18 15.2652 17.8946 15.5196 17.7071 15.7071C17.5196 15.8946 17.2652 16 17 16H14V19C14 19.552 13.55 20 12.993 20H1.007C0.875127 20.0008 0.744397 19.9755 0.622322 19.9256C0.500247 19.8757 0.389233 19.8022 0.295659 19.7093C0.202084 19.6164 0.127793 19.5059 0.0770543 19.3841C0.0263156 19.2624 0.000129374 19.1319 0 19L0.00300002 5C0.00300002 4.448 0.453 4 1.01 4H4ZM2.003 6L2 18H12V6H2.003ZM6 4H14V14H16V2H6V4Z"
                                        fill="#005C8A"
                                    />
                                </svg>
                            </div>
                            <div
                                className="pe-cursor"
                            >
                                <svg
                                    width="21"
                                    height="17"
                                    viewBox="0 0 21 17"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M3 12.0005V5.50049C3 4.30701 3.47411 3.16242 4.31802 2.31851C5.16193 1.47459 6.30653 1.00049 7.5 1.00049C8.69347 1.00049 9.83807 1.47459 10.682 2.31851C11.5259 3.16242 12 4.30701 12 5.50049V12.5005C12 13.1635 12.2634 13.7994 12.7322 14.2683C13.2011 14.7371 13.837 15.0005 14.5 15.0005C15.163 15.0005 15.7989 14.7371 16.2678 14.2683C16.7366 13.7994 17 13.1635 17 12.5005V5.83049C16.3325 5.59458 15.7699 5.13029 15.4116 4.51968C15.0534 3.90907 14.9225 3.19146 15.0422 2.4937C15.1619 1.79593 15.5244 1.16294 16.0656 0.706614C16.6069 0.250285 17.292 0 18 0C18.708 0 19.3931 0.250285 19.9344 0.706614C20.4756 1.16294 20.8381 1.79593 20.9578 2.4937C21.0775 3.19146 20.9466 3.90907 20.5884 4.51968C20.2301 5.13029 19.6675 5.59458 19 5.83049V12.5005C19 13.694 18.5259 14.8386 17.682 15.6825C16.8381 16.5264 15.6935 17.0005 14.5 17.0005C13.3065 17.0005 12.1619 16.5264 11.318 15.6825C10.4741 14.8386 10 13.694 10 12.5005V5.50049C10 4.83745 9.73661 4.20156 9.26777 3.73272C8.79893 3.26388 8.16304 3.00049 7.5 3.00049C6.83696 3.00049 6.20107 3.26388 5.73223 3.73272C5.26339 4.20156 5 4.83745 5 5.50049V12.0005H8L4 17.0005L0 12.0005H3ZM18 4.00049C18.2652 4.00049 18.5196 3.89513 18.7071 3.70759C18.8946 3.52006 19 3.2657 19 3.00049C19 2.73527 18.8946 2.48092 18.7071 2.29338C18.5196 2.10584 18.2652 2.00049 18 2.00049C17.7348 2.00049 17.4804 2.10584 17.2929 2.29338C17.1054 2.48092 17 2.73527 17 3.00049C17 3.2657 17.1054 3.52006 17.2929 3.70759C17.4804 3.89513 17.7348 4.00049 18 4.00049Z"
                                        fill="#005C8A"
                                    />
                                </svg>
                            </div>
                            <div
                                className="pe-cursor"
                            >
                                <svg
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M10 20C4.477 20 0 15.523 0 10C0 4.477 4.477 0 10 0C15.523 0 20 4.477 20 10C20 15.523 15.523 20 10 20ZM10 18C12.1217 18 14.1566 17.1571 15.6569 15.6569C17.1571 14.1566 18 12.1217 18 10C18 7.87827 17.1571 5.84344 15.6569 4.34315C14.1566 2.84285 12.1217 2 10 2C7.87827 2 5.84344 2.84285 4.34315 4.34315C2.84285 5.84344 2 7.87827 2 10C2 12.1217 2.84285 14.1566 4.34315 15.6569C5.84344 17.1571 7.87827 18 10 18ZM5 9H15V11H5V9Z"
                                        fill="#005C8A"
                                    />
                                </svg>
                            </div>
                        </div>
                    )}
                </ClayLayout.Row>
            </div>
        </React.Fragment>
    );
}
export default ActionRenderer;
