const ArrowDownIcon = function (props: any) {
    let color = "";
    switch (props.color) {
        case "blue":
            color = "#005C8A";
            break;
        case "grey":
            color = "#ADB5BD";
            break;
        case "darkgrey":
            color = "#6A7178";
            break;
        case "black":
            color = "#101213";
            break;
        default:
            color = "#005C8A";
            break;
    }

    return (
        <svg
            width="17"
            height="11"
            viewBox="0 0 17 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.8292 1.47859C16.9408 1.59019 16.9966 1.71854 16.9966 1.86363C16.9966 2.00872 16.9408 2.13707 16.8292 2.24868L9.02784 10.05C8.91623 10.1616 8.78789 10.2174 8.6428 10.2174C8.49771 10.2174 8.36936 10.1616 8.25775 10.05L0.456412 2.24868C0.344805 2.13707 0.289001 2.00872 0.289001 1.86363C0.289001 1.71854 0.344805 1.59019 0.456412 1.47859L1.29347 0.641532C1.40507 0.529925 1.53342 0.474121 1.67851 0.474121C1.8236 0.474121 1.95195 0.529925 2.06356 0.641532L8.6428 7.22077L15.222 0.641532C15.3336 0.529925 15.462 0.474121 15.6071 0.474121C15.7522 0.474121 15.8805 0.529925 15.9921 0.641532L16.8292 1.47859Z"
                fill={color}
            />
        </svg>
    );
};

export { ArrowDownIcon };
