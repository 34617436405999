const FTPDownloadIcon = function (props: any) {
    let color = "";
    switch (props.color) {
        case "blue":
            color = "#005C8A";
            break;
        case "grey":
            color = "#ADB5BD";
            break;
        case "black":
            color = "#101213";
            break;
        default:
            color = "#005C8A";
            break;
    }

    return (
        <svg
            width="28"
            height="23"
            viewBox="0 0 28 23"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M28 10.5493C27.9074 10.9583 27.8545 11.3801 27.7223 11.7764C27.0744 13.6809 25.7785 14.959 23.8215 15.6365C23.7818 15.6493 23.7554 15.6621 23.7157 15.6621C23.7157 15.6621 23.7025 15.6493 23.676 15.6365C23.9802 14.8312 23.9405 14.0388 23.5041 13.2591C23.5702 13.2079 23.6364 13.1696 23.7157 13.1312C25.1041 12.3388 25.8182 10.8561 25.5273 9.36059C25.2364 7.8779 23.9934 6.71475 22.4198 6.48468C22.1289 6.44633 21.838 6.45911 21.5471 6.45911C20.9785 6.48468 20.5553 6.26739 20.2909 5.78167C19.7223 4.74634 18.876 4.0689 17.6727 3.8644C17.2363 3.7877 16.7603 3.80049 16.3107 3.85161C15.795 3.91552 15.3851 3.80049 15.0148 3.46816C14.3537 2.88019 13.5735 2.5223 12.6876 2.3817C10.5586 2.03659 8.3768 3.32756 7.75531 5.33431C7.72887 5.39822 7.71564 5.44935 7.70242 5.51326C7.47762 6.20348 7.05448 6.49746 6.30076 6.47189C5.21646 6.42077 4.29084 6.7531 3.52389 7.48166C1.76521 9.11774 2.12224 11.9425 4.22472 13.1185C4.30406 13.1568 4.3834 13.2079 4.47596 13.2591C4.05282 14.026 3.98671 14.8184 4.30406 15.6365C4.29084 15.6621 4.27762 15.6876 4.26439 15.7004C3.82803 15.4959 3.36522 15.3425 2.96852 15.0869C0.852812 13.7831 -0.205042 11.9042 0.0329748 9.48841C0.284215 6.95761 1.75199 5.2704 4.22472 4.40123C4.54208 4.2862 4.87266 4.23507 5.20324 4.18394C5.49415 4.1456 5.67927 4.01778 5.82473 3.74936C7.10738 1.35915 9.14375 0.0937469 11.9206 0.00427386C13.3487 -0.0468536 14.671 0.362166 15.8611 1.15464C16.1785 1.37193 16.4826 1.46141 16.8661 1.46141C18.7834 1.46141 20.3967 2.16441 21.6397 3.59598C21.9438 3.94109 22.2479 4.13281 22.724 4.19672C25.4612 4.55462 27.5769 6.62528 27.9471 9.25834C27.9603 9.33503 27.9868 9.41172 28 9.48841C28 9.84631 28 10.2042 28 10.5493Z"
                fill={color}
            />
            <path
                d="M14.1155 22.9996C13.5469 23.0123 13.1502 22.8462 12.8196 22.5266C10.4791 20.2642 8.13861 18.0019 5.7981 15.7395C5.12372 15.0876 5.12372 14.1673 5.78488 13.5154C8.12538 11.2402 10.4659 8.96508 12.8196 6.71547C13.494 6.06359 14.4328 6.06359 15.1205 6.71547C17.4742 8.97786 19.8147 11.2402 22.1552 13.5154C22.8296 14.1673 22.8164 15.0748 22.142 15.7395C19.8147 18.0019 17.461 20.2642 15.1205 22.5266C14.8428 22.8206 14.4725 22.9996 14.1155 22.9996ZM17.1304 15.3177C17.3684 15.3177 17.5667 15.3304 17.7651 15.3177C17.9767 15.3049 18.1882 15.2665 18.3866 15.2154C19.1668 14.9981 19.6163 14.4101 19.5634 13.6688C19.5106 12.9019 19.0477 12.3906 18.2279 12.3011C17.5932 12.2372 16.9453 12.3011 16.2973 12.2884C16.0857 12.2884 16.0329 12.3778 16.0329 12.5823C16.0461 13.9756 16.0329 15.3688 16.0329 16.762C16.0329 16.8387 16.0461 16.9154 16.0461 16.9921C16.4163 16.9921 16.7601 16.9921 17.1304 16.9921C17.1304 16.4297 17.1304 15.8801 17.1304 15.3177ZM9.55349 14.2312C9.55349 13.8605 9.55349 13.5154 9.55349 13.1447C10.1882 13.1447 10.7832 13.1447 11.3915 13.1447C11.3915 12.838 11.3915 12.5696 11.3915 12.2884C10.3865 12.2884 9.40803 12.2884 8.42952 12.2884C8.42952 13.8733 8.42952 15.4199 8.42952 16.9921C8.79977 16.9921 9.14357 16.9921 9.52704 16.9921C9.52704 16.353 9.52704 15.7395 9.52704 15.0876C10.1353 15.0876 10.7039 15.0876 11.2593 15.0876C11.2593 14.7936 11.2593 14.5252 11.2593 14.2312C10.6907 14.2312 10.1221 14.2312 9.55349 14.2312ZM15.6494 12.2884C14.3667 12.2884 13.137 12.2884 11.9072 12.2884C11.9072 12.5823 11.9072 12.8635 11.9072 13.1703C12.3568 13.1703 12.7799 13.1703 13.2295 13.1703C13.2295 14.4613 13.2295 15.7267 13.2295 16.9793C13.613 16.9793 13.9568 16.9793 14.3271 16.9793C14.3271 15.7011 14.3271 14.4485 14.3271 13.1575C14.7767 13.1575 15.1998 13.1575 15.6362 13.1575C15.6494 13.0553 15.6494 12.9914 15.6494 12.9275C15.6494 12.7229 15.6494 12.5184 15.6494 12.2884Z"
                fill={color}
            />
            <path
                d="M17.1437 14.4472C17.1437 14.0127 17.1437 13.5653 17.1437 13.1179C17.6858 12.9773 18.1486 13.0668 18.3469 13.3608C18.5056 13.5909 18.4924 13.9615 18.3205 14.1788C18.0957 14.4856 17.5932 14.5878 17.1437 14.4472Z"
                fill={color}
            />
        </svg>
    );
};

export { FTPDownloadIcon };
