import React from "react";
import ClayModal, { useModal } from "@clayui/modal";
import Icon from "../../../../../common-ui-modules/components/icons/Icon";
import ClayButton from "@clayui/button";
import { claySpriteImagePath } from "../../../../../common-ui-modules/service/util/claySpriteImagePath";
import { useDispatch } from "react-redux";
import { updateModalStatus } from "../../../../../store/Registration/forgot-password/slice";
import "../../pages/forgot-password/forgotPassword.scss";
import "./modal.scss";

const ModelPopUp = (props: any) => {
    const { icon = "", msg = "" } = props;
    const dispatch = useDispatch();
    const { observer, onClose } = useModal({
        onClose: () => {
            dispatch(updateModalStatus(false));
            props.modelCloseHandler();
        },
    });

    return (
        <React.Fragment>
            <ClayModal
                center="true"
                observer={observer}
                size="md"
                spritemap={claySpriteImagePath()}
                className="forgot-password-modal"
                disableAutoClose="true"
            >
                <ClayModal.Header className="modalHeader" withTitle={false}>
                    <Icon
                        type={icon === "roundTick" ? "roundTick" : "crossIconCircle"}
                        color="success"
                        size="md"
                    />
                </ClayModal.Header>
                <ClayModal.Body className="modalBody">
                    <div>{msg}</div>
                </ClayModal.Body>
                <ClayModal.Footer
                    className="modalFooter"
                    middle={
                        <ClayButton.Group>
                            <ClayButton
                                className="modalButton"
                                displayType="primary"
                                onClick={() => onClose()}
                            >
                                OK
                            </ClayButton>
                        </ClayButton.Group>
                    }
                />
            </ClayModal>
        </React.Fragment>
    );
};

export default ModelPopUp;
