const InfoIconBold = function (props: any) {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <g clipPath="url(#clip0)">
                <path
                    d="M10.0006 1.5931C5.39828 1.5931 1.66732 5.32406 1.66732 9.92643C1.66732 14.5288 5.39828 18.2598 10.0007 18.2598C14.603 18.2598 18.334 14.5288 18.334 9.92643C18.334 5.32406 14.603 1.5931 10.0006 1.5931Z"
                    stroke="#017AAD"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M10 13.2598L10 9.92643"
                    stroke="#017AAD"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M10 6.66699L9.99167 6.66699"
                    stroke="#017AAD"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0">
                    <rect
                        width="20"
                        height="20"
                        fill="white"
                        transform="translate(20 20) rotate(180)"
                    />
                </clipPath>
            </defs>
        </svg>
    );
};

export { InfoIconBold };
