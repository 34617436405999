const StatusColumnRenderer = (props: any) => {
    return (
        <div>
            <span>
                { props.rowData.isActive === "Y" ? "Active" : "Inactive" }
            </span>
        </div>
    );
};

export default StatusColumnRenderer;
