import Text from "./controls/Text";
import TextArea from "./controls/TextArea";
import Select from "./controls/Select";
import Checkbox from "./controls/Checkbox";
import { IFormikControl } from "./interfaces/interfaces";

function FomikControl(props: IFormikControl) {
    const { control } = props;
    switch (control) {
        case "text":
            return <Text {...props} />;
        case "textarea":
            return <TextArea {...props} />;
        case "select":
            return <Select {...props} />;
        case "radio":
        case "checkbox":
            return <Checkbox {...props} />;
        case "date":
        default:
            return null;
    }
}

export default FomikControl;
