export const MAIL_SENT_SUCCESS = `If the email address is valid, the provided address
will receive a password reset email. Please follow
the instructions in the email to complete the
password reset process. Contact the Support
Team for assistance if no email is received.`;
export const USER_MFA_EXCLUDED = `USER_MFA_EXCLUDED`;
export const USER_MFA_EXCLUDED_MSG = `Password reset is not allowed for this user.`;
export const INTERNAL_USER = `Internal User`;
export const INTERNAL_USER_MSG = `Password reset is not allowed for this user.`;
export const FORGOT_PASSWORD_ERROR = `FORGOT_PASSWORD_ERROR`;
export const OTP_SEND_SUCCESS = `Verification code is sent to your authorized 
mailbox. Please use the verification code to
proceed further.`;
export const INVALID_OTP_MSG = `Verification code is invalid. Please enter a correct code.`;
export const OTP_ERROR_MSG = "Verification code is required";
export const FEDERATED_USER = `Federated users are not allowed to reset the password. Please use federated portal to reset`;
export const FEDERATED_USER_MSG = INTERNAL_USER_MSG; //`Federated users are not allowed to reset the password. Please use federated portal to reset.`;
