import React, { useEffect, useState } from "react";
import ClayCard from "@clayui/card";
import ClayForm from "@clayui/form";
import ClayLayout from "@clayui/layout";
import { Formik } from "formik";
import { FormControl } from "../../../common-ui-modules/components";
import * as Yup from "yup";
import {
    getCompanyList,
    getCompanyListData,
    resetCompanyListData,
} from "../../../store/CompanyManagement/slice";
import { useDispatch, useSelector } from "react-redux";
import "../css/styles.scss";

const CompanySearch = (props: any) => {
    const dispatch = useDispatch();
    const [companyNameText, setCompanyNameText] = useState("");
    const [formData, setFormData] = useState({
        companyName: "",
    });

    const options = useSelector(getCompanyListData);

    const validationSchema = Yup.object().shape({
        companyName: Yup.string()
            .trim()
            .min(3, "Minimum 3 characters are required")
            .max(2000, "Maximum 2000 characters allowed")
            .required("Company Name is required"),
    });

    const handleCompanySelection = (data: any[]) => {
        if (data && data.length > 0) {
            if (data[0]?.value.length > 2) {
                props.setSelectedCompany(data[0]?.value);
                props.setCompanyId(data[0]?.id);
            }
        }
    };

    useEffect(() => {
        const delayUserInputSearch = setTimeout(() => {
            if (companyNameText.length > 2) {
                loadCompanyListHandler();
            } else {
                props.setSelectedCompany("");
                dispatch(resetCompanyListData());
            }
        }, 750);
        return () => {
            clearTimeout(delayUserInputSearch);
        };
    }, [companyNameText]);

    const loadCompanyListHandler = () => {
        dispatch(getCompanyList(companyNameText));
    };

    return (
        <React.Fragment>
            <ClayCard>
                <ClayCard.Body>
                    <Formik
                        initialValues={formData}
                        onSubmit={() => {/**/}}
                        validationSchema={validationSchema}
                    >
                        {(formik) => (
                            <ClayForm onSubmit={formik.handleSubmit} className="mx-4 pt-2">
                                <>
                                    <ClayLayout.Row>
                                        <ClayLayout.Col lg={5}>
                                            <FormControl
                                                control="autocomplete"
                                                name="companyName"
                                                label="Company"
                                                placeholder="Enter Company"
                                                onChange={handleCompanySelection}
                                                onInputChange={(companyName: string) =>
                                                    setCompanyNameText(companyName)
                                                }
                                                options={options}
                                                selectedText={companyNameText}
                                                minLength={3}
                                                emptyLabel={"No matching Company Name found"}
                                                showInfoIcon={true}
                                                information={
                                                    "Please enter a company name to view results"
                                                }
                                                alignPosition="top"
                                                size="md"
                                            />
                                        </ClayLayout.Col>
                                    </ClayLayout.Row>
                                </>
                            </ClayForm>
                        )}
                    </Formik>
                </ClayCard.Body>
            </ClayCard>
        </React.Fragment>
    );
};

export default CompanySearch;
