const CaseIcon = function (props: any) {
    let color = "";
    switch (props.color) {
        case "blue":
            color = "#005C8A";
            break;
        case "grey":
            color = "#ADB5BD";
            break;
        default:
            color = "#005C8A";
            break;
    }

    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M11 17.9C12.1292 17.6691 13.144 17.0554 13.8729 16.1625C14.6017 15.2697 14.9999 14.1526 15 13V10C15.0013 9.31182 14.86 8.63083 14.585 8H5.415C5.13996 8.63083 4.99866 9.31182 5 10V13C5.00014 14.1526 5.3983 15.2697 6.12714 16.1625C6.85599 17.0554 7.87081 17.6691 9 17.9V12H11V17.9ZM3.536 15.69C3.18115 14.8376 2.99897 13.9233 3 13H0V11H3V10C3 9.357 3.087 8.735 3.249 8.144L1.036 6.866L2.036 5.134L4.056 6.3C4.11975 6.19831 4.18611 6.09828 4.255 6H15.745C15.814 6.098 15.88 6.199 15.944 6.3L17.964 5.134L18.964 6.866L16.751 8.144C16.913 8.734 17 9.357 17 10V11H20V13H17C17 13.953 16.81 14.862 16.464 15.69L18.964 17.134L17.964 18.866L15.438 17.408C14.7824 18.2184 13.9537 18.8718 13.0127 19.3203C12.0718 19.7688 11.0424 20.001 10 20C8.95762 20.001 7.92822 19.7688 6.98726 19.3203C6.0463 18.8718 5.21763 18.2184 4.562 17.408L2.036 18.866L1.036 17.134L3.536 15.691V15.69ZM6 4C6 2.93913 6.42143 1.92172 7.17157 1.17157C7.92172 0.421427 8.93913 0 10 0C11.0609 0 12.0783 0.421427 12.8284 1.17157C13.5786 1.92172 14 2.93913 14 4H6Z"
                fill={color}
            />
        </svg>
    );
};

export { CaseIcon };
