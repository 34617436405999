const MFIcon = function () {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            id="Icon_set"
            data-name="Icon set"
            viewBox="0 6 100 88"
            height="25"
            width="25"
        >
            <path d="M93.44,20.09H87.52V9.55H82.11L76.33,0H23.67L17.89,9.55H12.48V20.09H6.56V93.72h5.92V98.9h0V100H41.14V96.44l2.12-1.84H56.74l2.12,1.84V100H87.52V93.72h5.92Zm-18.79,43-9.66-4L80.3,42.66Zm-2.28,2.81-10.05,5.8V61.74ZM64.32,54.73l9.81-24,8.94,3.85ZM65.4,13H80.82L73.11,25.21ZM71.22,28.7l-8.9,21.75V14.64Zm-8.9,47.41,8.94,11.7L62.32,96Zm5.76,19.32L73,90.9l5.15,4.53ZM68,77.79l11.38,3.27-5.8,4Zm-2-4.19L76,67.77l6.48,10.6Zm18.13.64-6.13-10,6.13-22.15Zm0-54.15V31.24l-8.38-3.6,8.38-13.26ZM25.62,3.46H74.38l3.68,6.09H58.86V23.92H41.14V9.55H21.94ZM19.7,42.66,35,59.1l-9.69,4Zm18,19.11v9.92l-10-5.79ZM34.6,13l-7.71,12.2L19.18,13Zm3.08,1.62V50.47l-8.9-21.75Zm-2,40L16.93,34.6l8.94-3.84ZM22.07,64.22l-6.13,10V42.07ZM24,67.78l10.11,5.83L17.48,78.38ZM32,77.8l-5.58,7.31-5.8-4Zm0,17.64H21.74L27,90.9Zm-3.27-7.62,8.94-11.69V95.89ZM15.94,14.4l8.38,13.25-8.38,3.6V14.4ZM10,90.25V23.55h2.46v66.7Zm5.92,3.47V82l8.88,6.17-8.88,7.71Zm26-2.59-.83.72V71.59h0V27.39h7.13V91.13Zm16.06,0h-6.3V27.39h7.13V71.57h0V91.85Zm26,4.87-8.88-7.82L84.06,82V96ZM90,90.25H87.52V23.55H90Z" />
        </svg>
    );
};

export { MFIcon };
