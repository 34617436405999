const BackArrowIcon = function (props: any) {
    let color = "";
    switch (props.color) {
        case "light-grey":
            color = "#ADB5BD";
            break;
        case "dark-grey":
            color = "#4F575E";
            break;
        case "white":
            color = "white";
            break;
        default:
            color = "#4F575E";
            break;
    }
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fill={color}
                d="M6.82648 12.9999L11.4614 17.6348C11.8519 18.0253 11.8519 18.6585 11.4614 19.049C11.0708 19.4395 10.4377 19.4395 10.0471 19.049L2.97607 11.9779L2.97909 11.9749L2.97607 
        11.9719L10.0471 4.9008C10.4377 4.51028 11.0708 4.51028 11.4614 4.9008C11.8519 5.29133 11.8519 5.92449 11.4614 6.31502L6.77648 10.9999H21.0261V12.9999H6.82648Z"
            />
        </svg>
    );
};

export { BackArrowIcon };
