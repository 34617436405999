import { ClayCheckbox } from "@clayui/form";

function TableFilterCheckbox(props: any) {
    const {
        handleAllCheckboxChange,
        isAllSelected,
        type,
        handleCheckboxChange,
        rowData,
        rowIndex,
    } = props;
    return type === "selectAll" ? (
        <span className="esp-checkbox-center">
            <ClayCheckbox
                checked={isAllSelected}
                onChange={(e) => handleAllCheckboxChange(e.target.checked)}
            />
        </span>
    ) : (
        <div>
            <ClayCheckbox
                checked={rowData.isChecked}
                onChange={(e) => {
                    handleCheckboxChange(rowIndex, e.target.checked);
                }}
            />
        </div>
    );
}

export { TableFilterCheckbox };
