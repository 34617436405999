const ExternalLinkIcon = function (props: any) {
    let color = "";
    switch (props.color) {
        case "blue":
            color = "#005C8A";
            break;
        case "grey":
            color = "#ADB5BD";
            break;
        case "linkBlue":
            color = "#0b5fff";
            break;
        default:
            color = "#005C8A";
            break;
    }

    return (
        <svg
            width="14"
            height="15"
            viewBox="0 0 14 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M10.6033 8.29058V12.6647C10.6033 13.0513 10.4625 13.4222 10.212 13.6956C9.96136 13.9691 9.62148 14.1227 9.26709 14.1227H1.91778C1.56339 14.1227 1.22351 13.9691 0.972918 13.6956C0.722325 13.4222 0.581543 13.0513 0.581543 12.6647V4.64552C0.581543 4.25883 0.722325 3.88798 0.972918 3.61455C1.22351 3.34111 1.56339 3.1875 1.91778 3.1875H5.9265"
                stroke={color}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M8.59912 1H12.6078V5.37408"
                stroke={color}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M5.2583 9.0191L12.6077 1"
                stroke={color}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export { ExternalLinkIcon };
