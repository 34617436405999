import ClayTable from "@clayui/table";
import { SortAscIcon } from "../../../icons/sortAsc";
import { SortDescIcon } from "../../../icons/sortDesc";
import { UnsortIcon } from "../../../icons/unsort";
import { TableFilterCheckbox } from "../table-filter/TableFilterCheckBox";

const TableHeader = (props: any) => {
    const { info, sortInfo, onHandleSort, isAllSelected, handleAllCheckboxChange } = props;

    const handleHeaderClick = (column: any) => {
        const newSortInfo = {
            order: "asc",
            column,
        };
        if (column === sortInfo.column) {
            newSortInfo.order = sortInfo.order === "asc" ? "desc" : "asc";
        }

        onHandleSort(newSortInfo);
    };

    const onClickOfCell = (e: any, item: any) => {
        const origin = e.srcElement || e.target;
        if (item.hasToggle && origin.type && origin.type === "checkbox") {
            return true;
        } else {
            item.sortable && handleHeaderClick(item.field);
        }
    };
    return (
        <ClayTable.Head>
            <ClayTable.Row>
                {info.map((item: any) => (
                    <ClayTable.Cell
                        headingCell
                        headingTitle
                        style={{
                            minWidth: item.width || "auto",
                            maxWidth: item.width || "auto",
                        }}
                        className={`${
                            item.sortable ? "pe-cursor" : ""
                        } esp-table-header esp-table-${item.field}`}
                        key={item.field}
                        onClick={(e) => onClickOfCell(e, item)}
                    >
                        <span>
                            {item.renderer ? (
                                item.renderer
                            ) : item.filter?.show && item.filter?.type === "checkbox" ? (
                                <TableFilterCheckbox
                                    type="selectAll"
                                    isSelectAll={isAllSelected}
                                    handleAllCheckboxChange={handleAllCheckboxChange}
                                />
                            ) : (
                                <span className={"pr-2"}>{item.headerName}</span>
                            )}
                            {item.sortable ? (
                                sortInfo.column === item.field ? (
                                    sortInfo.order === "asc" ? (
                                        <SortAscIcon />
                                    ) : (
                                        <SortDescIcon />
                                    )
                                ) : (
                                    <UnsortIcon />
                                )
                            ) : null}
                        </span>
                    </ClayTable.Cell>
                ))}
            </ClayTable.Row>
        </ClayTable.Head>
    );
};

export { TableHeader };
