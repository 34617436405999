const RoundPendingIcon = function (props: any) {
    return (
        <svg
            width="21"
            height="20"
            viewBox="0 0 21 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <ellipse cx="10.1526" cy="10" rx="10.0218" ry="10" fill="#E9A100" />
            <g clipPath="url(#clip0_16598:112751)">
                <path
                    d="M9.15339 5.63499L5.02706 12.5086C4.94198 12.6556 4.89696 12.8223 4.89649 12.9921C4.89601 13.1618 4.94009 13.3288 5.02434 13.4762C5.10859 13.6237 5.23007 13.7466 5.3767 13.8327C5.52333 13.9188 5.69 13.9651 5.86012 13.967H14.1128C14.2829 13.9651 14.4496 13.9188 14.5962 13.8327C14.7428 13.7466 14.8643 13.6237 14.9486 13.4762C15.0328 13.3288 15.0769 13.1618 15.0764 12.9921C15.0759 12.8223 15.0309 12.6556 14.9458 12.5086L10.8195 5.63499C10.7327 5.49213 10.6104 5.37401 10.4645 5.29203C10.3185 5.21006 10.1539 5.16699 9.98645 5.16699C9.81899 5.16699 9.65436 5.21006 9.50844 5.29203C9.36252 5.37401 9.24024 5.49213 9.15339 5.63499V5.63499Z"
                    stroke="white"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M9.98633 8.375V10.3194"
                    stroke="white"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M9.98633 12.2637H9.99216"
                    stroke="white"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_16598:112751">
                    <rect
                        width="11.6921"
                        height="11.6667"
                        fill="white"
                        transform="translate(4.14062 4)"
                    />
                </clipPath>
            </defs>
        </svg>
    );
};

export { RoundPendingIcon };
