import React from "react";
import { Table } from "../../../../../../common-ui-modules/components";

const WolkenDetails = (props: any) => {
    const wolkenDetails = props.userDetails.data.wolkenDetails;

    const columnDefs = [
        { headerName: `Role Name`, field: "roleName" },
        { headerName: `Status`, field: "status" },
        { headerName: `Wolken Instance`, field: "wolkenInstance" },
    ];

    return (
        <React.Fragment>
            <div id="wolkenDetails" className="pt-4">
                <h2 className="text-red">Wolken Details</h2>

                { wolkenDetails.length === 0 && (
                    <p className="text-red">No Details Found!</p>
                )}

                { wolkenDetails.length > 0 && (
                    <Table
                        definitions={columnDefs}
                        dataSource={wolkenDetails}
                        borderedColumns={true}
                        borderless={true}
                        headVerticalAlignment={"middle"}
                        headingNoWrap={true}
                        hover={true}
                        noWrap={true}
                        responsive={true}
                        responsiveSize={"sm"}
                        striped={true}
                        tableVerticalAlignment={"middle"}
                        showPagination={false}
                        uniqueKeyProp={"userAccessRequestId"}
                        showDeltasDropDown={false}
                    />
                )}

                <br /><hr />
            </div>
        </React.Fragment>
    );
};

export default WolkenDetails;