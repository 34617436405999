const OpenMenu = function (props: any) {
    return (
        <svg
            width="47"
            height="29"
            viewBox="0 0 47 29"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect
                x="46"
                y="28"
                width="45"
                height="27"
                rx="8.6"
                transform="rotate(-180 46 28)"
                fill="#272B30"
                stroke="#4F575E"
            />
            <path
                d="M26 15L8 15"
                stroke="#A7A8AA"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M26 21L8 21"
                stroke="#A7A8AA"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M26 9L8 9"
                stroke="#A7A8AA"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M38.25 19L34 15.25L38.25 11.5"
                stroke="#A7A8AA"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export { OpenMenu };
