
const RoundTick = function (props: any) {
    const { size = "sm" } = props;

    let color = "#1bb408";

    switch (props.color) {
        case "success":
        case "approved":
        case "green":
            color = "#1bb408";
            break;
        case "disabled":
        case "light-grey":
            color = "#ced4da";
            break;
        case "pending":
        case "yellow":
            color = "#e9a100";
            break;
    }

    let dim = 32;
    switch (size) {
        case "lg":
            dim = dim * 3;
            break;

        case "md":
            dim = dim * 2;
            break;
    }

    return (
        <svg
            width={dim}
            height={dim}
            viewBox="0 0 21 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <ellipse
                cx="10.5"
                cy="10"
                rx="10"
                ry="10"
                fill="white"
                stroke="#def8de"
                strokeWidth="1"
            />
            <path
                d="M9.15134 11.5858L13.7573 6.98926L14.4664 7.69626L9.15134 12.9998L5.9624 9.81776L6.67094 9.11076L9.15134 11.5858Z"
                fill={color}
            />
        </svg>
    );
};

export { RoundTick };
