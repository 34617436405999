import { BASEPATH } from "../../../utils/constants/constants";

// const pathName = window.location.pathname;
// const pathName = getBaseUrl();
const MY_PROFILE_API_URL = {
    ALL_MASTER: `${BASEPATH}/api/ms/registration/masterService/getCountries`,
    FETCH_STATES: `${BASEPATH}/api/ms/registration/masterService/getStates/`,
    FETCH_USER_INFO: `${BASEPATH}/api/ms/registration/userProfile/fetch`,
    FETCH_JOB_TITLES: `${BASEPATH}/api/web/registration/masterService/getJobTitles`,
    UPDATE_USER_INFO: `${BASEPATH}/api/ms/registration/userProfile/update`,
    UPDATE_PRODUCT_LINE: `${BASEPATH}/-/profilelogin/updateProductLine`,
    UPDATE_GUIDED_TOUR_STATUS: `${BASEPATH}/-/profilelogin/userGuidedTour`,
    CSP_HOME_LINK: "/",
    ECX_DASHBOARD_LINK: "/group/ecx/my-dashboard?segment={segment}",
    UPDATE_USER_PROFILE: `${BASEPATH}/-/profilelogin/updateUserProfile`,
};

export { MY_PROFILE_API_URL };
