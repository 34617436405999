import "./styles.scss";

export function AccessDenied() {
    return (
        <div className="col-md-12 error-banner">
            <h1 className="error-title">Permission Denied</h1>
            <p className="error-text">
                To view this page logout and login with your user credentials
            </p>
        </div>
    );
}
