import ClayModal, { useModal } from "@clayui/modal";
import { claySpriteImagePath } from "../../../common-ui-modules/service/util/claySpriteImagePath";
import ClayButton from "@clayui/button";
import React from "react";
import "./ModalStyle.scss";

const ConfirmationModal = (props: any) => {
    const { heading = '', message = '', btn1Text = '', btn2text = '' } = props;
    const { observer, onClose } = useModal({
        onClose: () => {
            props.setOpenModal(false);
        },
    });

    return (
        <React.Fragment>
            <ClayModal
                center="true"
                observer={observer}
                size="md"
                spritemap={claySpriteImagePath()}
                className="confirmation-modal"
            >
                <ClayModal.Header className="modalHeader">
                    <div>{heading}</div>
                </ClayModal.Header>
                <ClayModal.Body className="modalBody">
                    <p>{message}</p>
                </ClayModal.Body>
                <ClayModal.Footer
                    className="modalFooter"
                    middle={
                        <ClayButton.Group spaced>
                            <ClayButton
                                className="modalButton"
                                displayType="secondary"
                                onClick={() => { onClose(); props.btn1Handler() }}
                            >
                                {btn1Text}
                            </ClayButton>
                            <ClayButton
                                className="modalButton"
                                displayType="primary"
                                onClick={() => { onClose(); props.btn2Handler() }}
                            >
                                {btn2text}
                            </ClayButton>
                        </ClayButton.Group>
                    }
                />
            </ClayModal>
        </React.Fragment>
    )
}

export default ConfirmationModal;