const MinimizeIcon = function (props: any) {
    let color = "";
    switch (props.color) {
        case "light-grey":
            color = "#ADB5BD";
            break;
        case "dark-grey":
            color = "#4F575E";
            break;
        case "white":
            color = "white";
            break;
        default:
            color = "#4F575E";
            break;
    }
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fill={color}
                d="M4 14H10V20"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                fill={color}
                d="M20 10H14V4"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                fill={color}
                d="M14 10L21 3"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                fill={color}
                d="M3 21L10 14"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export { MinimizeIcon };
