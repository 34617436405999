import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../../common-ui-modules/service/redux-helper/api-action-creator";
import { URL } from "./constant";

const defaultState = {
    showLDSConfigMod: false,
    userDetails: undefined,
    targetUri: "",
    userInfoVO: "",
    envVariables: null,
    userDetailsLoading: false,
    userDetailsError: false,
    heartbeat: {},
    heartbeatCalling: true,
};

const slice = createSlice({
    name: "global",
    initialState: { ...defaultState },
    reducers: {
        updateShowLDSConfigMod: (global, action) => {
            global.showLDSConfigMod = action.payload;
        },
        setUserDetails: (global, action) => {
            global.userDetailsLoading = false;
            global.userDetails = action.payload;
            global.userInfoVO = action.payload.profileDetails;
        },
        setEnvVariables: (global, action) => {
            global.envVariables = action.payload;
            global.targetUri = action.payload.okta.oktaDomain + action.payload.okta.settings;
        },
        setUserDetailsLoading: (global, action) => {
            global.userDetailsLoading = action.payload;
        },
        setUserDetailsError: (global, action) => {
            global.userDetailsError = action.payload;
        },
        setHeartbeat: (global, action) => {
            if (action.payload && action.payload.data) {
                global.heartbeat = action.payload.data;
            }
        },
        stopHeartbeatCall: (global) => {
            global.heartbeatCalling = false;
        },
    },
});

export const {
    updateShowLDSConfigMod,
    setUserDetails,
    setEnvVariables,
    setUserDetailsLoading,
    setUserDetailsError,
    setHeartbeat,
    stopHeartbeatCall,
} = slice.actions;

export const loadUserDetails = () => (dispatch: any) => {
    dispatch(setUserDetailsLoading(true));
    return dispatch(
        apiCallBegan({
            url: URL.GET_USER_DETAILS,
            method: "GET",
            onSuccess: setUserDetails.type,
            skipErrorHandling: true,
        }),
    );
};

export const updateEnvironmentVariables = () => (dispatch: any) => {
    return dispatch(
        apiCallBegan({
            url: URL.GET_ENV,
            method: "GET",
            onSuccess: setEnvVariables.type,
            skipErrorHandling: true,
        }),
    );
};

export const loadHeartbeat = () => (dispatch: any, state: any) => {
    return dispatch(
        apiCallBegan({
            url: URL.GET_HEARTBEAT,
            method: "GET",
            onSuccess: setHeartbeat.type,
            skipErrorHandling: true,
            hideLoader: true,
        }),
    );
};

//selector
export const getShowLDSConfigMod = (state: any) => state.global.showLDSConfigMod;
export const getUserDetails = (state: any) => state.global.userDetails;
export const getTargetUri = (state: any) => state.global.targetUri;
export const getEnvVariables = (state: any) => state.global.envVariables;
export const getUserData = (state: any) => state.global.userInfoVO;
export const getUserDetailsLoading = (state: any) => state.global.userDetailsLoading;
export const getUserDetailsError = (state: any) => state.global.userDetailsError;
export const getHeartbeat = (state: any) => state.global.heartbeat;
export const getHeartbeatCalling = (state: any) => state.global.heartbeatCalling;

// reducer
export const globalReducer = slice.reducer;
