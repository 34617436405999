const HelpCircle = function () {
    return (
        <svg
            width="18"
            height="19"
            viewBox="0 0 18 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M9 17.5C13.4183 17.5 17 13.9183 17 9.5C17 5.08172 13.4183 1.5 9 1.5C4.58172 1.5 1 5.08172 1 9.5C1 13.9183 4.58172 17.5 9 17.5Z"
                stroke="#005C8A"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M6.67188 7.09967C6.85996 6.56501 7.2312 6.11416 7.71984 5.82698C8.20848 5.5398 8.78299 5.43482 9.34162 5.53064C9.90024 5.62646 10.4069 5.91689 10.7719 6.3505C11.1369 6.7841 11.3367 7.33289 11.3359 7.89967C11.3359 9.49967 8.93587 10.2997 8.93587 10.2997"
                stroke="#005C8A"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M9 13.5H9.008"
                stroke="#005C8A"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export { HelpCircle };
