import ClayForm, { ClaySelectWithOption } from "@clayui/form";
import { Field, ErrorMessage } from "formik";
import { ISelect } from "../interfaces/interfaces";
import TextError from "./TextError";

function Select(props: ISelect) {
    const { label, id, name, options, isMandatory } = props;
    return (
        <div>
            <label htmlFor={name}>
                {label}
                {isMandatory && <span className="text-red text-size-lg">* </span>}
            </label>
            <Field name={name}>
                {({ form, field }) => {
                    const { setFieldValue } = form;
                    const { value } = field;
                    return (
                        <ClayForm.Group
                            className={
                                "form-group-sm0 " +
                                (form.errors[name] && form.touched[name] !== undefined
                                    ? "has-error"
                                    : "")
                            }
                        >
                            <ClaySelectWithOption
                                aria-label={label}
                                id={id}
                                value={value}
                                options={options}
                                onChange={(e) => {
                                    setFieldValue(name, e.target.value);
                                }}
                            />
                            <ErrorMessage name={name} component={TextError} />
                        </ClayForm.Group>
                    );
                }}
            </Field>
        </div>
    );
}

export default Select;
