const TickIcon = function (props: any) {
    let color = "";
    switch (props.color) {
        case "white":
            color = "white";
            break;
        case "light-grey":
            color = "#ADB5BD";
            break;
        case "dark-grey":
            color = "#4F575E";
            break;
        case "blue":
            color = "#005C8A";
            break;
        default:
            color = "white";
            break;
    }
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="13"
            height="12"
            viewBox="0 0 13 12"
            fill="none"
            style={{ position: "relative", left: "4px", top: "-3px" }}
            {...props}
        >
            <path
                fill={color}
                d="M5.14987 7.58576L9.75588 2.98926L10.4649 3.69626L5.14987 8.99976L1.96094 5.81776L2.66948 5.11076L5.14987 7.58576Z"
            />
        </svg>
    );
};

export { TickIcon };
