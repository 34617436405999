const InactiveStatus = function () {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect width="20" height="20" rx="4" fill="white" />
            <circle cx="10" cy="10" r="4" fill="#C92A2A" />
        </svg>
    );
};

export { InactiveStatus };
