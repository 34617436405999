import ClayBadge from "@clayui/badge";

export const StatusDataRenderer = (props: any) => {
    let displayType = "";
    if (["Pending"].includes(props.value)) {
        displayType = "warning";
    } else if (props.value === "Approved" || props.value === "Active") {
        displayType = "success";
    } else if (props.value === "Declined") {
        displayType = "danger";
    } else {
        displayType = "secondary";
    }
    return <ClayBadge label={props.value} displayType={displayType} />;
};
