import React from "react";
import { useSelector } from "react-redux";

import ClayCard from "@clayui/card";
import ClayLayout from "@clayui/layout";

import { getUserDetails } from "../../../../../store/CustomerQueryTool/slice";
import MasterDetails from "./master-details/MasterDetails";
import OktaDetails from "./okta-details/OktaDetails";
import SupportHubDetails from "./support-hub-details/SupportHubDetails";
import WolkenDetails from "./wolken-details/WolkenDetails";

const UserDetails = () => {
    const userDetails = useSelector(getUserDetails);

    return (
        <React.Fragment>
            {userDetails &&
                userDetails.success &&
                (userDetails?.data?.oktaProfile?.errorCode === "E0000007" ||
                    userDetails?.data?.oktaProfile?.email === undefined) && (
                    <div>
                        <h2 className="text-red">OKTA Details</h2>
                        <p className="text-red">
                            User is not present in OKTA. Please contact Customer support Team
                        </p>
                    </div>
                )}

            {userDetails && userDetails.success && userDetails.data.oktaProfile.email && (
                <ClayCard>
                    <ClayCard.Body>
                        <div id="customerQueryDetails">
                            <ClayLayout.Row justify="start">
                                <ClayLayout.Col size={1}>
                                    <h3 className="text-red">Name:</h3>
                                </ClayLayout.Col>
                                <ClayLayout.Col size={11} className="text-red">
                                    {userDetails.data.oktaProfile.firstName}{" "}
                                    {userDetails.data.oktaProfile.lastName}
                                </ClayLayout.Col>
                                <ClayLayout.Col size={1}>
                                    <h3 className="text-red">Email:</h3>
                                </ClayLayout.Col>
                                <ClayLayout.Col size={11} className="text-red">
                                    {userDetails.data.oktaProfile.email}
                                </ClayLayout.Col>
                            </ClayLayout.Row>
                        </div>

                        <hr />

                        <MasterDetails userDetails={userDetails} />
                        <OktaDetails userDetails={userDetails} />
                        <SupportHubDetails userDetails={userDetails} />
                        <WolkenDetails userDetails={userDetails} />
                    </ClayCard.Body>
                </ClayCard>
            )}
        </React.Fragment>
    );
};

export default UserDetails;
