const MapIcon = function () {
    return (
        <svg
            width="41"
            height="40"
            viewBox="0 0 41 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect x="1.39062" y="0.5" width="39" height="39" rx="7.5" fill="white" />
            <rect x="1.39062" y="0.5" width="39" height="39" rx="7.5" stroke="#CED4DA" />
            <g clipPath="url(#clip0_5743_121366)">
                <path
                    d="M9.89062 14V30L16.8906 26L24.8906 30L31.8906 26V10L24.8906 14L16.8906 10L9.89062 14Z"
                    stroke="#737373"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M16.8906 10V26"
                    stroke="#737373"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M24.8906 14V30"
                    stroke="#737373"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_5743_121366">
                    <rect width="24" height="24" fill="white" transform="translate(8.89062 8)" />
                </clipPath>
            </defs>
        </svg>
    );
};

export { MapIcon };
