const NoDataFoundIcon = function () {
    return (
        <svg
            width="147"
            height="32"
            viewBox="0 0 147 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M53.106 16.976L47.856 10.2H46.26V20H47.954V13.028L53.358 20H54.8V10.2H53.106V16.976ZM64.6005 16.304C64.6005 15.772 64.5025 15.2727 64.3065 14.806C64.1105 14.3393 
        63.8352 13.9333 63.4805 13.588C63.1352 13.2333 62.7199 12.958 62.2345 12.762C61.7585 12.5567 61.2359 12.454 60.6665 12.454C60.0972 12.454 59.5699 12.5567 59.0845 
        12.762C58.6085 12.9673 58.1932 13.2473 57.8385 13.602C57.4932 13.9473 57.2179 14.358 57.0125 14.834C56.8165 15.3007 56.7185 15.8 56.7185 16.332C56.7185 16.864 56.8165 
        17.3633 57.0125 17.83C57.2085 18.2873 57.4792 18.6933 57.8245 19.048C58.1792 19.3933 58.5945 19.6687 59.0705 19.874C59.5559 20.07 60.0785 20.168 60.6385 20.168C61.2079 
        20.168 61.7352 20.0653 62.2205 19.86C62.7059 19.6547 63.1212 19.3793 63.4665 19.034C63.8212 18.6793 64.0965 18.2687 64.2925 17.802C64.4979 17.3353 64.6005 16.836 64.6005 
        16.304ZM62.9065 16.332C62.9065 16.6587 62.8552 16.9667 62.7525 17.256C62.6499 17.536 62.5005 17.7833 62.3045 17.998C62.1085 18.2127 61.8705 18.3853 61.5905 
        18.516C61.3199 18.6373 61.0119 18.698 60.6665 18.698C60.3399 18.698 60.0365 18.6373 59.7565 18.516C59.4859 18.3853 59.2479 18.2127 59.0425 17.998C58.8465 17.7833 
        58.6925 17.5313 58.5805 17.242C58.4685 16.9433 58.4125 16.6307 58.4125 16.304C58.4125 15.9773 58.4639 15.674 58.5665 15.394C58.6692 15.1047 58.8185 14.8527 59.0145 
        14.638C59.2105 14.4233 59.4439 14.2553 59.7145 14.134C59.9945 14.0033 60.3025 13.938 60.6385 13.938C60.9745 13.938 61.2779 14.0033 61.5485 14.134C61.8285 14.2553 62.0665 
        14.428 62.2625 14.652C62.4679 14.8667 62.6265 15.1187 62.7385 15.408C62.8505 15.6973 62.9065 16.0053 62.9065 16.332ZM77.789 20V9.78H76.095V13.756C75.8243 13.4107 75.4883 
        13.1073 75.087 12.846C74.6857 12.5847 74.1817 12.454 73.575 12.454C73.1363 12.454 72.707 12.538 72.287 12.706C71.8763 12.874 71.5123 13.126 71.195 13.462C70.8777 13.7887 
        70.621 14.19 70.425 14.666C70.2383 15.142 70.145 15.688 70.145 16.304C70.145 16.92 70.243 17.466 70.439 17.942C70.635 18.418 70.8917 18.824 71.209 19.16C71.5263 19.4867 
        71.8903 19.734 72.301 19.902C72.7117 20.07 73.1363 20.154 73.575 20.154C74.1723 20.154 74.6717 20.0187 75.073 19.748C75.4837 19.4773 75.8243 19.1507 76.095 
        18.768V20H77.789ZM76.123 16.304C76.123 16.668 76.0623 16.9947 75.941 17.284C75.829 17.5733 75.675 17.8253 75.479 18.04C75.283 18.2453 75.0543 18.404 74.793 
        18.516C74.5317 18.628 74.261 18.684 73.981 18.684C73.6917 18.684 73.4163 18.628 73.155 18.516C72.903 18.404 72.679 18.2453 72.483 18.04C72.287 17.8347 72.133 17.5873 
        72.021 17.298C71.909 16.9993 71.853 16.668 71.853 16.304C71.853 15.9307 71.909 15.5993 72.021 15.31C72.133 15.0113 72.287 14.7593 72.483 14.554C72.679 14.3487 72.903 
        14.1947 73.155 14.092C73.4163 13.98 73.6917 13.924 73.981 13.924C74.261 13.924 74.5317 13.98 74.793 14.092C75.0543 14.204 75.283 14.3673 75.479 14.582C75.675 14.7873 
        75.829 15.0347 75.941 15.324C76.0623 15.6133 76.123 15.94 76.123 16.304ZM86.1039 20V15.618C86.1039 14.6287 85.8379 13.8633 85.3059 13.322C84.7739 12.7713 83.9572 12.496 
        82.8559 12.496C82.2492 12.496 81.7312 12.552 81.3019 12.664C80.8726 12.776 80.4526 12.9253 80.0419 13.112L80.5039 14.47C80.8399 14.33 81.1712 14.218 81.4979 
        14.134C81.8246 14.05 82.1979 14.008 82.6179 14.008C83.2059 14.008 83.6539 14.1433 83.9619 14.414C84.2792 14.6847 84.4379 15.0767 84.4379 15.59V15.772C84.1486 
        15.6787 83.8452 15.604 83.5279 15.548C83.2199 15.492 82.8419 15.464 82.3939 15.464C81.9646 15.464 81.5632 15.5153 81.1899 15.618C80.8259 15.7113 80.5086 15.856 
        80.2379 16.052C79.9766 16.248 79.7712 16.4953 79.6219 16.794C79.4726 17.0927 79.3979 17.4427 79.3979 17.844V17.872C79.3979 18.2453 79.4679 18.5767 79.6079 
        18.866C79.7572 19.146 79.9486 19.384 80.1819 19.58C80.4246 19.7667 80.7046 19.9067 81.0219 20C81.3392 20.1027 81.6752 20.154 82.0299 20.154C82.5899 20.154 83.0659 
        20.0513 83.4579 19.846C83.8499 19.6407 84.1719 19.3887 84.4239 19.09V20H86.1039ZM84.4659 17.326C84.4659 17.5593 84.4146 17.774 84.3119 17.97C84.2186 18.1567 84.0832 
        18.32 83.9059 18.46C83.7286 18.5907 83.5186 18.6933 83.2759 18.768C83.0426 18.8427 82.7859 18.88 82.5059 18.88C82.1046 18.88 81.7639 18.7867 81.4839 18.6C81.2132 18.4133 
        81.0779 18.1427 81.0779 17.788V17.76C81.0779 17.3773 81.2272 17.0833 81.5259 16.878C81.8246 16.6727 82.2399 16.57 82.7719 16.57C83.0986 16.57 83.4066 16.598 83.6959 
        16.654C83.9946 16.71 84.2512 16.78 84.4659 16.864V17.326ZM90.0943 14.064H92.0823V12.608H90.0943V10.578H88.4003V12.608H87.4623V14.064H88.4003V17.956C88.4003 18.3667 
        88.4517 18.712 88.5543 18.992C88.657 19.2627 88.8017 19.482 88.9883 19.65C89.1843 19.818 89.413 19.9393 89.6743 20.014C89.9357 20.0887 90.2203 20.126 90.5283 
        20.126C90.8457 20.126 91.1257 20.0933 91.3683 20.028C91.611 19.9627 91.8397 19.8693 92.0543 19.748V18.362C91.7183 18.53 91.3683 18.614 91.0043 18.614C90.715 18.614 
        90.491 18.544 90.3323 18.404C90.1737 18.2547 90.0943 18.0167 90.0943 17.69V14.064ZM99.9945 20V15.618C99.9945 14.6287 99.7285 13.8633 99.1965 13.322C98.6645 12.7713 
        97.8478 12.496 96.7465 12.496C96.1398 12.496 95.6218 12.552 95.1925 12.664C94.7632 12.776 94.3432 12.9253 93.9325 13.112L94.3945 14.47C94.7305 14.33 95.0618 14.218 
        95.3885 14.134C95.7152 14.05 96.0885 14.008 96.5085 14.008C97.0965 14.008 97.5445 14.1433 97.8525 14.414C98.1698 14.6847 98.3285 15.0767 98.3285 15.59V15.772C98.0392 
        15.6787 97.7358 15.604 97.4185 15.548C97.1105 15.492 96.7325 15.464 96.2845 15.464C95.8552 15.464 95.4538 15.5153 95.0805 15.618C94.7165 15.7113 94.3992 15.856 94.1285 
        16.052C93.8672 16.248 93.6618 16.4953 93.5125 16.794C93.3632 17.0927 93.2885 17.4427 93.2885 17.844V17.872C93.2885 18.2453 93.3585 18.5767 93.4985 18.866C93.6478 19.146 
        93.8392 19.384 94.0725 19.58C94.3152 19.7667 94.5952 19.9067 94.9125 20C95.2298 20.1027 95.5658 20.154 95.9205 20.154C96.4805 20.154 96.9565 20.0513 97.3485 
        19.846C97.7405 19.6407 98.0625 19.3887 98.3145 19.09V20H99.9945ZM98.3565 17.326C98.3565 17.5593 98.3052 17.774 98.2025 17.97C98.1092 18.1567 97.9738 18.32 97.7965 
        18.46C97.6192 18.5907 97.4092 18.6933 97.1665 18.768C96.9332 18.8427 96.6765 18.88 96.3965 18.88C95.9952 18.88 95.6545 18.7867 95.3745 18.6C95.1038 18.4133 94.9685 
        18.1427 94.9685 17.788V17.76C94.9685 17.3773 95.1178 17.0833 95.4165 16.878C95.7152 16.6727 96.1305 16.57 96.6625 16.57C96.9892 16.57 97.2972 16.598 97.5865 
        16.654C97.8852 16.71 98.1418 16.78 98.3565 16.864V17.326ZM108.251 12.664V12.258C108.251 11.5113 108.577 11.138 109.231 11.138C109.417 11.138 109.585 11.1567 
        109.735 11.194C109.893 11.222 110.057 11.264 110.225 11.32V9.892C110.038 9.836 109.837 9.78933 109.623 9.752C109.408 9.71467 109.151 9.696 108.853 9.696C108.125 
        9.696 107.565 9.89667 107.173 10.298C106.781 10.69 106.585 11.2967 106.585 12.118V12.65H105.647V14.064H106.585V20H108.279V14.064H110.211V12.664H108.251ZM118.81 
        16.304C118.81 15.772 118.712 15.2727 118.516 14.806C118.32 14.3393 118.044 13.9333 117.69 13.588C117.344 13.2333 116.929 12.958 116.444 12.762C115.968 12.5567 115.445 
        12.454 114.876 12.454C114.306 12.454 113.779 12.5567 113.294 12.762C112.818 12.9673 112.402 13.2473 112.048 13.602C111.702 13.9473 111.427 14.358 111.222 14.834C111.026 
        15.3007 110.928 15.8 110.928 16.332C110.928 16.864 111.026 17.3633 111.222 17.83C111.418 18.2873 111.688 18.6933 112.034 19.048C112.388 19.3933 112.804 19.6687 113.28 
        19.874C113.765 20.07 114.288 20.168 114.848 20.168C115.417 20.168 115.944 20.0653 116.43 19.86C116.915 19.6547 117.33 19.3793 117.676 19.034C118.03 18.6793 118.306 
        18.2687 118.502 17.802C118.707 17.3353 118.81 16.836 118.81 16.304ZM117.116 16.332C117.116 16.6587 117.064 16.9667 116.962 17.256C116.859 17.536 116.71 17.7833 116.514 
        17.998C116.318 18.2127 116.08 18.3853 115.8 18.516C115.529 18.6373 115.221 18.698 114.876 18.698C114.549 18.698 114.246 18.6373 113.966 18.516C113.695 18.3853 113.457 
        18.2127 113.252 17.998C113.056 17.7833 112.902 17.5313 112.79 17.242C112.678 16.9433 112.622 16.6307 112.622 16.304C112.622 15.9773 112.673 15.674 112.776 15.394C112.878 
        15.1047 113.028 14.8527 113.224 14.638C113.42 14.4233 113.653 14.2553 113.924 14.134C114.204 14.0033 114.512 13.938 114.848 13.938C115.184 13.938 115.487 14.0033 115.758 
        14.134C116.038 14.2553 116.276 14.428 116.472 14.652C116.677 14.8667 116.836 15.1187 116.948 15.408C117.06 15.6973 117.116 16.0053 117.116 16.332ZM125.393 16.78C125.393 
        17.3587 125.239 17.8113 124.931 18.138C124.623 18.4553 124.221 18.614 123.727 18.614C123.223 18.614 122.831 18.46 122.551 18.152C122.28 17.8347 122.145 17.3867 122.145 
        16.808V12.608H120.451V17.312C120.451 18.18 120.679 18.8707 121.137 19.384C121.594 19.8973 122.243 20.154 123.083 20.154C123.652 20.154 124.119 20.028 124.483 
        19.776C124.847 19.5147 125.15 19.2067 125.393 18.852V20H127.087V12.608H125.393V16.78ZM130.91 15.828C130.91 15.2493 131.064 14.8013 131.372 14.484C131.68 14.1573 
        132.082 13.994 132.576 13.994C133.08 13.994 133.468 14.1527 133.738 14.47C134.018 14.778 134.158 15.2213 134.158 15.8V20H135.852V15.296C135.852 14.428 135.624 13.7373 
        135.166 13.224C134.709 12.7107 134.06 12.454 133.22 12.454C132.931 12.454 132.67 12.4913 132.436 12.566C132.212 12.6313 132.002 12.7247 131.806 12.846C131.62 
        12.9673 131.452 13.1073 131.302 13.266C131.162 13.4153 131.032 13.5787 130.91 13.756V12.608H129.216V20H130.91V15.828ZM145.164 20V9.78H143.47V13.756C143.199 13.4107 
        142.863 13.1073 142.462 12.846C142.061 12.5847 141.557 12.454 140.95 12.454C140.511 12.454 140.082 12.538 139.662 12.706C139.251 12.874 138.887 13.126 138.57 
        13.462C138.253 13.7887 137.996 14.19 137.8 14.666C137.613 15.142 137.52 15.688 137.52 16.304C137.52 16.92 137.618 17.466 137.814 17.942C138.01 18.418 138.267 18.824 
        138.584 19.16C138.901 19.4867 139.265 19.734 139.676 19.902C140.087 20.07 140.511 20.154 140.95 20.154C141.547 20.154 142.047 20.0187 142.448 19.748C142.859 19.4773 
        143.199 19.1507 143.47 18.768V20H145.164ZM143.498 16.304C143.498 16.668 143.437 16.9947 143.316 17.284C143.204 17.5733 143.05 17.8253 142.854 18.04C142.658 18.2453 
        142.429 18.404 142.168 18.516C141.907 18.628 141.636 18.684 141.356 18.684C141.067 18.684 140.791 18.628 140.53 18.516C140.278 18.404 140.054 18.2453 139.858 
        18.04C139.662 17.8347 139.508 17.5873 139.396 17.298C139.284 16.9993 139.228 16.668 139.228 16.304C139.228 15.9307 139.284 15.5993 139.396 15.31C139.508 15.0113 139.662 
        14.7593 139.858 14.554C140.054 14.3487 140.278 14.1947 140.53 14.092C140.791 13.98 141.067 13.924 141.356 13.924C141.636 13.924 141.907 13.98 142.168 14.092C142.429 
        14.204 142.658 14.3673 142.854 14.582C143.05 14.7873 143.204 15.0347 143.316 15.324C143.437 15.6133 143.498 15.94 143.498 16.304Z"
                fill="#ADB5BD"
            />
            <g clipPath="url(#clip0)">
                <path
                    d="M29.914 8.25488C29.914 7.53868 29.914 6.85397 29.914 6.14406C23.753 6.14406 17.5999 6.14406 11.4243 6.14406C11.4243 6.84137 11.4243 7.53925 11.4243 8.25488C17.5814 
          8.25488 23.7362 8.25488 29.914 8.25488Z"
                    fill="#ADB5BD"
                />
                <path
                    d="M29.8982 23.6533C23.7277 23.6533 17.5802 23.6533 11.4175 23.6533C11.4175 24.3581 11.4175 25.0485 11.4175 25.7642C17.5757 25.7642 23.7277 25.7642 29.8982 
          25.7642C29.8982 25.0577 29.8982 24.3541 29.8982 23.6533Z"
                    fill="#ADB5BD"
                />
                <path
                    d="M29.9133 17.2031C29.9133 16.4823 29.9133 15.7988 29.9133 15.0934C23.7517 15.0934 17.5986 15.0934 11.4236 15.0934C11.4236 15.7879 11.4236 16.4858 11.4236 
          17.2031C17.5823 17.2031 23.736 17.2031 29.9133 17.2031Z"
                    fill="#ADB5BD"
                />
                <path
                    d="M2.99146 7.19456C2.98753 8.96589 4.38711 10.4029 6.13238 10.4183C7.86922 10.4344 9.30253 8.97218 9.29803 7.19055C9.29297 5.4135 7.88833 3.98797 6.1425 
          3.98911C4.39779 3.98968 2.9954 5.41636 2.99146 7.19456ZM6.1262 6.14344C6.7147 6.13543 7.18291 6.59572 7.19078 7.18998C7.19865 7.7705 6.71919 8.26743 6.14756 
          8.27087C5.57761 8.27373 5.09534 7.78195 5.09646 7.19799C5.09871 6.61805 5.55456 6.15088 6.1262 6.14344Z"
                    fill="#ADB5BD"
                />
                <path
                    d="M2.99122 24.7115C2.99403 26.5023 4.4026 27.9261 6.16698 27.9204C7.90438 27.9147 9.31015 26.4662 9.29834 24.6932C9.28654 22.9224 7.86335 21.4792 6.1355 
          21.4866C4.40148 21.4946 2.9884 22.9436 2.99122 24.7115ZM6.15574 25.7649C5.5678 25.7689 5.10015 25.3046 5.09678 24.7132C5.09397 24.1304 5.57567 23.6369 6.14562 
          23.6386C6.71614 23.6404 7.19672 24.1367 7.19053 24.7178C7.18435 25.3 6.72906 25.7603 6.15574 25.7649Z"
                    fill="#ADB5BD"
                />
                <path
                    d="M2.99148 16.1498C2.99879 17.9428 4.40625 19.3604 6.17231 19.3535C7.90802 19.3466 9.31323 17.893 9.29805 16.1211C9.28287 14.3487 7.85631 12.9077 6.13015 
          12.9197C4.39444 12.9323 2.98418 14.3836 2.99148 16.1498ZM6.13465 17.1974C5.55233 17.194 5.08412 16.7091 5.09704 16.124C5.11053 15.5475 5.57875 15.0746 6.13971 
          15.0712C6.7091 15.0677 7.19249 15.5612 7.19136 16.1435C7.18968 16.736 6.72315 17.2009 6.13465 17.1974Z"
                    fill="#ADB5BD"
                />
            </g>
            <rect
                x="29.1797"
                width="1.99427"
                height="41.2663"
                transform="rotate(45 29.1797 0)"
                fill="#ADB5BD"
            />
            <rect
                x="30.5898"
                y="1.41016"
                width="1.99427"
                height="41.2663"
                transform="rotate(45 30.5898 1.41016)"
                fill="white"
            />
            <defs>
                <clipPath id="clip0">
                    <rect
                        width="26.9226"
                        height="23.9312"
                        fill="white"
                        transform="matrix(1 0 0 -1 2.99146 27.9199)"
                    />
                </clipPath>
            </defs>
        </svg>
    );
};

export { NoDataFoundIcon };
