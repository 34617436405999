import ClayForm, { ClaySelectWithOption } from "@clayui/form";
import { ErrorMessage, Field } from "formik";
import { ISelect } from "../../../interfaces/interfaces";

import TextError from "./TextError";
import { InfoButton } from "../../InfoButton/InfoButton";

const Select = (props: ISelect) => {
    const { label, name, options, validate, onChange = null, required, size = "sm" ,showInfo=false,infotext=""} = props;
    return (
        <div>
            {label && (
                <label htmlFor={name}>
                    {required && <span className="text-red">* </span>}
                    {label}
                    {showInfo &&<InfoButton info={infotext} align={"top"} classname={"p-0 pl-0 m-0 ml-1 mb-1"}/> }
                </label>
            )}
            <Field name={name} validate={validate}>
                {({ form, field }: { form: any; field: any }) => {
                    const { setFieldValue } = form;
                    const { value } = field;
                    return (
                        <ClayForm.Group
                            className={`form-group-${size} ${
                                form.errors[name] && form.touched[name] !== undefined
                                    ? "has-error"
                                    : ""
                            }`}
                        >
                            <ClaySelectWithOption
                                aria-label={label}
                                id={name}
                                value={value}
                                options={options}
                                onChange={(e) => {
                                    setFieldValue(name, e.target.value);
                                    if (onChange) {
                                        onChange(e);
                                    }
                                }}
                                {...props}
                            />
                            <ErrorMessage name={name} component={TextError} />
                        </ClayForm.Group>
                    );
                }}
            </Field>
        </div>
    );
}

export default Select;
