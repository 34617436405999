const BPSIcon = function () {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="25"
            viewBox="0 -2 100 94"
            version="1.1"
        >
            <desc>Created with sketchtool.</desc>
            <g id="Support-Home" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g
                    id="Desktop"
                    transform="translate(-996.000000, -852.000000)"
                    fill="#000000"
                    fillRule="nonzero"
                >
                    <g id="Boradcom-Payment-Security" transform="translate(853.000000, 788.000000)">
                        <g id="i_payment-security" transform="translate(143.000000, 64.000000)">
                            <path
                                d="M77.1658416,-1.42108547e-14 C81.5729425,-1.42108547e-14 85.1485149,3.61120934 85.1485149,8.06223479 L85.1485149,8.06223479 L85.148,27 L80.891,27 L80.8910891,21.4992928 L4.25742574,21.4992928 L4.25742574,68.2005343 C4.25742574,69.7114569 5.48903819,70.9476662 6.99434229,70.9476662 L6.99434229,70.9476662 L47,70.947 L47,75.247 L7.98267327,75.2475248 C3.65872525,75.2475248 0.135223283,71.7713019 0.00380026443,67.4363193 L1.42108547e-14,67.18529 L1.42108547e-14,8.06223479 C1.42108547e-14,3.61120934 3.5755724,-1.42108547e-14 7.98267327,-1.42108547e-14 L7.98267327,-1.42108547e-14 Z M17.029703,4.29985856 L7.45049505,4.29985856 C5.69430693,4.29985856 4.25742574,5.87199434 4.25742574,7.79349364 L4.25742574,7.79349364 L4.25742574,18.2743989 L17.029703,18.2743989 L17.029703,4.29985856 Z M78.1458146,4.29985856 L20.4950495,4.29985856 L20.4950495,18.2743989 L80.8910891,18.2743989 L80.8910891,7.79349364 C80.8910891,5.87199434 79.6557156,4.29985856 78.1458146,4.29985856 L78.1458146,4.29985856 Z"
                                id="Combined-Shape"
                            />
                            <g id="Shield" transform="translate(47.534653, 22.782178)">
                                <path
                                    d="M26.2233429,0.98019802 L27.5420157,2.31447211 C33.6627639,7.66597511 41.5217071,10.4940411 49.5796749,10.2447947 L49.5796749,10.2447947 L51.4680143,10.2447947 L51.4680143,42.4933386 C51.5313106,43.9029346 51.7001007,59.8389018 26.9196021,69.9966013 L26.9196021,69.9966013 L26.2233429,70.2871287 L25.5270837,69.9966013 C0.736035688,59.8389018 0.915375184,43.9029346 0.989220859,42.5256194 L0.989220859,42.5256194 L0.989220859,10.2447947 L2.87756026,10.2447947 C16.1148268,10.4397309 24.0675262,3.13141499 24.842689,2.3765292 L24.9046701,2.31447211 L26.2233429,0.98019802 Z M47.7440824,23.017241 L13.1737572,58.2679016 C17.0857529,61.5948881 21.4903317,64.2679489 26.2233429,66.187464 C35.3274597,62.3352856 41.878626,57.0842714 45.3071751,50.9186339 C46.8058815,48.4289908 47.6455485,45.5852709 47.7440824,42.665503 L47.7440824,42.665503 L47.7440824,23.017241 Z M42.0052185,13.4190758 L6.47489956,49.6166406 C6.67533782,50.0470516 6.89687485,50.4774626 7.13951063,50.9186339 C8.08158203,52.6092376 9.20687297,54.1865419 10.4942141,55.620874 L10.4942141,55.620874 L47.7440824,17.6371036 L47.7440824,14.0431718 C45.8184547,13.982761 43.9000472,13.774136 42.0052185,13.4190758 L42.0052185,13.4190758 Z M26.2127935,6.20969163 C20.0562145,11.1142304 12.5123785,13.8615092 4.70260336,14.0431718 L4.70260336,14.0431718 L4.70260336,42.6762633 L4.71671614,43.1165571 C4.75993591,43.9961779 4.89301431,44.8692079 5.11402927,45.7214211 L5.11402927,45.7214211 L37.8171138,12.3645689 C33.604226,11.0748566 29.6674025,8.98678383 26.2127935,6.20969163 Z"
                                    id="Combined-Shape"
                                />
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
};

export { BPSIcon };
