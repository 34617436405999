import ClayForm from "@clayui/form";
import { ErrorMessage, Field } from "formik";
import TextError from "./TextError";
import { ITextArea } from "../../../interfaces/interfaces";

function TextArea(props: ITextArea) {
    const { label, name, required } = props;
    return (
        <div>
            {label && (
                <label htmlFor={name}>
                    {required && <span className="text-red">* </span>}
                    {label}
                </label>
            )}
            <Field name={name}>
                {({ form, field }: { form: any; field: any }) => {
                    return (
                        <ClayForm.Group
                            className={
                                "form-group-sm " +
                                (form.errors[name] && form.touched[name] !== undefined
                                    ? "has-error"
                                    : "")
                            }
                        >
                            <textarea
                                className="form-control"
                                value={name}
                                {...field}
                                {...props}
                                style={{ height: "auto" }}
                            ></textarea>
                            <ErrorMessage name={name} component={TextError} />
                        </ClayForm.Group>
                    );
                }}
            </Field>
        </div>
    );
}

export default TextArea;
