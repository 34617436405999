const LifeBuoyIcon = function () {
    return (
        <svg
            width="51"
            height="50"
            viewBox="0 0 51 50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M25.8906 49C39.1455 49 49.8906 38.2548 49.8906 25C49.8906 11.7452 39.1455 1 25.8906 1C12.6358 1 1.89062 11.7452 1.89062 25C1.89062 38.2548 12.6358 49 25.8906 49Z"
                stroke="#6A7178"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M25.891 34.6004C31.193 34.6004 35.491 30.3023 35.491 25.0004C35.491 19.6985 31.193 15.4004 25.891 15.4004C20.5891 15.4004 16.291 19.6985 16.291 25.0004C16.291 30.3023 20.5891 34.6004 25.891 34.6004Z"
                stroke="#6A7178"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M8.92188 8.0332L19.0979 18.2092"
                stroke="#6A7178"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M32.6836 31.793L42.8596 41.969"
                stroke="#6A7178"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M32.6836 18.2092L42.8596 8.0332"
                stroke="#6A7178"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M32.6836 18.2083L41.1556 9.73633"
                stroke="#6A7178"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M8.92188 41.969L19.0979 31.793"
                stroke="#6A7178"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export { LifeBuoyIcon };
