import { v4 as uuidv4 } from "uuid";

const csp = (window as any).csp || {};
const cspGlobalActions = csp.globalActions || {};

const globalActionsSubscribers: any = {};
let dispatch: any = null;
let getState: any = null;

export const registerGlobalActions = (actionKey: any, actionFn: any) => {
    const id = uuidv4();
    //-------- new code-------
    if (!globalActionsSubscribers[actionKey]) {
        globalActionsSubscribers[actionKey] = {
            ids: [],
            subscribers: {},
        };
    }

    globalActionsSubscribers[actionKey].ids.push(id);
    globalActionsSubscribers[actionKey].subscribers[id] = actionFn;

    if (!cspGlobalActions[actionKey]) {
        cspGlobalActions[actionKey] = (payload: any) => {
            globalActionsSubscribers[actionKey].ids.forEach((subId: any) => {
                try {
                    globalActionsSubscribers[actionKey].subscribers[subId](
                        payload,
                        dispatch,
                        getState,
                    );
                } catch (e) {
                    //user Logger instead of Console.log
                }
            });
        };
    }

    return () => {
        // globalActionsSubscribers[actionKey].subscribers[id]; Check: needed?
        globalActionsSubscribers[actionKey].ids = globalActionsSubscribers[actionKey].ids.filter(
            (subId: any) => subId !== id,
        );
    };
};

csp.globalActions = cspGlobalActions;
(window as any).csp = csp;

//should be called by the store configurer, by passing in the store
export const configureGlobalActions = (store: any) => {
    getState = store.getState;
    dispatch = store.dispatch;
};
