import "./App.scss";
import {
    Footer,
    Header,
    GlobalAlertHandler,
    GlobalAPILoader,
} from "./common-ui-modules/components/index";

import Routing from "./Routing";
import ScrollButton from "../client-src/components/ScrollButton/ScrollButton";
import { Heartbeat } from "./common-ui-modules/components/Heartbeat/Heartbeat";
import { useLocation } from "react-router-dom";

function App() {
    const location = useLocation();
    return (
        <div className="App">
            <Header></Header>
            <GlobalAPILoader />
            <GlobalAlertHandler />
            {!location.pathname.includes("/web") && location.pathname !== "/" && <Heartbeat />}
            <div className="d-flex">
                <div className="flex-grow-1 body-bg max-width mx-auto overflow-auto">
                    <Routing />
                </div>
            </div>
            <Footer></Footer>
            <ScrollButton />
        </div>
    );
}

export default App;
