import Cookies from "js-cookie";
function authToken(url: string, data: any, method: string) {
    const headers: any = {};
    const csrfToken = Cookies.get("SYM-LIC-XSRF-TOKEN");
    if (method !== "GET" && csrfToken) {
        headers["X-XSRF-TOKEN"] = csrfToken;
    }

    headers["Authorization"] = `Bearer `;
    return headers;
}

export default authToken;
