import React, { useState } from "react";
import ClayCard from "@clayui/card";
import ClayLayout from "@clayui/layout";
import { Table } from "../../../common-ui-modules/components";
import TableCellRenderer from "../../../common-ui-modules/components/Tables/cellRenderer";
import ToggleRenderer from "../../../common-ui-modules/components/Tables/cellRenderer/ToggleRenderer";
import { useDispatch } from "react-redux";
import { addUpdateDomainList, deleteDomain } from "../../../store/CompanyManagement/slice";
import { useAppSelector } from "../../../store/hook";
import { getUserDetails } from "../../../store/global/global.slice";
import { DELETE_CONFIRM_MESSAGE, DELETE_POPUP_HEADING } from "../constants";
import ConfirmationModal from "../../../common-ui-modules/components/Modal/ConfirmationModal";

const DomainGrid = (props: any) => {
    const [openModal, setOpenModal] = useState(false);
    const [domainId_Delete, setDomainId_Delete] = useState(0);
    const dispatch = useDispatch();

    const { rowData, companyId } = props;
    const UserDetails = useAppSelector(getUserDetails);

    const deactivateDomain = (toggleStatus: any, rowData: any) => {
        props.setUpdateDomain(false);
        const status = toggleStatus ? "Y" : "N";
        props.setCurrentDomain(rowData.domainName);
        props.setDomainStatus(status);
        dispatch(addUpdateDomainList(companyId, rowData.domainName, status, UserDetails.email));
        props.setUpdateDomain(true);
    };

    const onDeleteDomain = (rowData: any) => {
        setOpenModal(true);
        setDomainId_Delete(rowData.companyDomainId);
    };

    const confirmDeleteDomainHandler = () => {
        if (domainId_Delete) {
            dispatch(deleteDomain(domainId_Delete));
        }
    };

    const columnDefs = [
        {
            headerName: "Domain Name",
            field: "domainName",
            sortable: true,
            width: 400,
        },
        {
            headerName: "IsActive",
            field: "recordActive",
            sortable: true,
            minWidth: 50,
            cellRenderer: (
                <ToggleRenderer
                    title={"recordActive"}
                    onToggle={(toggleStatus: any, rowData: any) =>
                        deactivateDomain(toggleStatus, rowData)
                    }
                />
            ),
        },
        {
            headerName: "Actions",
            field: "",
            minWidth: 50,
            cellRenderer: (
                <TableCellRenderer
                    type="action"
                    onDeleteClick={onDeleteDomain}
                    rowData={rowData}
                    showDeleteIcon
                />
            ),
        },
    ];

    return (
        <React.Fragment>
            <ClayCard>
                <ClayCard.Body>
                    <ClayLayout.Row>
                        <ClayLayout.Col>
                            <Table
                                definitions={columnDefs}
                                dataSource={rowData}
                                borderedColumns={false}
                                borderless={true}
                                headVerticalAlignment={"middle"}
                                headingNoWrap={true}
                                hover={true}
                                noWrap={true}
                                responsive={true}
                                responsiveSize={"sm"}
                                striped={false}
                                tableVerticalAlignment={"middle"}
                                showPagination={true}
                                uniqueKeyProp={"companyDomainId"}
                                showDeltasDropDown={false}
                            />
                        </ClayLayout.Col>
                    </ClayLayout.Row>
                </ClayCard.Body>
            </ClayCard>
            {openModal && (
                <ConfirmationModal
                    heading={DELETE_POPUP_HEADING}
                    message={DELETE_CONFIRM_MESSAGE}
                    btn1Text={"Cancel"}
                    btn2text={"Confirm"}
                    btn1Handler={() => {
                        /**/
                    }}
                    btn2Handler={confirmDeleteDomainHandler}
                    setOpenModal={setOpenModal}
                />
            )}
        </React.Fragment>
    );
};

export default DomainGrid;
