const ProfNotificationIcon = function () {
    return (
        <svg
            width="20"
            height="21"
            viewBox="0 0 20 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0)">
                <path
                    d="M3.33301 17.8153V11.9819"
                    stroke="#272B30"
                    strokeWidth="2"
                    strokeLinecap="round"
                />
                <path
                    d="M3.33301 8.64876V2.81543"
                    stroke="#272B30"
                    strokeWidth="2"
                    strokeLinecap="round"
                />
                <path
                    d="M10 17.8154V10.3154"
                    stroke="#272B30"
                    strokeWidth="2"
                    strokeLinecap="round"
                />
                <path
                    d="M10 6.9821V2.81543"
                    stroke="#272B30"
                    strokeWidth="2"
                    strokeLinecap="round"
                />
                <path
                    d="M16.667 17.8156V13.6489"
                    stroke="#272B30"
                    strokeWidth="2"
                    strokeLinecap="round"
                />
                <path
                    d="M16.667 10.3154V2.81543"
                    stroke="#272B30"
                    strokeWidth="2"
                    strokeLinecap="round"
                />
                <path
                    d="M0.833008 11.9819H5.83301"
                    stroke="#272B30"
                    strokeWidth="2"
                    strokeLinecap="round"
                />
                <path
                    d="M7.5 6.98193H12.5"
                    stroke="#272B30"
                    strokeWidth="2"
                    strokeLinecap="round"
                />
                <path
                    d="M14.167 13.6489H19.167"
                    stroke="#272B30"
                    strokeWidth="2"
                    strokeLinecap="round"
                />
            </g>
            <defs>
                <clipPath id="clip0">
                    <rect width="20" height="20" fill="white" transform="translate(0 0.31543)" />
                </clipPath>
            </defs>
        </svg>
    );
};

export { ProfNotificationIcon };
