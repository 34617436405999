const ThreeDotMenuIcon = function () {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M9.5 3.18604C9.5 4.01797 8.82559 4.69239 7.99365 4.69239C7.16172 4.69239 6.4873 4.01797 6.4873 3.18604C6.4873 2.3541 7.16172 1.67969 7.99365 1.67969C8.82559 1.67969 9.5 2.3541 9.5 3.18604ZM9.5 8.00635C9.5 8.83829 8.82559 9.5127 7.99365 9.5127C7.16172 9.5127 6.4873 8.83829 6.4873 8.00635C6.4873 7.17442 7.16172 6.5 7.99365 6.5C8.82559 6.5 9.5 7.17442 9.5 8.00635ZM9.5 12.8267C9.5 13.6586 8.82559 14.333 7.99365 14.333C7.16172 14.333 6.4873 13.6586 6.4873 12.8267C6.4873 11.9947 7.16172 11.3203 7.99365 11.3203C8.82559 11.3203 9.5 11.9947 9.5 12.8267Z"
                fill="#858585"
            />
        </svg>
    );
};

export { ThreeDotMenuIcon };
