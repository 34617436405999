const TRANSLATE_STYLE = "translate(8px, 8px)";
const UserDetailsDownloadIcon = function () {
    return (
        <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="20" cy="20" r="20" fill="#f5c5cf" />
            <path
                d="M8.00891 15.0117L12.0089 19.0117L16.0089 15.0117"
                stroke="#b41b3c"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                style={{ transform: TRANSLATE_STYLE }}
            />
            <path
                d="M12.0089 10.0117V19.0117"
                stroke="#b41b3c"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                style={{ transform: TRANSLATE_STYLE }}
            />
            <path
                d="M20.889 16.1017C21.7584 15.4903 22.4103 14.6178 22.7503 13.6108C23.0902 12.6038 23.1004 11.5147 22.7794 10.5015C22.4584 9.48828 21.8228 8.60374 20.965 7.97621C20.1072 7.34868 19.0718 7.01082 18.009 7.01165H16.749C16.4482 5.83952 15.8855 4.7509 15.1031 3.82773C14.3207 2.90456 13.3391 2.17091 12.2322 1.68201C11.1252 1.1931 9.92181 0.961679 8.71249 1.00517C7.50317 1.04866 6.31948 1.36592 5.25052 1.93308C4.18156 2.50023 3.25519 3.3025 2.54115 4.27948C1.82712 5.25646 1.34402 6.3827 1.12823 7.5734C0.912436 8.7641 0.969576 9.98825 1.29535 11.1537C1.62112 12.3191 2.20703 13.3954 3.00897 14.3017"
                stroke="#b41b3c"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                style={{ transform: TRANSLATE_STYLE }}
            />
        </svg>
    );
};

export { UserDetailsDownloadIcon };
