import ClayTable from "@clayui/table";
import { TableBodyRow } from "./table-body-row/TableBodyRow";

const TableBody = (props: any) => {
    const { info, resultSet, currentPage, uniqueKeyProp, handleCheckboxChange } = props;

    return (
        <ClayTable.Body>
            {resultSet.map((rowData: any, idx: any) => (
                <TableBodyRow
                    key={uniqueKeyProp ? rowData[uniqueKeyProp] : idx}
                    index={idx + currentPage}
                    rowData={rowData}
                    definitions={info}
                    currentPage={currentPage}
                    handleCheckboxChange={handleCheckboxChange}
                />
            ))}
        </ClayTable.Body>
    );
};

export { TableBody };
