const scrollToView = (selector, isId) => {
    setTimeout(function () {
        let elem;
        if (isId) {
            elem = document.getElementById(selector);
        } else {
            elem = document.querySelector(selector);
        }

        if (elem) {
            const headerOuterHeight = 0,
                errorTop = elem.getBoundingClientRect().top,
                scrollYPosition = Math.abs(window.pageYOffset + errorTop - headerOuterHeight - 20);

            elem.focus();
            window.scrollTo({
                top: scrollYPosition,
                behavior: "smooth",
            });
        }
    });
};

export const Util = {
    scrollToView,
};
