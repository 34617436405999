import { useState } from "react";
import ForgotPassword from "./steps/ForgotPassword";
import OTPVerification from "./steps/OTPVerification";

const ForgotPasswordHome = () => {
    const [email, setEmail] = useState(null);
    const [captchaToken, setCaptchaToken] = useState(null);
    const [step, setStep] = useState(1);

    const renderStep = () => {
        if (step === 1) {
            return (<ForgotPassword email={email} captchaToken={captchaToken} setEmail={setEmail} setCaptchaToken={setCaptchaToken} setStep={setStep} />);
        }
        else if (step === 2) {
            return (<OTPVerification setStep={setStep} email={email} captchaToken={captchaToken} />);
        }
    }

    return (
        <div>{renderStep()}</div>
    );
}

export default ForgotPasswordHome;
