/**
    userRoles = {
        isCustomer              : () => false,
        isPartner               : () => false,
        isInternal              : () => false,
        hasPlaSiteAccess        : () => false,
        hasPlaSubscriptionAccess: () => false,
        isAdminUser             : () => false,
        isSuperAdmin            : () => false,
        hasDemoRole             : () => false,
        hasExtRole              : () => false,
        hasNoRole               : () => false,
        hasPaRole               : () => false,
        hasSolRole              : () => false,
        hasPdbRole              : () => false,
        hasAHCARole             : () => false,
        hasRMRole               : () => false,
        hasBSNDRole             : () => false,
        isEnterprise            : () => false,
        isBasic                 : () => false
        isDownloadDisabled      : () => false,
        isLicenseDisabled       : () => false,
        isCMSDisabled           : () => false,
        isBSNRestricted         : () => false,
        isBSNCustomer           : () => false,
        isAssistOEM             : () => false,
        hasSCNORole             : () => false,
        hasPartnerSite          : () => false
        hasBSNAdminRole         : () => false
    }
 */
export const UserPermissionModel =
    window.ecx && window.ecx.userRoles
        ? { ...window.ecx.userRoles }
        : {
              isCustomer: () => false,
              isPartner: () => false,
              isInternal: () => false,
              hasPlaSiteAccess: () => false,
              hasPlaSubscriptionAccess: () => false,
              isAdminUser: () => false,
              isSuperAdmin: () => false,
              hasDemoRole: () => false,
              hasExtRole: () => false,
              hasNoRole: () => false,
              hasPaRole: () => false,
              hasSolRole: () => false,
              hasPdbRole: () => false,
              hasAHCARole: () => false,
              hasRMRole: () => false,
              hasBSNDRole: () => false,
              isEnterprise: () => false,
              isBasic: () => false,
              isDownloadDisabled: () => false,
              isLicenseDisabled: () => false,
              isCMSDisabled: () => false,
              isBSNRestricted: () => false,
              isBSNCustomer: () => false,
              isAssistOEM: () => false,
              hasSCNORole: () => false,
              hasPartnerSite: () => false,
              hasBSNAdminRole: () => false,
          };
