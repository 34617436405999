const GearIcon = function (props: any) {
    let color = props.color ? props.color : "#005C8A";
    switch (color) {
        case "blue":
            color = "#005C8A";
            break;
        case "grey":
            color = "#ADB5BD";
            break;
        case "darkgrey":
            color = "#58606E";
            break;
        default:
            color = "#005C8A";
            break;
    }

    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 10 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fill={color}
                stroke={color}
                d="M8.15303 5.55163L8.08395 5.90844L8.40201 6.08426L9.02061 6.42623C8.8498 6.86251 8.60291 7.26397 8.29446 7.61554L7.65219 7.26049L7.35665 7.09711L7.09471 7.31025C6.79358 7.55528 6.44824 7.74643 6.07528 7.87348L5.73652 7.98888V8.34677V9.02365C5.26034 9.10147 4.76004 9.10454 4.26541 9.02366V8.34677V7.9869L3.92417 7.87264C3.55093 7.74767 3.2073 7.55739 2.9095 7.31213L2.64717 7.09607L2.34973 7.26049L1.70779 7.61536C1.39932 7.26275 1.15254 6.86151 0.981767 6.42676L1.59798 6.08611L1.91605 5.91029L1.84696 5.55348C1.7761 5.1875 1.7761 4.81217 1.84696 4.44619L1.91605 4.08939L1.59798 3.91356L0.981569 3.57281C1.15234 3.13684 1.39909 2.73566 1.70733 2.38429L2.34737 2.73894L2.64306 2.90279L2.90528 2.68942C3.20641 2.44439 3.55176 2.25325 3.92471 2.1262L4.26348 2.01079V1.65291V0.976019C4.73965 0.898206 5.23995 0.895142 5.73459 0.976013V1.65106V2.01092L6.07583 2.12519C6.44906 2.25016 6.79269 2.44043 7.09049 2.6857L7.35283 2.90176L7.65026 2.73734L8.29221 2.38246C8.60068 2.73507 8.84745 3.13632 9.01823 3.57107L8.40201 3.91171L8.08395 4.08754L8.15303 4.44434C8.22389 4.81033 8.22389 5.18565 8.15303 5.55163ZM0.902463 3.79398L0.90303 3.79223L0.902463 3.79398ZM2.95459 4.99799C2.95459 6.10949 3.89231 6.97648 4.99903 6.97648C6.10576 6.97648 7.04347 6.10949 7.04347 4.99799C7.04347 3.88649 6.10576 3.0195 4.99903 3.0195C3.89231 3.0195 2.95459 3.88649 2.95459 4.99799Z"
            />
        </svg>
    );
};

export { GearIcon };
