const PLAIcon = function () {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.0"
            width="17pt"
            height="17pt"
            viewBox="0 0 833.000000 833.000000"
            preserveAspectRatio="xMidYMid meet"
        >
            <g
                transform="translate(0.000000,833.000000) scale(0.100000,-0.100000)"
                fill="#005C8A"
                stroke="none"
            >
                <path d="M1740 8164 c-105 -23 -186 -68 -271 -153 -54 -54 -85 -95 -108 -144 -55 -114 -61 -167 -61 -519 l0 -318 -325 0 c-196 0 -345 -4 -377 -11 -146 -30 -303 -148 -371 -277 -19 -37 -43 -94 -54 -127 -17 -58 -18 -174 -18 -3020 0 -2797 1 -2964 18 -3024 53 -192 206 -345 398 -398 60 -17 227 -18 3024 -18 2846 0 2962 1 3020 18 33 11 90 35 127 54 129 68 247 225 277 371 7 32 11 181 11 377 l0 325 318 0 c352 0 405 6 519 61 91 43 207 158 251 249 67 136 62 -92 60 3162 l-3 2953 -22 66 c-57 166 -196 305 -362 362 l-66 22 -2960 2 c-2517 1 -2970 -1 -3025 -13z m5820 -3424 l0 -2820 -265 0 -265 0 0 2308 c0 2544 4 2365 -63 2501 -43 88 -150 195 -238 238 -136 67 43 63 -2501 63 l-2308 0 0 265 0 265 2820 0 2820 0 0 -2820z m-6260 -627 c0 -2519 -3 -2362 61 -2498 44 -95 158 -209 252 -254 135 -64 -23 -61 2500 -61 l2297 0 0 -265 0 -265 -2820 0 -2820 0 0 2820 0 2820 265 0 265 0 0 -2297z m5110 52 l0 -2245 -2245 0 -2245 0 0 2245 0 2245 2245 0 2245 0 0 -2245z" />
            </g>
        </svg>
    );
};

export { PLAIcon };
