import ClayForm from "@clayui/form";
import { ITextError } from "../interfaces/interfaces";

function TextError(props: ITextError) {
    return (
        <ClayForm.FeedbackItem>
            {/* 
            <ClayForm.FeedbackIndicator spritemap={spritemap} symbol="exclamation-full" />
            */}
            {props.children}
        </ClayForm.FeedbackItem>
    );
}

export default TextError;
