import React from "react";
import { Table } from "../../../../../../common-ui-modules/components";
import StatusColumnRenderer from "./cell-renderers/StatusColumnRenderer";

const SupportHubDetails = (props: any) => {
    const userSiteList = props?.userDetails?.data?.userSiteInfo?.userSiteList || [];
    const siteAccessReqList = props?.userDetails?.data?.userSiteInfo?.siteAccessReqList || [];
    const userAccessReqList = props?.userDetails?.data?.userSiteInfo?.userAccessReqList || [];

    const columnDefsUserSiteList = [
        { headerName: `Site ID`, field: "siteId" },
        { headerName: `Status`, field: "isActive", cellRenderer: <StatusColumnRenderer /> },
        { headerName: `SOA Status`, field: "soaStatus" },
        { headerName: `SOA Flow ID`, field: "soaFlowId" },
        { headerName: `SOA Mesasge`, field: "soaMessage" },
    ];

    const columnDefsSiteAccessReqList = [
        { headerName: `Site ID`, field: "siteId" },
        { headerName: `Status`, field: "statusCd" },
        { headerName: `Company Name`, field: "companyName" },
        { headerName: `Modified Date`, field: "modifiedDate" },
        { headerName: `Serial Number`, field: "serialNumber" },
        { headerName: `Request Type`, field: "requestType" },
    ];

    const columnDefsUserAccessReqList = [
        { headerName: `Site ID`, field: "siteId" },
        { headerName: `Status`, field: "statusCd" },
        { headerName: `Access Type`, field: "accessType" },
        { headerName: `SOA Status`, field: "soaStatus" },
        { headerName: `SOA Flow ID`, field: "soaFlowId" },
        { headerName: `SOA Mesasge`, field: "soaDescription" },
    ];

    return (
        <React.Fragment>
            <div id="supportHubDetails" className="pt-4">
                <h2 className="text-red">Support Hub Details</h2>

                {userSiteList.length === 0 &&
                    siteAccessReqList.length === 0 &&
                    userAccessReqList.length === 0 && <p className="text-red">No Details Found!</p>}

                {userAccessReqList.length > 0 && (
                    <div className="pt-4">
                        <h3 className="text-red">User Access Request</h3>

                        <Table
                            definitions={columnDefsUserAccessReqList}
                            dataSource={userAccessReqList}
                            borderedColumns={true}
                            borderless={true}
                            headVerticalAlignment={"middle"}
                            headingNoWrap={true}
                            hover={true}
                            noWrap={true}
                            responsive={true}
                            responsiveSize={"sm"}
                            striped={true}
                            tableVerticalAlignment={"middle"}
                            showPagination={false}
                            uniqueKeyProp={"userAccessRequestId"}
                            showDeltasDropDown={false}
                        />
                    </div>
                )}

                {siteAccessReqList.length > 0 && (
                    <div className="pt-4">
                        <h3 className="text-red">Site Access Request</h3>

                        <Table
                            definitions={columnDefsSiteAccessReqList}
                            dataSource={siteAccessReqList}
                            borderedColumns={true}
                            borderless={true}
                            headVerticalAlignment={"middle"}
                            headingNoWrap={true}
                            hover={true}
                            noWrap={true}
                            responsive={true}
                            responsiveSize={"sm"}
                            striped={true}
                            tableVerticalAlignment={"middle"}
                            showPagination={false}
                            uniqueKeyProp={"siteAccessRequestId"}
                            showDeltasDropDown={false}
                        />
                    </div>
                )}

                {userSiteList.length > 0 && (
                    <div className="pt-4">
                        <h3 className="text-red">User Site</h3>

                        <Table
                            definitions={columnDefsUserSiteList}
                            dataSource={userSiteList}
                            borderedColumns={true}
                            borderless={true}
                            headVerticalAlignment={"middle"}
                            headingNoWrap={true}
                            hover={true}
                            noWrap={true}
                            responsive={true}
                            responsiveSize={"sm"}
                            striped={true}
                            tableVerticalAlignment={"middle"}
                            showPagination={false}
                            uniqueKeyProp={"UserSiteId"}
                            showDeltasDropDown={false}
                        />
                    </div>
                )}

                <br />
                <hr />
            </div>
        </React.Fragment>
    );
};

export default SupportHubDetails;
