import ClayLayout from "@clayui/layout";
import Banner from "../../common-ui-modules/images/registration/banner.png";
const CommonLayout = (props: any) => {
    const { pageTitle = "", description = "" } = props;

    const styles = {
        bgImage: {
            backgroundImage: `url(${Banner})`,
        },
        container: {
            margin: "0 3rem",
        },
    };
    return (
        <div className="registration-page" style={styles.bgImage}>
            <span className="bg-gradient"></span>
            <ClayLayout.Container view className="m-auto">
                <ClayLayout.Row justify="start" style={styles.container}>
                    <ClayLayout.Col xs={12} md={5} lg={7} xl={7} className="page-left-container">
                        <h1 className="page-title">{pageTitle}</h1>
                        <p className="lead">{description}</p>
                    </ClayLayout.Col>
                    <ClayLayout.Col xs={12} md={7} lg={5} xl={5} className="page-right-container">
                        {props.children}
                    </ClayLayout.Col>
                </ClayLayout.Row>
            </ClayLayout.Container>
        </div>
    );
};
export default CommonLayout;
