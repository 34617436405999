export const DEFAULT_PAGINATION = {
    activeDelta: 25,
    activePage: 0,
    ellipsisBuffer: 0,
    totalItems: 0,
};

//For Pagination options
export const DELTAS = [{ label: 25 }, { label: 50 }, { label: 100 }, { label: 300 }];
export const DEFAULT_DELTA = 25;
export const MAX_DELTA = 300;
