import { api } from "../../../common-ui-modules";
import { API_URL } from "../constants";

export const UpdateBasicUserInfo = (infoData: any) => {
    let data = {};        
    data = {
        email: infoData.email,
        firstName: infoData.firstName,
        lastName: infoData.lastName,
        // customerId: infoData.customerId,
        countryId: infoData.country,
        countryName: infoData.countryName,
        companyId: infoData.company.companyId || infoData.companyId || null,
        companyDescription: infoData.companyId === 5000000 ? infoData.otherCompanyName : null,
        // sourceId: [3],
        // registrationId: infoData.registrationId,
        // source: "PORTAL",
    };

    //TODO: Make it a variable
    const headers = {
        "Content-Type": "application/json",
    };

    return api({
        url: API_URL.UPDATE_BASIC_USER_INFO,
        method: "POST",
        data: JSON.stringify(data),
        headers,
        skipErrorHandling: true,
    })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return error;
        });
};
