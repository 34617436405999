import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getDownloadDetails } from "../../../../../store/DownloadQueryTool/slice";

interface DownloadDetailsData {
    subFamily: string;
    displayGroup: string;
    skuCode: string;
    release: number;
    genLevel: string;
    isActive: string;
}

type DynamicCol = {
    [key: string]: string;
};

const DownloadDetails = () => {
    const downloadDetails = useSelector(getDownloadDetails);

    const [resColm, setResColm] = useState<string[]>([]);

    const COLUMNS_OF_TABLE: DynamicCol = Object.freeze({
        subFamily: "Sub Family",
        displayGroup: "Display Group",
        skuCode: "Sku Code",
        release: "Release",
        genLevel: "Gen Level",
        isActive: "Is Active",
    });

    const fetchResColm = () => {
        const col = [];
        if (downloadDetails.data.result.length > 0) {
            for (const key in downloadDetails.data.result[0]) {
                col.push(key);
            }

            setResColm(col);
        }
    };

    useEffect(() => {
        if (downloadDetails.success) {
            fetchResColm();
        }
    }, [downloadDetails]);

    return (
        <React.Fragment>
            {downloadDetails.success && downloadDetails.data.result.length > 0 ? (
                <div className="table-container">
                    <div className="table-responsive table-responsive-sm">
                        <table
                            className="table table-autofit table-heading-nowrap table-nowrap thead-valign-left table-valign-left sip-table"
                            id="salesRepContractor"
                        >
                            <thead>
                                <tr className="">
                                    {resColm.map((col: string, index: number) => {
                                        return (
                                            <th
                                                key={index}
                                                className=" sip-table-header sip-table-employeeName text-left"
                                                style={{ minWidth: "auto" }}
                                            >
                                                <p className="table-list-title">
                                                    <span>
                                                        <span className="pr-2">
                                                            {COLUMNS_OF_TABLE[col]}
                                                        </span>
                                                    </span>
                                                </p>
                                            </th>
                                        );
                                    })}
                                </tr>
                            </thead>
                            <tbody>
                                {downloadDetails.data.result.map(
                                    (data: DownloadDetailsData, index: number) => {
                                        return (
                                            <tr
                                                className="sip-table-row sip-table-hover-row"
                                                key={index}
                                            >
                                                <td
                                                    className="sip-table-cell sip-table-employeeName  text-left"
                                                    style={{ minWidth: "auto" }}
                                                >
                                                    <div className="d-flex align-items-center">
                                                        <span className="exclamation-mark-placeholder mr-1"></span>
                                                        &nbsp;
                                                        <span
                                                            title="test"
                                                            className="mr-1 isoverriden-renderer"
                                                        ></span>
                                                        <div>{data.subFamily}</div>
                                                    </div>
                                                </td>
                                                <td
                                                    className="sip-table-cell sip-table-totalBasePayAmountAnnualized  text-left"
                                                    style={{ minWidth: "auto" }}
                                                >
                                                    {data.displayGroup}
                                                </td>
                                                <td
                                                    className="sip-table-cell sip-table-totalBasePayAmountAnnualized  text-left"
                                                    style={{ minWidth: "auto" }}
                                                >
                                                    {data.skuCode}
                                                </td>
                                                <td
                                                    className="sip-table-cell sip-table-totalBasePayAmountAnnualized  text-left"
                                                    style={{ minWidth: "auto" }}
                                                >
                                                    {data.release}
                                                </td>
                                                <td
                                                    className="sip-table-cell sip-table-totalBasePayAmountAnnualized  text-left"
                                                    style={{ minWidth: "auto" }}
                                                >
                                                    {data.genLevel}
                                                </td>
                                                <td
                                                    className="sip-table-cell sip-table-totalBasePayAmountAnnualized  text-left"
                                                    style={{ minWidth: "auto" }}
                                                >
                                                    {data.isActive}
                                                </td>
                                            </tr>
                                        );
                                    },
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            ) : (
                ""
            )}
        </React.Fragment>
    );
};

export default DownloadDetails;
