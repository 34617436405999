import { useEffect, useRef } from 'react';
import ClayForm from "@clayui/form";
import { ErrorMessage, Field } from "formik";
import { Typeahead } from "react-bootstrap-typeahead";

import TextError from "./TextError";
import { InfoButton } from "../../InfoButton/InfoButton";
import { IAutocomplete } from '../../../interfaces/interfaces';

const AutoComplete = (props: IAutocomplete) => {
    const {
        label,
        name,
        options,
        placeholder,
        required,
        size,
        customClassNameForLabel,
        customClassNameForField,
        showInfoIcon = false,
        isClear,
        defaultValue
    } = props ;
    const formSize = size ? size : "sm";
    const fieldStyle = customClassNameForField ? customClassNameForField : "rbt-highlight-text"
    const typeaheadRef: any = useRef(null);
    //to reset the field when not clicked on any dropdown value
    useEffect(() => {
        if (isClear) {
            typeaheadRef.current._handleClear();
        }
    }, [isClear]);

    return (
        <div>
            {label && (
                <label htmlFor={name} className={customClassNameForLabel}>
                    {required && <span className="text-red">* </span>}
                    {label}
                    {showInfoIcon && (
                        <InfoButton info={props.information} align={props.alignPosition} />
                    )}
                </label>
            )}
            <Field name={name}>
                {({ form, field }: { form: any; field: any }) => {
                    return (
                        <ClayForm.Group
                            className={`form-group-${formSize} ${customClassNameForLabel} ${
                                form.errors[name] && form.touched[name] !== undefined
                                    ? "has-error"
                                    : ""
                            }`}
                        >
                            <Typeahead
                                highlightOnlyResult={false}
                                id={name}
                                labelKey="label"
                                align="justify"
                                options={options}
                                placeholder={placeholder}
                                ref={typeaheadRef}
                                className={fieldStyle}
                                size={formSize}
                                defaultInputValue={defaultValue}
                                {...props}
                            />
                            <ErrorMessage name={name} component={TextError} />
                        </ClayForm.Group>
                    );
                }}
            </Field>
        </div>
    );
}

export default AutoComplete;
