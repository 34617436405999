const UnsortIcon = function (props: any) {
    let color = "";
    switch (props.color) {
        case "blue":
            color = "#005C8A";
            break;
        case "grey":
            color = "#ADB5BD";
            break;
        case "dark-grey":
            color = "#4F575E";
            break;
        case "black":
            color = "#101213";
            break;
        default:
            color = "#101213";
            break;
    }

    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fill={color}
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.1298 9.26995C11.2146 9.35477 11.257 9.45522 11.257 9.57129C11.257 9.68736 11.2146 9.78781 11.1298 9.87263L8.12981 12.8726C8.04499 12.9574 7.94454 12.9999 7.82847 
        12.9999C7.7124 12.9999 7.61196 12.9574 7.52713 12.8726L4.52713 9.87263C4.44231 9.78781 4.3999 9.68736 4.3999 9.57129C4.3999 9.45522 4.44231 9.35477 4.52713 
        9.26995C4.61196 9.18513 4.7124 9.14272 4.82847 9.14272H10.8285C10.9445 9.14272 11.045 9.18513 11.1298 9.26995ZM11.1298 6.69852C11.2146 6.78334 11.257 6.88379 11.257 
        6.99986C11.257 7.11593 11.2146 7.21638 11.1298 7.3012C11.045 7.38602 10.9445 7.42843 10.8285 7.42843H4.82847C4.7124 7.42843 4.61196 7.38602 4.52713 7.3012C4.44231 
        7.21638 4.3999 7.11593 4.3999 6.99986C4.3999 6.88379 4.44231 6.78334 4.52713 6.69852L7.52713 3.69852C7.61196 3.6137 7.7124 3.57129 7.82847 3.57129C7.94454 3.57129 
        8.04499 3.6137 8.12981 3.69852L11.1298 6.69852Z"
            />
        </svg>
    );
};

export { UnsortIcon };
