import ClayForm from "@clayui/form";
import { Field, ErrorMessage } from "formik";
import { ITextArea } from "../interfaces/interfaces";
import TextError from "./TextError";

function TextArea(props:ITextArea) {
    const { label, name, isMandatory } = props;
    return (
        <div>
            <label htmlFor={name}>
                {label}
                {isMandatory && <span className="text-red text-size-lg">* </span>}
            </label>
            <Field name={name}>
                {({ form, field }) => {
                    return (
                        <ClayForm.Group
                            className={
                                "form-group-sm " +
                                (form.errors[name] && form.touched[name] !== undefined
                                    ? "has-error"
                                    : "")
                            }
                        >
                            <textarea
                                className="form-control"
                                value={name}
                                placeholder={label}
                                onChange={field.onChange}
                                {...field}
                            ></textarea>
                            <ErrorMessage name={name} component={TextError} />
                        </ClayForm.Group>
                    );
                }}
            </Field>
        </div>
    );
}

export default TextArea;
