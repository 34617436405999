const ProfLogoutIcon = function () {
    return (
        <svg
            width="14"
            height="14"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M7.5 17.8149H4.16667C3.72464 17.8149 3.30072 17.6393 2.98816 17.3268C2.67559 17.0142 2.5 16.5903 2.5 16.1483V4.48161C2.5 4.03958 2.67559 3.61566 2.98816 3.3031C3.30072 2.99054 3.72464 2.81494 4.16667 2.81494H7.5"
                stroke="#272B30"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            ></path>
            <path
                d="M13.333 14.4818L17.4997 10.3151L13.333 6.14844"
                stroke="#272B30"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            ></path>
            <path
                d="M17.5 10.3149H7.5"
                stroke="#272B30"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            ></path>
        </svg>
    );
};

export { ProfLogoutIcon };
