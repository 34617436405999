import { useState } from "react";
import ClayIcon from "@clayui/icon";
import { claySpriteImagePath } from "../../common-ui-modules/service/util/claySpriteImagePath";

import "./ScrollButton.scss";

const ScrollButton = () => {
    const [visible, setVisible] = useState(false);

    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 200) {
            setVisible(true);
        } else if (scrolled <= 200) {
            setVisible(false);
        }
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    window.addEventListener("scroll", toggleVisible);

    return (
        <div
            onClick={() => scrollToTop()}
            className="scrollBtn"
            style={{ display: visible ? "inline" : "none" }}
        >
            <div className="scrollBtnContents">
                <b>
                    {"Top"} &nbsp;
                    <ClayIcon symbol="arrow-up-full" spritemap={claySpriteImagePath()} />
                </b>
            </div>
        </div>
    );
};

export default ScrollButton;
