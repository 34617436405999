const RecentActivityDownloadIcon = () => {
    return (
        <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="20" cy="20" r="20" fill="#F7ECFA" />
            <path
                d="M28 22.666V26.2216C28 26.6931 27.8127 27.1453 27.4793 27.4786C27.1459 27.812 26.6937 27.9993 26.2222 27.9993H13.7778C13.3063 27.9993 12.8541 27.812 12.5207 27.4786C12.1873 27.1453 12 26.6931 12 26.2216V22.666"
                stroke="#9435AB"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M15.5557 18.2227L20.0001 22.6671L24.4446 18.2227"
                stroke="#9435AB"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M20 22.6667V12"
                stroke="#9435AB"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export { RecentActivityDownloadIcon };
