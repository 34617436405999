const NoDownloadHistoryIcon = function () {
    return (
        <svg
            width="56"
            height="56"
            viewBox="0 0 56 56"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="28" cy="28" r="28" fill="#F1F3F5" />
            <path
                d="M24.0088 33.0117L28.0088 37.0117L32.0088 33.0117"
                stroke="#ADB5BD"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M28.0088 28.0117V37.0117"
                stroke="#ADB5BD"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M36.889 34.1017C37.7584 33.4903 38.4103 32.6178 38.7503 31.6108C39.0902 30.6038 39.1004 29.5147 38.7794 28.5015C38.4584 27.4883 37.8228 26.6037 36.965 25.9762C36.1072 25.3487 35.0718 25.0108 34.009 25.0117H32.749C32.4482 23.8395 31.8855 22.7509 31.1031 21.8277C30.3207 20.9046 29.3391 20.1709 28.2322 19.682C27.1252 19.1931 25.9218 18.9617 24.7125 19.0052C23.5032 19.0487 22.3195 19.3659 21.2505 19.9331C20.1816 20.5002 19.2552 21.3025 18.5412 22.2795C17.8271 23.2565 17.344 24.3827 17.1282 25.5734C16.9124 26.7641 16.9696 27.9882 17.2953 29.1537C17.6211 30.3191 18.207 31.3954 19.009 32.3017"
                stroke="#ADB5BD"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export { NoDownloadHistoryIcon };
