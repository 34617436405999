import ClayLayout from "@clayui/layout";
import "./NoAccess.scss";

function NoAccess() {
    return (
        <div>
            <ClayLayout.ContainerFluid view size={false}>
                <ClayLayout.Row>
                    <ClayLayout.Col size={12}>
                        <ClayLayout.Row>
                            <div className="textInput">
                                {`You don't have Admin privilages to access this page.`}
                            </div>
                        </ClayLayout.Row>
                    </ClayLayout.Col>
                </ClayLayout.Row>
            </ClayLayout.ContainerFluid>
        </div>
    );
}
export { NoAccess };
