import ClayForm, { ClayCheckbox } from "@clayui/form";
import { Field, ErrorMessage } from "formik";
import TextError from "./TextError";
import { InfoButton } from "../../index";
import { ICheckbox } from "../../../interfaces/interfaces";

const Checkbox = (props: ICheckbox) => {
    const { label, name, info, arrayName, itemIdx } = props;

    const formErrors = (form) => {
        if (
            arrayName &&
            form.errors[arrayName] &&
            form.errors[arrayName][itemIdx] &&
            form.touched[arrayName] &&
            form.touched[arrayName][itemIdx] !== undefined
        ) {
            return true;
        } else if (!arrayName && form.errors[name] && form.touched[name] !== undefined) {
            return true;
        } else {
            return false;
        }
    };
    return (
        <div>
            {/* <label htmlFor={name}>{label}</label> */}
            <Field name={name}>
                {({ form, field }) => {
                    const { setFieldValue } = form;
                    const { value } = field;
                    return (
                        <ClayForm.Group
                            className={"form-group-sm " + (formErrors(form) ? "has-error" : "")}
                        >
                            <ClayCheckbox
                                aria-label={label}
                                checked={value}
                                label={label}
                                onChange={(e) => {
                                    setFieldValue(name, e.target.checked);
                                }}
                                {...field}
                                {...props}
                            >
                                {info && <InfoButton info={info} />}
                            </ClayCheckbox>
                            <ErrorMessage name={name} component={TextError} />
                        </ClayForm.Group>
                    );
                }}
            </Field>
        </div>
    );
}

export default Checkbox;
