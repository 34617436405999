
import AutoComplete from "./controls/AutoComplete";
import Select from "./controls/Select";
import Text from "./controls/Text";
import Email from "./controls/Email";
import TextArea from "./controls/TextArea";
import Checkbox from "./controls/Checkbox";
import TextWithSearchIcon from "./controls/TextWithSearchIcon";

const FormControl = (props: any) => {
    const { control } = props;
    switch (control) {
        case "text":
            return <Text {...props} />;
        case "textarea":
            return <TextArea {...props} />;
        case "autocomplete":
            return <AutoComplete {...props} />;
        case "select":
            return <Select {...props} />;
        case "email":
            return <Email {...props} />;
        case "checkbox":
            return <Checkbox {...props} />;
        case "textwithsearchicon":
            return <TextWithSearchIcon {...props} />;
        default:
            return null;
    }
};

export { FormControl };
