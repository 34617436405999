const SolidIcon = function () {
    return (
        <svg
            width="203"
            height="102"
            viewBox="0 0 203 102"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect
                width="17.9823"
                height="17.9632"
                transform="matrix(0.981669 0.190595 -0.191016 0.981587 11.6519 23.8726)"
                fill="#ADB5BD"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.0439 29.5692C13.0899 29.3554 13.2948 29.2164 13.5085 29.2495L13.5323 29.2539L26.0467 31.9374C26.2688 31.985 26.4101 32.2034 26.3625 32.4252C26.3165 32.639 26.1115 32.778 25.8979 32.7449L25.8741 32.7405L13.3597 30.057C13.1376 30.0094 12.9962 29.791 13.0439 29.5692Z"
                fill="#6A7178"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.5918 31.9106C12.6377 31.6967 12.8426 31.5578 13.0563 31.5908L13.0801 31.5952L25.1559 34.1828C25.378 34.2304 25.5193 34.4488 25.4717 34.6706C25.4258 34.8844 25.2208 35.0234 25.0072 34.9904L24.9834 34.986L12.9076 32.3984C12.6855 32.3508 12.5441 32.1324 12.5918 31.9106Z"
                fill="#6A7178"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.8105 34.3065C11.8565 34.0926 12.0615 33.9537 12.2752 33.9868L12.2989 33.9912L21.5511 35.9764C21.7732 36.0241 21.9145 36.2425 21.8668 36.4643C21.8208 36.6781 21.6158 36.817 21.4022 36.784L21.3784 36.7796L12.1262 34.7943C11.9042 34.7467 11.7628 34.5283 11.8105 34.3065Z"
                fill="#6A7178"
            />
            <rect
                width="17.9803"
                height="17.9663"
                transform="matrix(0.916465 -0.400114 0.400884 0.916129 0 39.5356)"
                fill="#ADB5BD"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.9341 40.2829C14.1441 40.1967 14.3844 40.2969 14.4707 40.5067C14.554 40.709 14.4637 40.9394 14.2688 41.0329L14.2467 41.0427L6.47484 44.2328C6.26478 44.319 6.02452 44.2188 5.9382 44.009C5.85496 43.8067 5.94526 43.5763 6.14016 43.4828L6.16225 43.4729L13.9341 40.2829Z"
                fill="#6A7178"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.3848 44.7871C11.595 44.7011 11.8352 44.8015 11.9213 45.0115C12.0043 45.2139 11.9138 45.4441 11.7188 45.5375L11.6967 45.5473L8.05064 47.0396C7.84049 47.1256 7.60033 47.0252 7.51421 46.8153C7.43118 46.6129 7.52171 46.3826 7.7167 46.2893L7.7388 46.2795L11.3848 44.7871Z"
                fill="#6A7178"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.56739 34.4608L9.37549 35.4466L16.2701 32.8863L21.6295 44.1131L22.6027 44.2911L16.695 31.8047L9.56739 34.4608Z"
                fill="white"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M194.145 59.2148H123.239C118.427 59.2148 114.521 55.3127 114.521 50.5071V0H202.862V50.5071C202.862 55.3264 198.956 59.2148 194.145 59.2148Z"
                fill="#005C8A"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M163.057 38.0345C163.057 38.0345 159.767 38.8833 159.041 35.8302C159.041 35.8302 161.591 34.7212 163.057 38.0345Z"
                fill="white"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M155.477 30.1754C155.847 30.4082 156.258 30.5725 156.67 30.7094C156.272 31.2433 156.08 31.9553 156.217 32.6946C156.354 33.5161 156.848 34.1733 157.465 34.4882C157.3 35.4876 157.012 36.9252 156.683 37.4318C156.519 37.6782 156.615 38.0479 156.889 38.2669C157.163 38.4723 157.52 38.4449 157.684 38.1985C158.164 37.4592 158.493 35.6519 158.643 34.6798C158.671 34.6798 158.685 34.6798 158.712 34.6661C159.863 34.4471 160.617 33.2012 160.398 31.8731C160.329 31.4487 160.151 31.0653 159.918 30.7504C161.193 30.4218 162.372 29.6962 163.194 28.6009C164.962 26.2323 164.387 23.097 161.906 21.591C159.425 20.0849 155.971 20.7832 154.202 23.1381C152.421 25.5204 152.996 28.6557 155.477 30.1754Z"
                fill="white"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M143.045 53.2178L130.462 50.7808L126.679 43.1L128.817 32.1606L146.472 35.5835L143.045 53.2178Z"
                fill="#ADB5BD"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M131.154 37.018C131.192 36.8027 131.392 36.6566 131.607 36.6821L131.631 36.6856L141.952 38.5203C142.176 38.56 142.325 38.7733 142.285 38.9966C142.247 39.212 142.047 39.358 141.832 39.3326L141.808 39.329L131.487 37.4944C131.263 37.4546 131.114 37.2414 131.154 37.018Z"
                fill="white"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M130.839 38.8252C130.877 38.6098 131.077 38.4637 131.292 38.4892L131.316 38.4928L141.637 40.3274C141.861 40.3671 142.01 40.5804 141.97 40.8037C141.932 41.0191 141.732 41.1652 141.517 41.1397L141.493 41.1361L131.172 39.3015C130.948 39.2618 130.799 39.0485 130.839 38.8252Z"
                fill="white"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M130.551 40.7281C130.59 40.5128 130.79 40.3669 131.004 40.3926L131.028 40.3961L137.155 41.4914C137.379 41.5314 137.528 41.7448 137.488 41.9681C137.449 42.1834 137.249 42.3293 137.034 42.3036L137.01 42.3001L130.883 41.2048C130.66 41.1648 130.511 40.9514 130.551 40.7281Z"
                fill="white"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M126.742 42.8956C126.786 42.6812 126.989 42.54 127.203 42.5706L127.227 42.5747L132.298 43.6015C132.516 43.6455 132.658 43.853 132.623 44.0689L132.618 44.0929L131.179 50.5826C131.13 50.8041 130.91 50.9438 130.689 50.8948C130.475 50.8475 130.337 50.6418 130.371 50.4286L130.376 50.4049L131.725 44.3235L127.063 43.3798C126.849 43.3363 126.707 43.1332 126.738 42.9194L126.742 42.8956Z"
                fill="white"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M114.521 9.17334C114.748 9.17334 114.932 9.35723 114.932 9.58408C114.932 9.80282 114.761 9.98163 114.545 9.99412L114.521 9.99482H111.749L111.72 10.2308C111.697 10.4061 111.672 10.5953 111.643 10.7977L111.615 10.994C111.457 12.0762 111.248 13.2815 110.979 14.5791C110.212 18.2873 109.128 21.9961 107.671 25.4613C105.53 30.5509 102.766 34.6704 99.2901 37.4687C95.1074 40.8364 90.0545 42.1507 84.0856 41.0535L83.329 40.9149C75.3207 39.4523 69.2885 38.5074 62.8324 37.818L62.6305 37.7965C53.5969 36.8406 45.5118 36.6491 38.4097 37.3922C30.0462 38.2674 23.305 40.4229 18.4195 44.0408C12.9318 48.1046 9.85098 53.9697 9.44291 61.865C9.0243 69.8977 10.21 75.3312 12.7352 78.4734C14.8233 81.0717 17.789 82.0523 21.4454 81.7004C23.9539 81.4589 26.6534 80.6035 29.3816 79.3272C30.5286 78.7907 31.6025 78.2156 32.5723 77.6408L32.7535 77.5327C32.9604 77.4083 33.152 77.2902 33.3271 77.1795L33.4731 77.0865C33.5487 77.038 33.6161 76.9942 33.675 76.9553L33.7877 76.8801C33.9751 76.7519 34.231 76.7997 34.3593 76.9869C34.4876 77.1741 34.4397 77.4297 34.2524 77.5579L34.1645 77.6168C34.106 77.6556 34.039 77.6995 33.9637 77.748L33.9176 77.7777C33.6493 77.9498 33.3395 78.1413 32.992 78.3473C32.0002 78.9351 30.9031 79.5226 29.7304 80.0711C26.9241 81.3839 24.1396 82.2663 21.5242 82.518C17.6122 82.8945 14.373 81.8236 12.0939 78.9876C9.42064 75.6612 8.19347 70.0377 8.62159 61.8225C9.04218 53.6851 12.2425 47.5924 17.9297 43.3809C22.95 39.6632 29.8265 37.4644 38.324 36.5752C45.4909 35.8253 53.6308 36.0181 62.7171 36.9796L63.1221 37.0229C69.7286 37.7348 75.9124 38.7159 84.2345 40.2456C89.9658 41.2991 94.7763 40.0479 98.774 36.8292C102.129 34.1276 104.82 30.1175 106.913 25.1432C108.347 21.7317 109.417 18.0726 110.174 14.4129C110.426 13.1928 110.626 12.0559 110.778 11.0289L110.801 10.8757C110.846 10.5651 110.884 10.2837 110.916 10.0339L110.95 9.74865L110.973 9.54332C110.993 9.34138 111.157 9.18572 111.358 9.17404L111.382 9.17334H114.521Z"
                fill="#005C8A"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M113.904 10.8164H111.629C111.286 10.8164 111.012 10.5426 111.012 10.2003V9.00917C111.012 8.66689 111.286 8.39307 111.629 8.39307H113.904C114.247 8.39307 114.521 8.66689 114.521 9.00917V10.2003C114.521 10.5426 114.233 10.8164 113.904 10.8164Z"
                fill="#CED4DA"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M36.1993 76.8084C37.3644 75.85 37.7619 69.8532 32.8685 72.4956C27.9751 75.138 31.5938 78.9442 33.9925 77.9585C36.3775 76.9727 36.1993 76.8084 36.1993 76.8084Z"
                fill="#CED4DA"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M50.6112 36.3355C50.7216 36.5798 50.6202 36.8657 50.3848 36.9876L50.341 37.0082L50.1767 37.0839L49.9449 37.1926C49.6029 37.3541 49.2057 37.5464 48.758 37.7687L48.471 37.9119C47.3133 38.4921 46.0416 39.1614 44.6817 39.9163L44.5113 40.0111C40.1164 42.4606 35.7217 45.2839 31.6223 48.4406C23.4222 54.7549 17.4603 61.5824 14.6844 68.7751C11.2659 77.6327 12.8633 86.619 20.4519 95.6813C20.6299 95.8939 20.6017 96.2105 20.3888 96.3884C20.1759 96.5662 19.859 96.538 19.6809 96.3254C11.8608 86.9865 10.1951 77.6162 13.7466 68.414C16.6003 61.0196 22.676 54.062 31.0087 47.6455C35.1507 44.456 39.5862 41.6065 44.0215 39.1344L44.3647 38.9439C45.7299 38.1891 47.0057 37.5206 48.1661 36.9416L48.3106 36.8697C48.6671 36.6927 48.9923 36.5343 49.284 36.3949L49.6636 36.2153L49.9464 36.0846C50.1993 35.9706 50.4969 36.0829 50.6112 36.3355Z"
                fill="#005C8A"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20.6145 97.0304C21.8755 97.8518 27.7147 96.4416 23.7396 92.567C19.7646 88.6787 17.1877 93.2653 18.8463 95.2642C20.5185 97.2494 20.6145 97.0304 20.6145 97.0304Z"
                fill="#CED4DA"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M55.4714 38.6785H49.9475C48.9469 38.6785 48.1245 37.8707 48.1245 36.8576C48.1245 35.8581 48.9332 35.0366 49.9475 35.0366H55.4714C56.472 35.0366 57.2945 35.8444 57.2945 36.8576C57.2945 37.8707 56.472 38.6785 55.4714 38.6785Z"
                fill="#CED4DA"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M178.724 94.2378L173.173 92.499C170.336 91.609 168.759 88.6106 169.65 85.7765L175.147 68.238C176.038 65.4039 179.04 63.8294 181.877 64.7193L187.428 66.4581C190.266 67.3481 191.842 70.3465 190.951 73.1806L185.454 90.7191C184.577 93.5532 181.562 95.1277 178.724 94.2378Z"
                fill="white"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M174.755 68.1146L169.258 85.6535C168.3 88.7018 169.994 91.932 173.05 92.8904L178.601 94.6293C181.657 95.5875 184.903 93.8909 185.847 90.84L191.344 73.3029C192.302 70.2546 190.607 67.0244 187.552 66.0659L182 64.3271C178.948 63.3698 175.714 65.0622 174.755 68.1146ZM181.754 65.1106L187.305 66.8494C189.928 67.6721 191.381 70.4416 190.559 73.0569L185.062 90.5958C184.252 93.2128 181.469 94.6674 178.848 93.8453L173.296 92.1064C170.673 91.2838 169.221 88.5143 170.043 85.899L175.539 68.3601C176.363 65.7404 179.135 64.2894 181.754 65.1106Z"
                fill="#6A7178"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M176.984 83.9141C176.984 83.9141 178.218 83.8867 178.218 85.0231C178.218 85.0231 177.217 85.2148 176.984 83.9141Z"
                fill="#6A7178"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M179.012 87.3234C178.903 87.2139 178.766 87.1181 178.642 87.0359C178.821 86.8853 178.958 86.6389 178.971 86.365C178.985 86.0638 178.875 85.79 178.684 85.6257C178.821 85.2834 179.04 84.8042 179.204 84.6536C179.287 84.5852 179.287 84.4346 179.204 84.3387C179.122 84.2429 178.999 84.2155 178.917 84.2977C178.684 84.5167 178.423 85.1328 178.286 85.4614C178.272 85.4614 178.272 85.4614 178.259 85.4614C177.834 85.4477 177.464 85.8174 177.436 86.3103C177.422 86.4746 177.464 86.6115 177.518 86.7484C177.039 86.7621 176.559 86.9264 176.189 87.2413C175.366 87.9259 175.312 89.0896 176.065 89.8289C176.819 90.5683 178.094 90.6093 178.917 89.9111C179.712 89.2128 179.766 88.0628 179.012 87.3234Z"
                fill="#6A7178"
            />
            <rect
                width="36.6384"
                height="43.1982"
                transform="matrix(0.986508 -0.163714 0.164079 0.986447 61.4741 59.3838)"
                fill="#6A7178"
            />
            <rect
                width="33.6999"
                height="39.7377"
                transform="matrix(0.930952 -0.365141 0.365867 0.930667 52.6001 62.2261)"
                fill="white"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M83.8146 49.5387L52.45 61.8363C52.2386 61.9191 52.1346 62.1575 52.2177 62.3687L66.7648 99.3591C66.8478 99.5702 67.0865 99.6743 67.2978 99.5914L98.6625 87.2939C98.8738 87.211 98.9778 86.9727 98.8947 86.7615L84.3476 49.7711C84.2646 49.5599 84.0259 49.4559 83.8146 49.5387ZM83.7327 50.4537L97.979 86.6792L67.3797 98.6767L53.1334 62.4512L83.7327 50.4537Z"
                fill="#6A7178"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M56.6424 66.6315C56.9623 66.5061 57.3234 66.6635 57.449 66.9831C57.5709 67.2932 57.426 67.6422 57.1249 67.7771L57.097 67.7888L51.299 70.0615C50.9791 70.1869 50.6179 70.0295 50.4924 69.71C50.3705 69.3998 50.5154 69.0508 50.8165 68.9159L50.8443 68.9043L56.6424 66.6315Z"
                fill="#6A7178"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M59.0538 72.7662C59.3735 72.6402 59.7349 72.7968 59.8611 73.1161C59.9836 73.4261 59.8395 73.7753 59.5386 73.9108L59.5108 73.9226L53.7128 76.209C53.3931 76.3351 53.0317 76.1784 52.9055 75.8591C52.783 75.5492 52.9271 75.2 53.228 75.0644L53.2558 75.0527L59.0538 72.7662Z"
                fill="#6A7178"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M61.4671 78.9132C61.787 78.7878 62.1482 78.9452 62.2737 79.2648C62.3956 79.575 62.2507 79.9239 61.9496 80.0588L61.9218 80.0705L56.1237 82.3432C55.8038 82.4686 55.4427 82.3113 55.3171 81.9917C55.1952 81.6815 55.3401 81.3326 55.6413 81.1977L55.6691 81.186L61.4671 78.9132Z"
                fill="#6A7178"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M63.8797 85.0465C64.1996 84.9211 64.5608 85.0785 64.6863 85.3981C64.8082 85.7083 64.6633 86.0572 64.3622 86.1921L64.3344 86.2038L58.5363 88.4765C58.2164 88.6019 57.8553 88.4446 57.7297 88.125C57.6078 87.8148 57.7527 87.4659 58.0538 87.331L58.0817 87.3193L63.8797 85.0465Z"
                fill="#6A7178"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M66.3053 91.1806C66.6251 91.0548 66.9864 91.2118 67.1123 91.5312C67.2346 91.8412 67.0902 92.1903 66.7892 92.3256L66.7614 92.3373L60.9496 94.6238C60.6299 94.7496 60.2685 94.5926 60.1426 94.2732C60.0203 93.9632 60.1648 93.6141 60.4657 93.4788L60.4935 93.4671L66.3053 91.1806Z"
                fill="#6A7178"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M74.2509 65.1438C74.4623 65.0609 74.7009 65.1648 74.7839 65.376C74.864 65.5796 74.77 65.8085 74.5737 65.8989L74.5514 65.9084L63.4488 70.2622C63.2374 70.3451 62.9988 70.2411 62.9158 70.03C62.8357 69.8264 62.9297 69.5974 63.126 69.507L63.1483 69.4976L74.2509 65.1438Z"
                fill="#CED4DA"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M79.4732 65.3493C79.6847 65.2664 79.9233 65.3705 80.0062 65.5817C80.0861 65.7854 79.9921 66.0142 79.7957 66.1046L79.7735 66.114L68.6571 70.4679C68.4457 70.5507 68.2071 70.4466 68.1242 70.2354C68.0442 70.0318 68.1383 69.8029 68.3347 69.7125L68.3569 69.7031L79.4732 65.3493Z"
                fill="#CED4DA"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M66.218 70.538C66.4293 70.4548 66.6681 70.5585 66.7514 70.7696C66.8316 70.9731 66.738 71.2021 66.5417 71.2928L66.5195 71.3023L64.2578 72.1922C64.0465 72.2754 63.8078 72.1717 63.7245 71.9606C63.6443 71.7571 63.7379 71.5281 63.9342 71.4374L63.9564 71.4279L66.218 70.538Z"
                fill="#CED4DA"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M70.5357 76.8638C70.7469 76.7805 70.9858 76.8839 71.0692 77.0949C71.1497 77.2983 71.0563 77.5275 70.8601 77.6183L70.8379 77.6278L66.9862 79.1475C66.775 79.2309 66.5361 79.1274 66.4527 78.9164C66.3722 78.713 66.4657 78.4839 66.6619 78.393L66.6841 78.3835L70.5357 76.8638Z"
                fill="#CED4DA"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M72.6744 73.4681C72.8857 73.385 73.1245 73.4889 73.2076 73.7C73.2877 73.9036 73.1939 74.1325 72.9976 74.2231L72.9754 74.2325L66.1082 76.9297C65.8968 77.0127 65.6581 76.9089 65.575 76.6978C65.4949 76.4942 65.5887 76.2652 65.785 76.1747L65.8072 76.1652L72.6744 73.4681Z"
                fill="#CED4DA"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M68.6863 72.1675C68.8978 72.0848 69.1363 72.189 69.2191 72.4003C69.299 72.604 69.2048 72.8328 69.0084 72.923L68.9861 72.9325L65.1345 74.4385C64.923 74.5212 64.6844 74.417 64.6016 74.2058C64.5218 74.0021 64.616 73.7732 64.8124 73.683L64.8347 73.6736L68.6863 72.1675Z"
                fill="#CED4DA"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M76.8002 68.9773C77.0116 68.8943 77.2503 68.9982 77.3334 69.2093C77.4135 69.4129 77.3197 69.6418 77.1234 69.7324L77.1012 69.7418L70.6863 72.261C70.4749 72.344 70.2362 72.2402 70.1531 72.0291C70.073 71.8255 70.1668 71.5965 70.3631 71.506L70.3854 71.4965L76.8002 68.9773Z"
                fill="#CED4DA"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M85.202 62.1587L84.4893 64.7874L84.5578 89.5823H85.997L85.9285 64.7737L85.202 62.1587Z"
                fill="#CED4DA"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M87.0304 82.295C87.2513 82.2905 87.4354 82.4593 87.4525 82.6765L87.4537 82.7008L87.5771 88.7113C87.5817 88.9354 87.4074 89.121 87.1862 89.1335L87.1623 89.1342H84.5579C84.3288 89.1342 84.1431 88.9486 84.1431 88.7198C84.1431 88.4991 84.3158 88.3187 84.5336 88.3061L84.5579 88.3054L86.7387 88.3053L86.6241 82.7178C86.6196 82.4972 86.7886 82.3133 87.0061 82.2962L87.0304 82.295Z"
                fill="#005C8A"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M84.8321 73.3305H85.6271C85.9698 73.3305 86.2439 73.0567 86.2439 72.7281L86.285 66.7177C86.285 66.3754 86.0109 66.1016 85.6819 66.1016H84.8869C84.5443 66.1016 84.2701 66.3754 84.2701 66.704L84.229 72.7144C84.229 73.0567 84.5031 73.3305 84.8321 73.3305Z"
                fill="#005C8A"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M85.3225 79.8034C85.5434 79.809 85.7195 79.9861 85.7266 80.2039L85.7266 80.2282L85.6444 83.432C85.6385 83.6607 85.4481 83.8415 85.219 83.8356C84.9982 83.8299 84.8221 83.6529 84.815 83.4351L84.8149 83.4107L84.8972 80.207C84.9031 79.9782 85.0935 79.7975 85.3225 79.8034Z"
                fill="#005C8A"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M78.102 56.4629L77.7456 59.1464L81.1175 83.7222L82.543 83.5305L79.1711 58.9547L78.102 56.4629Z"
                fill="#CED4DA"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M74.4559 22.8509L64.7377 24.1379C63.3944 24.3159 62.1608 23.3712 61.9826 22.0295L61.9552 21.8104C61.777 20.4687 62.7227 19.2365 64.066 19.0585L73.7843 17.7715L74.4559 22.8509Z"
                fill="white"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M73.7304 17.3645L64.0121 18.6514C62.4593 18.8572 61.362 20.2692 61.5417 21.8171L61.5747 22.0806C61.7831 23.6504 63.2236 24.753 64.7919 24.5452L74.5101 23.2583C74.7352 23.2285 74.8934 23.0221 74.8637 22.7973L74.1921 17.7178C74.1623 17.4929 73.9556 17.3346 73.7304 17.3645ZM73.4304 18.2327L73.9943 22.4978L64.6837 23.7309C63.5658 23.879 62.5386 23.0927 62.3903 21.9756L62.3633 21.7596C62.2146 20.6399 63.0017 19.6139 64.1202 19.4657L73.4304 18.2327Z"
                fill="#6A7178"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M84.7222 30.9836C91.8003 30.9836 97.5382 25.2522 97.5382 18.1822C97.5382 11.1122 91.8003 5.38086 84.7222 5.38086C77.6442 5.38086 71.9062 11.1122 71.9062 18.1822C71.9062 25.2522 77.6442 30.9836 84.7222 30.9836Z"
                fill="white"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M84.7223 4.97021C77.4171 4.97021 71.4951 10.8855 71.4951 18.1823C71.4951 25.4791 77.4171 31.3944 84.7223 31.3944C92.0275 31.3944 97.9495 25.4791 97.9495 18.1823C97.9495 10.8855 92.0275 4.97021 84.7223 4.97021ZM84.7223 5.79188C91.5733 5.79188 97.1271 11.3393 97.1271 18.1825C97.1271 25.0256 91.5733 30.5731 84.7223 30.5731C77.8713 30.5731 72.3175 25.0256 72.3175 18.1825C72.3175 11.3393 77.8713 5.79188 84.7223 5.79188Z"
                fill="#6A7178"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M84.7224 27.2317C89.7263 27.2317 93.7827 23.1799 93.7827 18.1818C93.7827 13.1836 89.7263 9.13184 84.7224 9.13184C79.7185 9.13184 75.6621 13.1836 75.6621 18.1818C75.6621 23.1799 79.7185 27.2317 84.7224 27.2317Z"
                fill="#CED4DA"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M115.399 82.1477L115.371 68.3332C115.371 67.1558 116.317 66.1974 117.496 66.1974L152.64 66.1289C153.819 66.1289 154.779 67.0736 154.779 68.2511L154.806 82.0656C154.806 83.243 153.86 84.2014 152.681 84.2014L117.537 84.2699C116.358 84.2699 115.399 83.3252 115.399 82.1477Z"
                fill="#CED4DA"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M151.832 66.7725L118.263 66.8409C117.18 66.8409 116.303 67.7433 116.303 68.8535L116.331 81.3272C116.331 82.4255 117.21 83.3252 118.291 83.3252L151.86 83.2567C152.936 83.2741 153.805 82.3735 153.805 81.2578V78.2184L153.805 78.1942C153.792 77.9786 153.613 77.8076 153.394 77.8076L152.777 77.8075L152.764 72.2216H153.381C153.608 72.2216 153.792 72.0377 153.792 71.8108V68.7714C153.792 67.6722 152.912 66.7725 151.832 66.7725ZM151.832 67.5938L151.866 67.5943C152.472 67.613 152.969 68.1331 152.969 68.7712V71.3999H152.736C152.288 71.3999 151.941 71.7634 151.941 72.2077L151.955 77.8221L151.956 77.852C151.971 78.2791 152.323 78.6289 152.75 78.6289L152.983 78.6288V81.2576C152.983 81.9117 152.498 82.426 151.899 82.4351L118.29 82.5035C117.669 82.5035 117.153 81.976 117.153 81.3261L117.126 68.8525C117.126 68.1913 117.64 67.6622 118.264 67.6622L151.832 67.5938Z"
                fill="white"
            />
        </svg>
    );
};

export { SolidIcon };
