import { PROFILEBUILDER_API_URL } from "../constants";

import { axiosApi } from "../../../../common-ui-modules/service/redux-helper/middleware/axios-api";
import { IS_PUBLIC } from "../../common/constants";

export const setVerifySupportValue = (data, path) => {
    const headers = {
        "Content-Type": "application/json",
    };

    return axiosApi({
        // url: PROFILEBUILDER_API_URL.VALIDATE_SUPPORT_VAL_Fail,
        //url: PROFILEBUILDER_API_URL.VALIDATE_SUPPORT_VAL_Success,
        url: IS_PUBLIC
            ? PROFILEBUILDER_API_URL.VALIDATE_SUPPORT_VAL_WEB
            : PROFILEBUILDER_API_URL.VALIDATE_SUPPORT_VAL,
        method: "POST",
        data: JSON.stringify(data),
        headers,
    }).then((response) => {
        return response;
    });
};
