function SearchCrossIcon() {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M11.6484 9.68862L15.3945 5.94247C15.8256 5.51144 15.8256 4.8126 15.3945 4.38157C14.9635 3.95054 14.2647 3.95054 13.8336 4.38157L10.0875 8.12772L6.34134 4.38157C5.91032 3.95054 5.21148 3.95054 4.78045 4.38157C4.34942 4.8126 4.34942 5.51144 4.78045 5.94247L8.5266 9.68862L4.78045 13.4348C4.34942 13.8658 4.34942 14.5646 4.78045 14.9957C5.21148 15.4267 5.91032 15.4267 6.34134 14.9957L10.0875 11.2495L13.8336 14.9957C14.2647 15.4267 14.9635 15.4267 15.3945 14.9957C15.8256 14.5646 15.8256 13.8658 15.3945 13.4348L11.6484 9.68862ZM10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20Z"
                fill="#ADB5BD"
            />
        </svg>
    );
}

export { SearchCrossIcon };
