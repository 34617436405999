export const BASEPATH = process.env.NODE_ENV === "production" ? "" : "http://localhost:5000";

export const ALERT_CLOSING_TIME_IN_MS = 2500;
export const navBarOptions = () => {
    return [
        {
            name: "Company Management",
            to: "/company-management",
            active: false,
            isVisible: true,
            pageName: "companyManagement",
        },
        {
            name: "Requests",
            to: "/requests",
            active: false,
            isVisible: true,
            pageName: "requests",
        },
        {
            name: "Approvals",
            to: "/approvals",
            active: false,
            isVisible: true,
            pageName: "approvals",
        },
        {
            name: "User Group Management",
            to: "/user-management",
            active: false,
            isVisible: true,
            pageName: "userManagement",
        },
    ];
};
export const DELTAS = [{ label: 25 }, { label: 50 }, { label: 100 }, { label: 300 }];
export const DEFAULT_DELTA = 25;
export const MAX_DELTA = 300;
export const BROADCOM_DOT_COM_DOMAIN = "broadcom.com";
export const BROADCOM_DOT_NET_DOMAIN = "broadcom.net";
export const REGISTRATION_TAB = "Registration";
export const FP_TAB = "Forgot Password";
export const CM_TAB = "Company Management";
export const UM_TAB = "User Management";
export const PROFILE_TAB = "My Profile";
export const BUILD_PROFILE_TAB = "Registration - Build Profile";
export const BASIC_UM_TAB = "Basic User Management";
export const VMWARE_DOT_COM = "vmware.com";
