import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Slider from "react-slick";

import { useAppDispatch } from "../../../utils/constants/hook";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
    fetchDisplayBanner,
    getExtBanner,
    loadExternalBanner,
} from "../../../store/ExternalBanner/slice";

const ExternalBanner = () => {
    const dispatch = useAppDispatch();
    const location = useLocation();
    const [bannerTiles, setBannerTiles] = useState([]);

    const sliderConfig = {
        dots: false,
        pauseOnFocus: true,
        pauseOnHover: true,
        infinite: true,
        autoplay: true,
        speed: 500,
        autoplaySpeed: 5000,
        slidesToShow: 1,
        slidesToScroll: 1,
        fade: true,
    };

    useEffect(() => {
        dispatch(loadExternalBanner(location.pathname));
    }, []);

    useEffect(() => {
        // execute on location change
        dispatch(fetchDisplayBanner(location.pathname));
    }, [location]);

    const tiles = useSelector(getExtBanner);
    useEffect(() => {
        setBannerTiles(tiles);
    }, [tiles]);

    return (
        <>
            { bannerTiles && bannerTiles.length 
                ?   <div className="mx-5 mt-2 brdcm-fade-in alert alert-danger px-5">
                        <Slider {...sliderConfig}>
                        
                            {bannerTiles.map( (tile: any) => (
                                <div key={tile.title} className="px-4">
                                    <h3>{tile.title}</h3>
                                    <div dangerouslySetInnerHTML={{__html: tile.description}} />
                                    
                                </div>
                            ))}
                        </Slider>
                    </div>
                : null
            }
        </>
    );
};

export { ExternalBanner };
