const CrossIconCircle = function () {
    return (
        <svg
            id="changeColor"
            fill="#DC7633"
            xmlns="http://www.w3.org/2000/svg"
            width="70"
            zoomAndPan="magnify"
            viewBox="0 0 375 374.9999"
            height="70"
            version="1.0"
        >
            <defs>
                <path
                    id="pathAttribute"
                    d="M 11.972656 11.972656 L 359.222656 11.972656 L 359.222656 359.222656 L 11.972656 359.222656 Z M 11.972656 11.972656 "
                    strokeWidth="1"
                    stroke="#727272"
                    fill="#ff0000"
                ></path>
            </defs>
            <g>
                <path
                    id="pathAttribute"
                    d="M 185.597656 359.222656 C 89.675781 359.222656 11.972656 280.984375 11.972656 185.597656 C 11.972656 90.210938 89.675781 11.972656 185.597656 11.972656 C 281.519531 11.972656 359.222656 89.675781 359.222656 185.597656 C 359.222656 281.519531 280.984375 359.222656 185.597656 359.222656 Z M 185.597656 22.691406 C 95.570312 22.691406 22.691406 95.570312 22.691406 185.597656 C 22.691406 275.625 95.570312 348.503906 185.597656 348.503906 C 275.625 348.503906 348.503906 275.625 348.503906 185.597656 C 348.503906 95.570312 275.089844 22.691406 185.597656 22.691406 Z M 185.597656 22.691406 "
                    fillOpacity="1"
                    fillRule="nonzero"
                    strokeWidth="5"
                    stroke="#ff0000"
                    fill="#ff0000"
                ></path>
            </g>
            <g id="inner-icon" transform="translate(85, 75)">
                {" "}
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="205"
                    height="205"
                    fill="currentColor"
                    className="bi bi-x"
                    viewBox="0 0 16 16"
                    id="IconChangeColor"
                >
                    {" "}
                    <path
                        d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
                        id="mainIconPathAttribute"
                        strokeWidth="1"
                        stroke="#ff0000"
                        fill="#ff0000"
                    ></path>{" "}
                </svg>{" "}
            </g>
        </svg>
    );
};

export { CrossIconCircle };
