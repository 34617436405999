function formatUserInfoVO(obj) {
    const userProfile = {};
    const userInfo = obj?.userVO || obj?.data?.userVO || "";
    const companyInfo = obj?.companyVO || obj?.data?.companyVO || "";
    if (userInfo) {
        userProfile.firstName = userInfo.firstName ? userInfo.firstName : "";
        userProfile.lastName = userInfo.lastName ? userInfo.lastName : "";
        userProfile.email = userInfo.email ? userInfo.email : "";
        userProfile.jobTitle = userInfo.jobTitle ? userInfo.jobTitle : "Other";
        userProfile.phone = userInfo.phone ? userInfo.phone : "";
        // userProfile.timezone = userInfo.timezone ? userInfo.timezone : "";
        userProfile.addressLine1 = userInfo.addressLine1 ? userInfo.addressLine1 : "";
        userProfile.addressLine2 = userInfo.addressLine2 ? userInfo.addressLine2 : "";
        userProfile.city = userInfo.city ? userInfo.city : "";
        userProfile.stateName = userInfo.stateName ? userInfo.stateName : "";
        userProfile.postalCode = userInfo.postalCode ? userInfo.postalCode : "";
        userProfile.isEnterprise = userInfo.gtmScreened === "Y" ? true : false;
        userProfile.country = userInfo.country ? userInfo.country.countryId : "";
        userProfile.customerId = userInfo.id ? userInfo.id : "";
        userProfile.supportId = userInfo.supportId ? userInfo.supportId : [1];
        userProfile.sourceId = userInfo.sourceId ? userInfo.sourceId : [];
        userProfile.registrationId = userInfo.registrationId ? userInfo.registrationId : null;
        userProfile.companyId = userInfo.company ? userInfo.company.companyId : null;
        userProfile.companyName = userInfo.company ? userInfo.company.companyName : null;
        userProfile.otherCompanyName = userInfo.otherCompanyName ? userInfo.otherCompanyName : null;
        userProfile.siteId = userInfo.siteId ? userInfo.siteId : null;
        userProfile.billToCustomerId = userInfo.billToCustomerId ? userInfo.billToCustomerId : null;
        userProfile.serialNumber = userInfo.serialNumber ? userInfo.serialNumber : null;
        userProfile.isBasic = userInfo.isBasic ? userInfo.isBasic : false;
        userProfile.isInternal = userInfo.isInternal ? userInfo.isInternal : false;
        userProfile.isAdminUser = userInfo.isAdminUser ? userInfo.isAdminUser : false;
        userProfile.isBSNAssistUser = userInfo.isBSNAssistUser ? userInfo.isBSNAssistUser : false;

        if (userInfo.updateDate) {
            const date = new Date(userInfo.updateDate);
            const day = date.getDate();
            const month = date.toLocaleString("default", { month: "long" });
            const year = date.getFullYear();
            const time = date.toLocaleString("en-US", {
                hour: "numeric",
                minute: "numeric",
                hour12: true,
            });
            userProfile.stringDate = `${day} ${month} ${year}, ${time}`;
        }
        const companyVO = {};
        const list = [];
        const companyId = userProfile.companyId;

        if (companyInfo) {
            companyInfo.map((item) => {
                list.push({ label: item.companyName, value: item.companyId });
            });
        }

        if (companyId === 5000000 && userInfo.otherCompanyName) {
            companyVO.isDisabled = true;
            companyVO.fieldType = "text";
        } else if (companyId === 5000000) {
            //if company list contains, company other than "other", show drop down
            if (list.length && list[0].value !== 5000000) {
                companyVO.fieldType = "select";
            } else {
                if (userProfile.companyName === "Other") {
                    userProfile.companyName = "";
                }
                companyVO.fieldType = "text";
            }
            companyVO.isDisabled = false;
            companyVO.companyId = companyId;
        } else if (companyId === "") {
            if (companyInfo) {
                companyVO.isDisabled = false;
                companyVO.fieldType = "select";
                companyVO.companyId = companyId;
            } else {
                companyVO.isDisabled = false;
                companyVO.fieldType = "text";
                companyVO.companyId = companyId;
            }
        } else {
            companyVO.isDisabled = true;
            companyVO.fieldType = "select";
            companyVO.companyId = companyId;
        }
        companyVO.options = list;
        userProfile.company = userInfo.company ? companyVO : {};
    }
    return userProfile;
}

export default formatUserInfoVO;
