import React from "react";
import ClayTable from "@clayui/table";
import { TableFilterCheckbox } from "../../table-filter/TableFilterCheckBox";

const TableBodyRow = (props: any) => {
    const { definitions, rowData, index, currentPage, handleCheckboxChange } = props;

    return (
        <ClayTable.Row className="esp-table-row">
            {definitions.map((def: any, cellIndex: number) => (
                <ClayTable.Cell
                    key={index + def.field}
                    style={{
                        minWidth: def.width || "auto",
                        maxWidth: def.width || "auto",
                    }}
                    className={"esp-table-cell esp-table-" + def.field}
                >
                    {def.cellRenderer ? (
                        React.cloneElement(def.cellRenderer, {
                            value: rowData[def.field],
                            rowData,
                        })
                    ) : def.filter?.show && def.filter?.type === "checkbox" ? (
                        <TableFilterCheckbox
                            value={rowData[def.field]}
                            rowData={rowData}
                            rowIndex={index}
                            currentPage={currentPage}
                            handleCheckboxChange={handleCheckboxChange}
                        />
                    ) : (
                        <span className={"pr-2"}>{rowData[def.field]}</span>
                    )}
                </ClayTable.Cell>
            ))}
        </ClayTable.Row>
    );
};

export { TableBodyRow };
