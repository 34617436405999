const UserDetailsLicenseIcon = function () {
    return (
        <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle opacity="0.5" cx="20" cy="20" r="20" fill="#abd7f1" />
            <path
                d="M8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15Z"
                stroke="#005C8A"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                style={{ transform: "translate(11.5px, 8px)" }}
            />
            <path
                d="M4.21 13.8899L3 22.9999L8 19.9999L13 22.9999L11.79 13.8799"
                stroke="#005C8A"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                style={{ transform: "translate(11.5px, 8px)" }}
            />
        </svg>
    );
};

export { UserDetailsLicenseIcon };
