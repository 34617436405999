const FilterIcon = function () {
    return (
        <svg
            width="20"
            height="18"
            viewBox="0 0 20 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M6.99995 14.86L8.99993 15.86V9.27C8.99883 9.00766 8.89467 8.75626 8.70993 8.57L2.22998 2H17.7899L11.3199 8.57C11.1352 8.75626 11.031 9.00766 11.0299 9.27L10.9999 17L12.9999 18V9.68L19.5798 3C19.8431 2.73296 19.9934 2.3749 19.9998 2V1C19.9998 0.447715 19.5521 0 18.9999 0H0.999992C0.447712 0 0 0.447715 0 1V2C0.00639745 2.3749 0.156784 2.73296 0.419997 3L6.99995 9.68V14.86Z"
                fill="#6A7178"
            />
        </svg>
    );
};

export { FilterIcon };
