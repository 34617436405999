const SCIcon = function () {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            id="Icon_set"
            data-name="Icon set"
            viewBox="0 0 100 100"
            height="25"
            width="25"
        >
            <path d="M88.36,0H83.54V3.09a3.09,3.09,0,1,1-6.18,0V0H22.64V3.09a3.09,3.09,0,0,1-6.18,0V0H11.64A11.65,11.65,0,0,0,0,11.64V88.36A11.65,11.65,0,0,0,11.64,100h4.82V96.91a3.08,3.08,0,0,1,5.27-2.18,3.06,3.06,0,0,1,.91,2.18V100H77.36V96.91a3.08,3.08,0,0,1,5.27-2.18,3.06,3.06,0,0,1,.91,2.18V100h4.82A11.65,11.65,0,0,0,100,88.36V11.64A11.65,11.65,0,0,0,88.36,0Zm8.43,88.36a8.44,8.44,0,0,1-8.43,8.43H86.75a6.3,6.3,0,0,0-6.3-6.18,6.31,6.31,0,0,0-6.3,6.18H25.85a6.3,6.3,0,0,0-12.6,0H11.64a8.44,8.44,0,0,1-8.43-8.43V11.64a8.44,8.44,0,0,1,8.43-8.43h1.61a6.3,6.3,0,0,0,12.6,0h48.3a6.3,6.3,0,0,0,12.6,0h1.61a8.44,8.44,0,0,1,8.43,8.43Z" />
            <path d="M83.64,31.82a6.37,6.37,0,0,0-6.36-6.36H74.54V22.65a6.38,6.38,0,0,0-6.36-6.36H65.36V9H62.15v7.31h-6V9H53v7.31H47V9H43.81v7.31h-6V9H34.64v7.31H31.83a6.37,6.37,0,0,0-6.36,6.36v2.81H22.72a6.37,6.37,0,0,0-6.36,6.36v2.82H9v3.21h7.32v6H9V47h7.32v6H9v3.21h7.32v6H9v3.21h7.32v2.82a6.37,6.37,0,0,0,6.36,6.36h2.75v2.81a6.37,6.37,0,0,0,6.36,6.36h2.81V91h3.21V83.71h6V91H47V83.71h6V91h3.21V83.71h6V91h3.21V83.71h2.82a6.38,6.38,0,0,0,6.36-6.36V74.54h2.74a6.37,6.37,0,0,0,6.36-6.36V65.36H91V62.15H83.64v-6H91V53H83.64V47H91V43.81H83.64v-6H91V34.64H83.64ZM71.26,71.26H28.74V28.74H71.26ZM28.68,22.65a3.16,3.16,0,0,1,3.15-3.15H68.18a3.15,3.15,0,0,1,3.14,3.15v2.81H28.68Zm-6,48.67a3.15,3.15,0,0,1-3.15-3.14V31.82a3.15,3.15,0,0,1,3.15-3.14h2.81V71.32H22.72Zm48.6,6a3.15,3.15,0,0,1-3.14,3.15H31.83a3.16,3.16,0,0,1-3.15-3.15V74.54H71.32Zm9.11-9.17a3.15,3.15,0,0,1-3.15,3.14H74.47V28.68h2.81a3.15,3.15,0,0,1,3.15,3.14Z" />
        </svg>
    );
};

export { SCIcon };
