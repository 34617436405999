const ArrowRightIcon = function (props: any) {
    let color = "";
    switch (props.color) {
        case "blue":
            color = "#005C8A";
            break;
        case "grey":
            color = "#ADB5BD";
            break;
        case "black":
            color = "#101213";
            break;
        default:
            color = "#005C8A";
            break;
    }

    return (
        <svg
            width="8"
            height="12"
            viewBox="0 0 8 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M0.589996 10.59L5.17 6L0.589996 1.41L2 0L8 6L2 12L0.589996 10.59Z"
                fill={color}
            />
        </svg>
    );
};

export { ArrowRightIcon };
