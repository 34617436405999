import { createSlice } from "@reduxjs/toolkit";
import { URL } from "./constants";
import { axiosApi } from "../../common-ui-modules/service/redux-helper/middleware/axios-api";

const defaultState = {
    bannerPageMap: {},
    displayBanner: [],
};

const slice = createSlice({
    name: "externalBanner",
    initialState: {
        ...defaultState,
    },
    reducers: {
        bannerDataReceived: (externalBanner, action) => {
            externalBanner.bannerPageMap = action.payload;
        },
        setDisplayBanner: (externalBanner, action) => {
            externalBanner.displayBanner = action.payload;
        },
    },
});

export const { bannerDataReceived, setDisplayBanner } = slice.actions;

//api

export const loadExternalBanner = (path: string) => (dispatch: any, state: any) => {
    return axiosApi({
        url: URL.BANNER_FETCH_API,
        method: "GET",
        hideLoader: true,
        skipErrorHandling: true,
    }).then((response: any) => {
        if (response.data.success) {
            const bannerArray = response.data.data && response.data.data.result;
            const bannerMap: { [key: string]: any } = {};
            bannerArray &&
                bannerArray.forEach((bannerTile: any) => {
                    const page = bannerTile.page;
                    const pageArray: string[] = page.split(",");
                    pageArray.forEach((pagePath: string) => {
                        pagePath = pagePath.trim();
                        if (!bannerMap[pagePath]) {
                            bannerMap[pagePath] = [];
                        }

                        bannerMap[pagePath].push(bannerTile);
                    });
                });
            dispatch(bannerDataReceived(bannerMap));
            dispatch(fetchDisplayBanner(path));
        }
    });
};

export const fetchDisplayBanner = (path: string) => (dispatch: any, state: any) => {
    let bannerTiles: any[] = [];
    if (path) {
        const bannerMap = state().externalBanner.bannerPageMap;

        if (bannerMap[path]) {
            bannerTiles = bannerMap[path];
        }

        if (bannerMap[""]) {
            bannerTiles = [...bannerTiles, ...bannerMap[""]];
        }
    }

    dispatch(setDisplayBanner(bannerTiles));
};

export const getExtBanner = (state: any) => state.externalBanner.displayBanner;
// reducer
export const externalBannerReducer = slice.reducer;
