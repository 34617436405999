import { createSlice } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";

const defaultState = {
    toastMessages: [] as any,
    accessDeniedConfig: {
        show: false,
        clossable: true,
    },
    reLoginModal: {
        show: false,
        clossable: false,
    },
};

const slice = createSlice({
    name: "globalAlertHandler",
    initialState: { ...defaultState },
    reducers: {
        addToastAlert: (globalAlertHandler, action) => {
            globalAlertHandler.toastMessages.push(action.payload);
        },
        removeToastAlert: (globalAlertHandler, action) => {
            globalAlertHandler.toastMessages = globalAlertHandler.toastMessages.filter(
                (value: any) => value.id !== action.payload,
            );
        },
        addAccessDeniedModal: (globalAlertHandler, action) => {
            globalAlertHandler.accessDeniedConfig = action.payload;
        },
        addReLoginModal: (globalAlertHandler, action) => {
            globalAlertHandler.reLoginModal = action.payload;
        },
        removeAccessDeniedModal: (globalAlertHandler, action) => {
            globalAlertHandler.accessDeniedConfig = {
                show: action.payload,
                clossable: true,
            };
        },
        resetGlobalAlertsAndModals: (state) => {
            return defaultState;
        },
    },
});

export const {
    addToastAlert,
    removeToastAlert,
    addAccessDeniedModal,
    removeAccessDeniedModal,
    addReLoginModal,
    resetGlobalAlertsAndModals,
} = slice.actions;

export const showAlert =
    ({
        display = "toast",
        type = "danger",
        position = "top-right",
        timeout = "5000",
        message = "",
        additionalInfo = "",
        clossable = true,
    }) =>
    (dispatch: any) => {
        if (message) {
            const id = uuidv4();
            if (!type || type === "failure") {
                type = "danger";
            }

            if (display === "toast") {
                dispatch(
                    addToastAlert({
                        type,
                        position,
                        timeout,
                        message,
                        additionalInfo,
                        id,
                    }),
                );
            }
        }

        if (display === "accessDenied") {
            dispatch(
                addAccessDeniedModal({
                    show: true,
                    clossable,
                }),
            );
        }

        if (display === "toast-warning") {
            const id = uuidv4();
            dispatch(
                addToastAlert({
                    type,
                    position,
                    message,
                    additionalInfo,
                    id,
                }),
            );
        }

        // SESSION EXPIRED MODAL. NON CLOSEABLE.
        if (display === "unauthenticated") {
            //Removing all other global alerts and modals when session timeout modal is shown.
            dispatch(resetGlobalAlertsAndModals());
            dispatch(
                addReLoginModal({
                    show: true,
                    clossable,
                }),
            );
        }
    };
export const getToastMessages = (state: any) => state.globalAlertHandler.toastMessages;
export const getAccessDeniedConfig = (state: any) => state.globalAlertHandler.accessDeniedConfig;
export const getReLoginModal = (state: any) => state.globalAlertHandler.reLoginModal;

export const globalAlertHandlerReducer = slice.reducer;
