import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../../../common-ui-modules/service/redux-helper/api-action-creator";
import { showAlert } from "../../../common-ui-modules/service/redux-helper/store/slice/globalAlertHandler.slice";
import { api } from "../../../common-ui-modules";

import { MY_PROFILE_API_URL } from "./constants";
import { UpdateUserInfo } from "./service/UpdateUserInfoService";
import formatUserInfoVO from "./businessLogic/formatUserInfoVO";

const slice = createSlice({
    name: "userInformation",
    initialState: {
        allMasterVO: "",
        stateVO: [{ label: "Select State", value: "" }],
        userInfoVO: "",
        jobTitleArr: [{ label: "Select Job Title", value: "" }],
        saveStatus: "",
        stateVOAPIInProgress: false,
    },
    reducers: {
        allMasterVOReceived: (userInformation, action) => {
            userInformation.allMasterVO = action.payload.data.result;
        },
        stateVOReceived: (userInformation, action) => {
            userInformation.stateVO = action.payload;
            userInformation.stateVOAPIInProgress = false;
        },
        stateVOAPICallStatus: (userInformation) => {
            userInformation.stateVOAPIInProgress = !userInformation.stateVOAPIInProgress;
        },
        userInfoVOReceived: (userInformation, action) => {
            const userInfoList = formatUserInfoVO(action.payload ? action.payload : {});
            userInformation.userInfoVO = userInfoList;
        },
        jobTitleArrReceived: (userInformation, action) => {
            userInformation.jobTitleArr = action.payload;
        },
        updateSaveReceive: (userInformation, action) => {
            userInformation.saveStatus = action.payload;
        },
    },
});

export const {
    allMasterVOReceived,
    stateVOReceived,
    userInfoVOReceived,
    jobTitleArrReceived,
    updateSaveReceive,
    stateVOAPICallStatus,
} = slice.actions;

//custom actions
export const loadAllMaster = () => (dispatch) => {
    return dispatch(
        apiCallBegan({
            method: "GET",
            url: MY_PROFILE_API_URL.ALL_MASTER,
            onSuccess: allMasterVOReceived.type,
            skipErrorHandling: true,
        }),
    );
};

//--q what is getState
export const fetchStates = (countryId) => (dispatch, getState) => {
    const apiInProgress = getState().userInformation.stateVOAPIInProgress;
    if (!apiInProgress && countryId !== "Select Country") {
        return dispatch(
            apiCallBegan({
                url: MY_PROFILE_API_URL.FETCH_STATES + countryId,
                onStart: stateVOAPICallStatus.type,
                onSuccess: stateVOReceived.type,
                onError: stateVOAPICallStatus.type,
                method: "GET",
                skipErrorHandling: true,
            }),
        );
    }
};

export const getUserInfo = (email) => (dispatch: any) => {
    return dispatch(
        apiCallBegan({
            url: MY_PROFILE_API_URL.FETCH_USER_INFO,
            onSuccess: userInfoVOReceived.type,
            method: "GET",
            //skipErrorHandling: true
        }),
    );
};

export const getjobTitleArr = () => (dispatch) => {
    api({
        method: "GET",
        url: MY_PROFILE_API_URL.FETCH_JOB_TITLES,
        skipErrorHandling: true,
    }).then((res) => {
        if (
            res.data.success &&
            res.data.data &&
            res.data.data.jobtitles &&
            res.data.data.jobtitles.length
        ) {
            const resData = res.data.data.jobtitles;
            const list = [];
            list.push({ label: "Select Job Title", value: "" });
            resData.map((item) => {
                list.push({ label: item, value: item });
            });
            dispatch(jobTitleArrReceived(list));
        }
    });
};

export const updateUserData = (formValues) => (dispatch) => {
    UpdateUserInfo(formValues)
        .then((data) => {
            if (data.data.includes("successfully")) {
                dispatch(updateSaveReceive("success"));
                dispatch(
                    showAlert({
                        type: "success",
                        message: "Updated Successfully",
                    }),
                );
            } else {
                dispatch(updateSaveReceive("error"));
            }
        })
        .catch((error) => {
            dispatch(updateSaveReceive("error"));
        });
};

//selectors
export const getAllMasterVO = (state) => state.userInformation.allMasterVO;

export const statesList = (state) => {
    const list = [];
    list.push({ label: "Select State", value: "" });

    if (state.userInformation.stateVO) {
        if (
            state.userInformation.stateVO.success &&
            state.userInformation.stateVO.data &&
            state.userInformation.stateVO.data.result.length
        ) {
            state.userInformation.stateVO.data.result.map((item) => {
                list.push({ label: item, value: item });
            });
        }
    }
    return list;
};
//phoneNum?
export const userInfoObject = (state) => {
    return state.userInformation.userInfoVO;
};

export const userInfoVOPrevious = (state) => {
    return state.userInformation.userInfoVOPrevious;
};

export const jobTitlesList = (state) => {
    const list = [];
    list.push({ label: "Select a Job Title", value: "" });

    if (
        state.userInformation.jobTitleArr.payload &&
        state.userInformation.jobTitleArr.payload.length
    ) {
        state.userInformation.jobTitleArr.payload[0].jobtitles.map((item) => {
            list.push({ label: item, value: item });
        });
    }
    return list;
};
export const getJobTitlesList = (state) => {
    return state.userInformation.jobTitleArr;
};
export const getSaveStatus = (state) => {
    return state.userInformation.saveStatus;
};

// reducer
export const userInformationReducer = slice.reducer;
