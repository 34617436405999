import CookieConsent from "../Cookie/CookieConsent";

function Footer() {
    return (
        <footer className="brdcm-standard-footer">
            <div className="brdcm-container brdcm-standard-container">
                <div className="brdcm-footer-segment-one">
                    <nav className="brdcm-footer-primary-nav">
                        <ul className="brdcm-footer-primary-nav-links">
                            <li className="brdcm-footer-primary-nav-item">
                                <a
                                    className="brdcm-footer-primary-nav-link"
                                    href="https://www.broadcom.com/products"
                                >
                                    Products
                                </a>
                            </li>
                            <li className="brdcm-footer-primary-nav-item">
                                <a
                                    className="brdcm-footer-primary-nav-link"
                                    href="https://www.broadcom.com/solutions"
                                >
                                    Solutions
                                </a>
                            </li>
                            <li className="brdcm-footer-primary-nav-item">
                                <a
                                    className="brdcm-footer-primary-nav-link"
                                    href="https://www.broadcom.com/support"
                                >
                                    Support and Services
                                </a>
                            </li>
                            <li className="brdcm-footer-primary-nav-item">
                                <a
                                    className="brdcm-footer-primary-nav-link"
                                    href="https://www.broadcom.com/"
                                >
                                    Company
                                </a>
                            </li>
                            <li className="brdcm-footer-primary-nav-item">
                                <a
                                    className="brdcm-footer-primary-nav-link"
                                    href="https://www.broadcom.com/how-to-buy"
                                >
                                    How to Buy
                                </a>
                            </li>
                        </ul>
                    </nav>
                    <div className="brdcm-footer-copyright">
                        {`
                        Copyright © 2005-${new Date().getFullYear()} Broadcom. All Rights Reserved. The term “Broadcom”
                        refers to Broadcom Inc. and/or its subsidiaries.`}
                    </div>
                    <nav className="brdcm-footer-secondary-nav">
                        <ul className="brdcm-footer-secondary-nav-links">
                            <li className="brdcm-footer-secondary-nav-item">
                                <a
                                    className="brdcm-footer-secondary-nav-link"
                                    href="https://www.broadcom.com/company/legal/accessibility"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    Accessibility
                                </a>
                            </li>
                            <li className="brdcm-footer-secondary-nav-item">
                                <a
                                    className="brdcm-footer-secondary-nav-link"
                                    href="https://www.broadcom.com/company/legal/privacy"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    Privacy
                                </a>
                            </li>
                            <li className="brdcm-footer-secondary-nav-item">
                                <a
                                    className="brdcm-footer-secondary-nav-link"
                                    href="https://www.broadcom.com/company/citizenship/supplier-responsibility"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    Supplier Responsibility
                                </a>
                            </li>
                            <li className="brdcm-footer-secondary-nav-item">
                                <a
                                    className="brdcm-footer-secondary-nav-link"
                                    href="https://www.broadcom.com/company/legal/terms-of-use"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    Terms of Use
                                </a>
                            </li>
                            <li className="brdcm-footer-secondary-nav-item">
                                <a
                                    className="brdcm-footer-secondary-nav-link"
                                    href="https://www.broadcom.com/sitemap"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    Site Map
                                </a>
                            </li>
                        </ul>
                    </nav>
                </div>
                <div className="brdcm-footer-segment-two">
                    <ul className="brdcm-footer-social-nav-links">
                        <li className="brdcm-footer-social-nav-item">
                            <a
                                target="_blank"
                                rel="noreferrer"
                                href="https://www.linkedin.com/company/broadcom"
                            >
                                <span>
                                    <svg
                                        aria-hidden="true"
                                        data-prefix="fab"
                                        data-icon="linkedin"
                                        role="img"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 448 512"
                                        data-fa-i2svg=""
                                    >
                                        <path
                                            fill="currentColor"
                                            d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 
                        416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 
                        243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z"
                                        ></path>
                                    </svg>
                                </span>
                            </a>
                        </li>
                        <li className="brdcm-footer-social-nav-item">
                            <a target="_blank" rel="noreferrer" href="https://twitter.com/Broadcom">
                                <span>
                                    <svg
                                        aria-hidden="true"
                                        data-prefix="fab"
                                        data-icon="twitter"
                                        role="img"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 512 512"
                                        data-fa-i2svg=""
                                    >
                                        <path
                                            fill="currentColor"
                                            d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 
                        25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 
                        27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 
                        5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 
                        30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 
                        60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"
                                        ></path>
                                    </svg>
                                </span>
                            </a>
                        </li>
                        <li className="brdcm-footer-social-nav-item">
                            <a
                                target="_blank"
                                rel="noreferrer"
                                href="https://www.youtube.com/user/BroadcomCorporation"
                            >
                                <span>
                                    <svg
                                        aria-hidden="true"
                                        data-prefix="fab"
                                        data-icon="youtube"
                                        role="img"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 576 512"
                                        data-fa-i2svg=""
                                    >
                                        <path
                                            fill="currentColor"
                                            d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 
                        48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 
                        213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 
                        213.508V175.185l142.739 81.205-142.739 81.201z"
                                        ></path>
                                    </svg>
                                </span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <CookieConsent />
        </footer>
    );
}

export { Footer };
