import React, { useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import ClayLayout from "@clayui/layout";
import { Formik } from "formik";
import ClayForm from "@clayui/form";
import ClayButton from "@clayui/button";
import { FormControl } from "../../../common-ui-modules/components";
import { addUpdateDomainList } from "../../../store/CompanyManagement/slice";
import { useDispatch } from "react-redux";
import { showAlert } from "../../../common-ui-modules/service/redux-helper/store/slice/globalAlertHandler.slice";
import { useAppSelector } from "../../../store/hook";
import { getUserDetails } from "../../../store/global/global.slice";
import "../css/styles.scss";
import { DOMAIN_ERROR_TIMEOUT } from "../constants";

const DomainInput = (props: any) => {
    const DomainRef = useRef<any>("");
    const [infoShow, setInfoShow] = useState(false);
    const [infoMsg, setInfoMsg] = useState("");
    const { rowData, companyId } = props;
    const [formData, setFormData] = useState({
        domainName: "",
    });
    const UserDetails = useAppSelector(getUserDetails);

    const dispatch = useDispatch();

    const onSubmit = (data: any, submitProps: any) => {
        const domainName = data.domainName.trim();
        if (domainName.length > 0) {
            const allDomains = rowData.map((item: any) => item.domainName.toLowerCase());
            if (allDomains.indexOf(domainName.toLowerCase()) === -1) {
                props.setUpdateDomain(false);
                dispatch(addUpdateDomainList(companyId, domainName, "Y", UserDetails.email));
                submitProps.resetForm();
            } else {
                dispatch(
                    showAlert({
                        type: "danger",
                        message: "Domain already exists.",
                    }),
                );
            }
        } else {
            handleEmptyDomainInput();
            return;
        }
    };

    const handleEmptyDomainInput = () => {
        setInfoMsg("Domain name is required.");
        setInfoShow(true);
        const timeOut = setTimeout(() => {
            setInfoMsg("");
            setInfoShow(false);
        }, DOMAIN_ERROR_TIMEOUT)
        return () => {
            clearTimeout(timeOut);
        };
    }

    const validationSchema = Yup.object().shape({
        // domainName: Yup.string().required("Domain name is required."),
    });

    useEffect(() => {
        DomainRef.current.resetForm();
    }, [companyId, rowData])

    return (
        <React.Fragment>
            <ClayLayout.Row>
                <ClayLayout.Col size={9}>
                    <Formik
                        initialValues={formData}
                        onSubmit={onSubmit}
                        validationSchema={validationSchema}
                        innerRef={DomainRef}
                        validateOnBlur={false}
                    >
                        {(formik) => (
                            <ClayForm onSubmit={formik.handleSubmit}>
                                <ClayLayout.Row justify="start">
                                    <ClayLayout.Col size={5}>
                                        <FormControl
                                            control="text"
                                            id="domainName"
                                            name="domainName"
                                            size="md"
                                            placeholder="Enter Domain"
                                            value={formik.values.domainName}
                                            infoShow={infoShow}
                                            infoMsg={infoMsg}
                                            onTypeShow={infoMsg === "Domain name is required."}
                                        />
                                    </ClayLayout.Col>
                                    <ClayLayout.Col size={3}>
                                        <ClayButton type="submit" displayType="primary">
                                            Add
                                        </ClayButton>
                                    </ClayLayout.Col>
                                </ClayLayout.Row>
                            </ClayForm>
                        )}
                    </Formik>
                </ClayLayout.Col>
            </ClayLayout.Row>
        </React.Fragment>
    );
};

export default DomainInput;
