import {
    getUserDetails,
    //getUserDetailsError,
    getUserDetailsLoading,
    loadUserDetails,
    setUserDetails,
    updateEnvironmentVariables,
} from "../../store/global/global.slice";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch, useAppSelector } from "../../utils/constants/hook";
import { GlobalAPILoader } from "../../common-ui-modules/components/HOC/global-api-loader/GlobalAPILoader";

// eslint-disable-next-line react/display-name
const AppInitializer: React.FC<any> = React.memo((props: any) => {
    const dispatch = useAppDispatch();
    const loading = useAppSelector(getUserDetailsLoading);
    //const error = useAppSelector(getUserDetailsError);
    const location = window.location.pathname;

    useEffect(() => {
        dispatch(updateEnvironmentVariables());
        if (location.includes("/web") || location === "/") {
            dispatch(setUserDetails({}));
        } else {
            dispatch(loadUserDetails());
        }
    }, []);

    const userDetails = useSelector(getUserDetails);
    return (
        <React.Fragment>
            {userDetails ? (
                props.children
            ) : loading ? (
                <GlobalAPILoader showLoader={true} />
            ) : (
                <div>
                    <div>Proper User Details is not found</div>
                </div>
            )}
        </React.Fragment>
    );
});
export default AppInitializer;
