export const SERVICES_ARRAY = [
    { title: "Product Documentation", status: "approved" },
    { title: "Communities", status: "approved" },
    {
        title: "Public Education",
        status: "approved",
        info: "You will get access to Broadcom public education.  For access to premium education, build your profile with the required information.",
    },
    {
        title: "Public Semiconductors Case Management",
        status: "approved",
        info: "You will receive access to Semiconductors Case Management after you build your profile to unlock additional services.",
    },
    {
        title: "Public Knowledge Base Articles",
        status: "approved",
        info: "Provides access to Broadcom's Public Knowledge Base Articles.",
    },
    { title: "Broadcom Software Support Systems", status: "disabled" },
    { title: "Broadcom Software Case Management", status: "removed" },
    { title: "Broadcom Software Licensing", status: "removed" },
    { title: "Broadcom Software Product Downloads", status: "removed" },
    { title: "Broadcom Software Premium Education", status: "removed" },
    { title: "Brocade Storage Networking Support Systems", status: "disabled" },
    { title: "Brocade Storage Networking Case Management", status: "removed" },
    { title: "Brocade Storage Networking Licensing", status: "removed" },
    { title: "Brocade Storage Networking Product Downloads", status: "removed" },
    { title: "Brocade Storage Networking Premium Education", status: "removed" },
    { title: "Semiconductors Support Systems", status: "disabled" },
    { title: "Semiconductors Case Management", status: "removed" },
    { title: "Semiconductors docSAFE Product Downloads", status: "removed" },
    { title: "Broadcom Partner", status: "disabled" },
    { title: "eStore", status: "disabled" },
    { title: "Premium Education", status: "disabled" },
    { title: "iSupplier", status: "removed" },
    // {"title":"Broadcom SCRT Report Management",
    // "status":"disabled",
    // "info":"Broadcom SCRT Report Management is a web interface for Mainframe Customers to securely submit ISV SCRT reports."
    // }
];

export const ADDRESS_INITIAL_VALUES = {
    company: null,
    companyId: "",
    companyName: "",
    phone: "",
    address: "",
    zipcode: "",
    city: "",
    state: "",
};
export const POSTAL_CODE_REGEX = /^[a-z\d\-\s]+$/i;
export const INITIAL_FORM_DATA = {
    1: {
        siteId: "",
        phone: "",
    },
    2: { serialNumber: "", ...ADDRESS_INITIAL_VALUES },
    3: ADDRESS_INITIAL_VALUES,
    4: { customerId: "", ...ADDRESS_INITIAL_VALUES },
    5: { customerId: "", ...ADDRESS_INITIAL_VALUES },
};

//indexes

// export const PRODUCT_DOWNLOADS_IDX=5
// export const CASE_MANAGEMENT_IDX=6
// export const PREMIUM_LEARNING_IDX=7
// export const PARTNER_PORTAL_IDX=8
// export const LICENSE_KEYS_IDX=9

export const BROADCOM_SOFTWARE_SUPPORT_SYSTEMS_IDX = 5;
export const BROADCOM_SOFTWARE_CASE_MGMT_IDX = 6;
export const BROADCOM_SOFTWARE_LICENSING_IDX = 7;
export const BROADCOM_SOFTWARE_PRODUCT_DOWNLOADS_IDX = 8;
export const BROADCOM_SOFTWARE_PREMIUM_LEARNING_IDX = 9;
export const BROADCOM_STORAGE_NETWORKING_SUPPORT_SYSTEMS_IDX = 10;
export const BROADCOM_STORAGE_NETWORKING_CASE_MGMT_IDX = 11;
export const BROADCOM_STORAGE_NETWORKING_LICENSING_IDX = 12;
export const BROADCOM_STORAGE_NETWORKING_PRODUCT_DOWNLOADS_IDX = 13;
export const BROADCOM_STORAGE_NETWORKING_PREMIUM_LEARNING_IDX = 14;
export const SEMICONDUCTORS_SUPPORT_SYSTEMS_IDX = 15;
export const SEMICONDUCTORS_CASE_MGMT_IDX = 16;
export const SEMICONDUCTORS_PRODUCT_DOWNLOADS_IDX = 17;
export const BROADCOM_PARTNER_IDX = 18;
export const ESTORE_IDX = 19;
export const PREMIUM_EDUCATION_IDX = 20;
export const ISUPPLIER_IDX = 21;
// export const BROADCOM_SCRT_REPORT_MANAGEMENT_IDX=22

export const PROFILE_BUILDER_OPTIONS = [
    {
        title: "Broadcom Software",
        desc: "Enterprise, Mainframe, Payment Security, Symantec, SCRT Report Management and VMware",
        showDesc: true,
        value: "supportSection",
        defaultChecked: false,
        formNumber: 1,
        supportValue: 1,
        data: null,
    },
    {
        title: "Brocade Storage Networking",
        desc: "",
        showDesc: false,
        value: "bsnSection",
        defaultChecked: false,
        formNumber: 2,
        supportValue: 5,
        data: null,
    },
    {
        title: "Broadcom Partner",
        desc: "",
        showDesc: false,
        value: "partnerSection",
        defaultChecked: false,
        formNumber: 3,
        supportValue: 2,
        data: null,
    },
    {
        title: "eStore",
        desc: "",
        showDesc: false,
        value: "estoreSection",
        defaultChecked: false,
        formNumber: 4,
        supportValue: 2,
        data: null,
    },
    {
        title: "Semiconductors",
        desc: "Broadcom portfolio of Standard and Custom silicon for Networking, Storage, Broadband, Wireless and Industrial market segments",
        showDesc: true,
        value: "semiconductorSection",
        defaultChecked: false,
        formNumber: 5,
        supportValue: 3,
        data: null,
    },
];
export const PHONE_NO_REGEX =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
export const ATTEMPTS = [
    " 2 attempts remaining",
    " 1 attempt remaining",
    " ALL ATTEMPTS EXHAUSTED! Please submit the form and your request will be reviewed",
];
export const USER_INFO_STRINGS = [
    "Address Line 1",
    "Address Line 2",
    "City",
    "Phone Number",
    "State",
    "Zip Code",
    "Serial Number",
    "Bill to Customer ID",
    "Broadcom Contact",
    "Site ID",
    "Company Name",
    "Job Title",
    "Country",
    // "Preferred Timezone"
];
export const SECTION_REQUESTED_ARRAY = [
    "APPROVED",
    "SUCCESS",
    "PENDING",
    "approved",
    "success",
    "pending",
];
export const OTHER_COMPANY_ID = 5000000;
export const OTHER_COMPANY = "Other";
export const SOFTWARE_SECTION_IDX = 0;
export const BSN_SECTION_IDX = 1;
export const PARTNER_SECTION_IDX = 2;
export const ESTORE_SECTION_IDX = 3;
export const SEMICONDUCTOR_SECTION_IDX = 4;
export const REGISTRATION_SUCCESS_TITLE = "Registered Successfully!";
export const REGISTRATION_SUCCESS_SUBTITLE =
    "Welcome to Broadcom. Based on the information provided you now have access to the following services";
export const UPGRADE_SUCCESS_TITLE = "Completed";
export const ACCESS_DETAILS_SUBTITLE =
    "Based on the information provided you now have access to the following services";
export const ACCESS_DETAILS_TITLE = "Access Details";
export const UNLOCK_ADDITIONAL_SERVICE_MESSAGE =
    "Unlock additional services by Building your Profile";
export const ISUPPLIER_SUBMIT_MESSAGE =
    "Thanks for submitting your request, you will receive an email once approved";
export const SPECIAL_CHARS = "Special characters are not allowed";
export const MIN_2 = "A minimum of 2 character is required";
export const MAX_40 = "Maximum allowed characters is 40";
export const REGISTRATION_ORIGIN = "ecx_registration";
export const BSN_AUTO_APPRVL_REQD = "BSN_AUTO_APPRVL_REQD";
export const BSN_AUTO_APPROVED = "BSN_AUTO_APPROVED";
export const BSN_APPROVED = "BSN_APPROVED";
export const BSN_REJECTED = "BSN_REJECTED";
export const DEFAULT_STATE = [{ label: "Select State", value: "" }];
