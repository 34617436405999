import { Fragment } from "react";

function PageTitle(props: any) {
    const title = props.title;
    const color = props.color ? props.color : "default";
    const additionalClass = props.additionalClass;
    return (
        <Fragment>
            <p className={`esp-page-title-${color} ${additionalClass} mb-0`}>{title}</p>
        </Fragment>
    );
}

export { PageTitle };
