import { InfoIconBold } from "./infoIconBold";
import { RoundPendingIcon } from "./roundPendingIcon";
import { RoundTickIcon } from "./roundTickIcon";
import { ActiveStatus } from "./activeStatus";
import { AlertTriangleIcon } from "./alertTriangleIcon";
import { ArchiveNotificationIcon } from "./archive_notification";
import { ArrowDownIcon } from "./arrowDownIcon";
import { ArrowRightIcon } from "./arrowRightIcon";
import { ArrowUpIcon } from "./arrowUpIcon";
import { BackArrowIcon } from "./backArrowIcon";
import { BellIcon } from "./bell";
import { BPSIcon } from "./brcmPaymentIcon";
import { BPIcon } from "./brocadeIcon";
import { CaseIcon } from "./case";
import { ClosedMenu } from "./closedMenu";
import { CompatibilityMatrixCardIcon } from "./compatibilityMatrixCard";
import { ContractDetailsIcon } from "./contractDetails";
import { CopyIcon } from "./Copy";
import { CounterCaseIcon } from "./counter_case";
import { CounterDownloadIcon } from "./counter_download";
import { CounterLicenseIcon } from "./counter_license";
import { CrossIcon } from "./cross";
import { DeleteIcon } from "./Delete";
import { DownloadIcon } from "./download";
import { Download2Icon } from "./download2";
import { DropDownSearchIcon } from "./dropDownSearch";
import { EditIcon } from "./Edit";
import { EmailIcon } from "./emailIcon";
import { ExternalLinkIcon } from "./externalLinkIcon";
import { FilterIcon } from "./filter";
import { FileText } from "./filteText";
import { FTPDownloadIcon } from "./ftpDownloadIcon";
import { GearCloudIcon } from "./gearCloudIcon";
import { GearIcon } from "./gearIcon";
import { GIMGTPKGIcon } from "./GIMGTPKGIcon";
import { HelpCircle } from "./HelpCircle";
import { InactiveStatus } from "./inactiveStatus";
import { InfoIcon } from "./info";
import { IFIcon } from "./infrastructureIcon";
import { LicenseIcon } from "./license";
import { LifeBuoyIcon } from "./lifeBuoyIcon";
import { LMSCardIcon } from "./lmsCard";
import { MFIcon } from "./mainframeIcon";
import { MapIcon } from "./mapIcon";
import { MaximizeIcon } from "./maximizeIcon";
import { MediumStatus } from "./mediumStatus";
import { MessageIcon } from "./message";
import { MinimizeIcon } from "./minimizeIcon";
import { NoCasesIcon } from "./NoCasesIcon";
import { NoDataFoundIcon } from "./noDataFound";
import { NoDownloadHistoryIcon } from "./NoDownloadHistoryIcon";
import { NoDownloadsIcon } from "./NoDownloads";
import { NotFoundIcon } from "./notFoundIcon";
import { OpenMenu } from "./openMenu";
import { PageNotFoundGeometric1 } from "./pageNotFoundIcon";
import { PasteIcon } from "./Paste";
import { PermDeniedIcon } from "./permDeniedIcon";
import { PLAIcon } from "./plaIcon";
import { ProfAccessIcon } from "./profileAccess";
import { ProfLogoutIcon } from "./profileLogout";
import { ProfNotificationIcon } from "./profileNotification";
import { ProfUserIcon } from "./profileUser";
import { ReadNotificationIcon } from "./read_notification";
import { RecentActivityDownloadIcon } from "./recentActivityDownloadIcon";
import { RecentActivitySiteAccessIcon } from "./recentActivitySiteAccessIcon";
import { RecentActivitySolutionsIcon } from "./recentActivitySolutionsIcon";
import { ResetIcon } from "./ResetIcon";
import { SearchIcon } from "./search";
import { SearchCrossIcon } from "./searchCrossIcon";
import { SCIcon } from "./semiconductorIcon";
import { SettingsIcon } from "./setting";
import { SolidIcon } from "./solidIcon";
import { SortAscIcon } from "./sortAsc";
import { SortDescIcon } from "./sortDesc";
import { SESIcon } from "./symantecIcon";
import { ThreeDotMenuIcon } from "./three-dot-menu";
import { TickBoldIcon } from "./tickBoldIcon";
import { TickIcon } from "./tickIcon";
import { TokenIcon } from "./TokenIcon";
import { TrashNotificationIcon } from "./trash_notification";
import { TrashIcon } from "./trashIcon";
import { UnsortIcon } from "./unsort";
import { UserDetailsCaseIcon } from "./userDetailsCaseIcon";
import { UserDetailsDownloadIcon } from "./userDetailsDownloadIcon";
import { UserDetailsLicenseIcon } from "./userDetailsLicenseIcon";
import { UserIcon } from "./userIcon";
import { ViewIcon } from "./view";
import { WomanAnnouncement } from "./womanAnnouncement";
import { RoundTick } from "./roundTick";
import { CrossIconCircle } from "./crossIconCircle";

const Icon = (props: any): any => {
    const { type } = props;

    switch (type) {
        case "activeStatus":
            return <ActiveStatus {...props} />;

        case "alertTriangleIcon":
            return <AlertTriangleIcon {...props} />;

        case "archiveNotification":
            return <ArchiveNotificationIcon {...props} />;

        case "arrowDownIcon":
            return <ArrowDownIcon {...props} />;

        case "arrowRightIcon":
            return <ArrowRightIcon {...props} />;

        case "arrowUpIcon":
            return <ArrowUpIcon {...props} />;

        case "backArrowIcon":
            return <BackArrowIcon {...props} />;

        case "bellIcon":
            return <BellIcon {...props} />;

        case "bpsIcon":
            return <BPSIcon {...props} />;

        case "bpIcon":
            return <BPIcon {...props} />;

        case "caseIcon":
            return <CaseIcon {...props} />;

        case "closedMenu":
            return <ClosedMenu {...props} />;

        case "compatibilityMatrixCardIcon":
            return <CompatibilityMatrixCardIcon {...props} />;

        case "contractDetailsIcon":
            return <ContractDetailsIcon {...props} />;

        case "copyIcon":
            return <CopyIcon {...props} />;

        case "counterCaseIcon":
            return <CounterCaseIcon {...props} />;

        case "counterDownloadIcon":
            return <CounterDownloadIcon {...props} />;

        case "counterLicenseIcon":
            return <CounterLicenseIcon {...props} />;

        case "crossIcon":
            return <CrossIcon {...props} />;
        case "crossIconCircle":
            return <CrossIconCircle {...props} />;

        case "deleteIcon":
            return <DeleteIcon {...props} />;

        case "downloadIcon":
            return <DownloadIcon {...props} />;

        case "download2Icon":
            return <Download2Icon {...props} />;

        case "dropDownSearchIcon":
            return <DropDownSearchIcon {...props} />;

        case "editIcon":
            return <EditIcon {...props} />;

        case "emailIcon":
            return <EmailIcon {...props} />;

        case "externalLinkIcon":
            return <ExternalLinkIcon {...props} />;

        case "filterIcon":
            return <FilterIcon {...props} />;

        case "fileText":
            return <FileText {...props} />;

        case "ftpDownloadIcon":
            return <FTPDownloadIcon {...props} />;

        case "gearCloudIcon":
            return <GearCloudIcon {...props} />;

        case "gearIcon":
            return <GearIcon {...props} />;

        case "gimgtpkgIcon":
            return <GIMGTPKGIcon {...props} />;

        case "helpCircle":
            return <HelpCircle {...props} />;

        case "inactiveStatus":
            return <InactiveStatus {...props} />;

        case "infoIcon":
            return <InfoIcon {...props} />;

        case "infoIconBold":
            return <InfoIconBold {...props} />;

        case "ifIcon":
            return <IFIcon {...props} />;

        case "licenseIcon":
            return <LicenseIcon {...props} />;

        case "lifeBuoyIcon":
            return <LifeBuoyIcon {...props} />;

        case "lmsCardIcon":
            return <LMSCardIcon {...props} />;

        case "mfIcon":
            return <MFIcon {...props} />;

        case "mapIcon":
            return <MapIcon {...props} />;

        case "maximizeIcon":
            return <MaximizeIcon {...props} />;

        case "mediumStatus":
            return <MediumStatus {...props} />;

        case "messageIcon":
            return <MessageIcon {...props} />;

        case "minimizeIcon":
            return <MinimizeIcon {...props} />;

        case "noCasesIcon":
            return <NoCasesIcon {...props} />;

        case "noDataFoundIcon":
            return <NoDataFoundIcon {...props} />;

        case "noDownloadHistoryIcon":
            return <NoDownloadHistoryIcon {...props} />;

        case "noDownloadsIcon":
            return <NoDownloadsIcon {...props} />;

        case "notFoundIcon":
            return <NotFoundIcon {...props} />;

        case "openMenu":
            return <OpenMenu {...props} />;

        case "pageNotFoundIcon":
            return <PageNotFoundGeometric1 {...props} />;

        case "pasteIcon":
            return <PasteIcon {...props} />;

        case "permDeniedIcon":
            return <PermDeniedIcon {...props} />;

        case "plaIcon":
            return <PLAIcon {...props} />;

        case "profAccessIcon":
            return <ProfAccessIcon {...props} />;

        case "profLogoutIcon":
            return <ProfLogoutIcon {...props} />;

        case "profNotificationIcon":
            return <ProfNotificationIcon {...props} />;

        case "profUserIcon":
            return <ProfUserIcon {...props} />;

        case "readNotificationIcon":
            return <ReadNotificationIcon {...props} />;

        case "recentActivityDownloadIcon":
            return <RecentActivityDownloadIcon {...props} />;

        case "recentActivitySiteAccessIcon":
            return <RecentActivitySiteAccessIcon {...props} />;

        case "recentActivitySolutionsIcon":
            return <RecentActivitySolutionsIcon {...props} />;

        case "resetIcon":
            return <ResetIcon {...props} />;

        case "roundPendingIcon":
            return <RoundPendingIcon {...props} />;

        case "roundTickIcon":
            return <RoundTickIcon {...props} />;

        case "searchIcon":
            return <SearchIcon {...props} />;

        case "searchCrossIcon":
            return <SearchCrossIcon {...props} />;

        case "scIcon":
            return <SCIcon {...props} />;

        case "settingsIcon":
            return <SettingsIcon {...props} />;

        case "solidIcon":
            return <SolidIcon {...props} />;

        case "sortAscIcon":
            return <SortAscIcon {...props} />;

        case "sortDescIcon":
            return <SortDescIcon {...props} />;

        case "sesIcon":
            return <SESIcon {...props} />;

        case "threeDotMenuIcon":
            return <ThreeDotMenuIcon {...props} />;

        case "tickBoldIcon":
            return <TickBoldIcon {...props} />;

        case "tickIcon":
            return <TickIcon {...props} />;

        case "tokenIcon":
            return <TokenIcon {...props} />;

        case "trashNotificationIcon":
            return <TrashNotificationIcon {...props} />;

        case "trashIcon":
            return <TrashIcon {...props} />;

        case "unsortIcon":
            return <UnsortIcon {...props} />;

        case "userDetailsCaseIcon":
            return <UserDetailsCaseIcon {...props} />;

        case "userDetailsDownloadIcon":
            return <UserDetailsDownloadIcon {...props} />;

        case "userDetailsLicenseIcon":
            return <UserDetailsLicenseIcon {...props} />;

        case "userIcon":
            return <UserIcon {...props} />;

        case "viewIcon":
            return <ViewIcon {...props} />;

        case "womanAnnouncement":
            return <WomanAnnouncement {...props} />;

        case "roundTick":
            return <RoundTick {...props} />;
    }
};

export default Icon;
