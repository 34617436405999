import "@clayui/css/lib/css/atlas.css";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";

import "./index.scss";

import App from "./App";
import reportWebVitals from "./reportWebVitals";

import { store } from "./store/configureStore";
import AppInitializer from "./modules/app-initializer/AppInitializer";
const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
    <Provider store={store}>
        <AppInitializer>
            <Router>
                <App />
            </Router>
        </AppInitializer>
    </Provider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
