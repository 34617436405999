import { BASEPATH } from "../../utils/constants/constants";

const API_URL = {
    FETCH_COMPANY: `${BASEPATH}/api/ms/registration/masterService/getCompanyNames`,
    FETCH_COMPANY_BASIC: `${BASEPATH}/api/ms/registration/masterService/getBasicUserCompanyNames`,
    USER_SEARCH: `${BASEPATH}/api/web/registration/userProfile/searchUser`,
    BASIC_USER_SEARCH: `${BASEPATH}/api/web/registration/userProfile/searchBasicUser`,
    FETCH_USER_INFO: `${BASEPATH}/api/ms/registration/userDetails/fetch/`,
    FETCH_BASIC_USER_INFO: `${BASEPATH}/api/ms/registration/userDetails/fetchBasicUser/`,
    ALL_MASTER: `${BASEPATH}/api/ms/registration/masterService/getCountries`,
    FETCH_STATES: `${BASEPATH}/api/ms/registration/masterService/getStates`,
    FETCH_JOB_TITLES: `${BASEPATH}/api/web/registration/masterService/getJobTitles`,
    UPDATE_USER_INFO: `${BASEPATH}/api/ms/registration/userProfile/update`,
    UPDATE_BASIC_USER_INFO: `${BASEPATH}/api/ms/registration/userProfile/updateBasicUser`
};

export { API_URL };
