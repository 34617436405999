const SearchIcon = function (props: any) {
    let color = "";
    switch (props.color) {
        case "light-grey":
            color = "#ADB5BD";
            break;
        case "dark-grey":
            color = "#6A7178";
            break;
        default:
            color = "#ADB5BD";
            break;
    }
    return (
        <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.8457 11.1989C11.7296 10.315 12.1716 9.25362 12.1716 8.01478C12.1716 6.77594 11.7313 5.71623 10.8507 4.83565C9.97011 3.95507 8.9104 3.51478 7.67156 3.51478C6.43272 3.51478 5.37301 3.95507 4.49243 4.83565C3.61185 5.71623 3.17156 6.77594 3.17156 8.01478C3.17156 9.25362 3.61185 10.3133 4.49243 11.1939C5.37301 12.0745 6.43272 12.5148 7.67156 12.5148C8.9104 12.5148 9.96844 12.0762 10.8457 11.1989ZM17.3144 16.3719C17.3144 16.7201 17.1872 17.0215 16.9327 17.2759C16.6782 17.5304 16.3769 17.6576 16.0287 17.6576C15.6671 17.6576 15.3657 17.5304 15.1247 17.2759L11.6793 13.8407C10.4807 14.671 9.14474 15.0862 7.67152 15.0862C6.71393 15.0862 5.7982 14.9004 4.92431 14.5287C4.05043 14.1571 3.29708 13.6548 2.66427 13.022C2.03146 12.3892 1.52923 11.6359 1.15758 10.762C0.785923 9.88811 0.600098 8.97237 0.600098 8.01478C0.600098 7.0572 0.785923 6.14146 1.15758 5.26758C1.52923 4.39369 2.03146 3.64034 2.66427 3.00753C3.29708 2.37472 4.05043 1.87249 4.92431 1.50084C5.7982 1.12919 6.71393 0.943359 7.67152 0.943359C8.62911 0.943359 9.54485 1.12919 10.4187 1.50084C11.2926 1.87249 12.046 2.37472 12.6788 3.00753C13.3116 3.64034 13.8138 4.39369 14.1855 5.26758C14.5571 6.14146 14.7429 7.0572 14.7429 8.01478C14.7429 9.488 14.3278 10.8239 13.4974 12.0226L16.9427 15.4679C17.1905 15.7157 17.3144 16.017 17.3144 16.3719L17.3144 16.3719Z"
                fill={color}
            />
        </svg>
    );
};

export { SearchIcon };
