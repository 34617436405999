import React from "react";
import { useLocation } from "react-router";
import { getEnvVariables, getUserData, getUserDetails } from "../../store/global/global.slice";
import { useAppSelector } from "../../store/hook";
import { isLoggedIn } from "../../utils/utils";
import "./css/index.scss";
import { BASIC_UM_TAB, UM_TAB } from "../../utils/constants/constants";
import { AccessDenied } from "../../common-ui-modules/components/AccessDenied/AccessDenied";
import { NoAccess } from "../../common-ui-modules/components/NoAccess/NoAccess";

const SearchUser = React.lazy(() => import("./ui/pages/SearchUser/SearchUser"));
const BasicSearchUser = React.lazy(() => import("./ui/pages/BasicSearchUser/BasicSearchUser"));

const getPage = function (pageType: any, userDetails: any, isAdmin: any, isBasicAdmin: any) {
    const envVariables = useAppSelector(getEnvVariables);
    const isLogIn = isLoggedIn(envVariables);
    const userData = useAppSelector(getUserData);
    if (userData.userVO && userData.userVO.isAdminUser) {
        isAdmin = true;
    }
    if(userData.userVO && userData.userVO.isBasicAdmin){
        isBasicAdmin = true;
    }
    const isOEMUser = userData?.userVO?.isBSNAssistUser || false;
    switch (pageType) {
        case "/user-management":
            document.title=UM_TAB;
            return isLogIn && isOEMUser ? (
                <AccessDenied />
            ) : isLogIn && userDetails.email && isAdmin ? (
                <SearchUser />
            ) : (
                <NoAccess />
            );
        
        case "/basic-user-management":
            document.title=BASIC_UM_TAB;
            return isLogIn && isOEMUser ? (
                <AccessDenied />
            ) : isLogIn && userDetails.email && isBasicAdmin ? (
                <BasicSearchUser />
            ) : (
                <NoAccess />
            );

    }
};

const UserManagementPage = (props: any) => {
    const location = useLocation();
    const isAdmin = false;
    const isBasicAdmin = false;
    const userDetails = useAppSelector(getUserDetails);

    return <React.Fragment>{getPage(location.pathname, userDetails, isAdmin, isBasicAdmin)}</React.Fragment>;
};

export default UserManagementPage;
