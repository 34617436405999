const ArrowUpIcon = function (props: any) {
    let color = "";
    switch (props.color) {
        case "blue":
            color = "#005C8A";
            break;
        case "grey":
            color = "#ADB5BD";
            break;
        case "black":
            color = "#101213";
            break;
        default:
            color = "#005C8A";
            break;
    }

    return (
        <svg
            width="17"
            height="11"
            viewBox="0 0 17 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0.170757 9.52142C0.0591506 9.40981 0.00334538 9.28146 0.00334537 9.13637C0.00334536 8.99128 0.0591506 8.86293 0.170757 8.75133L7.9721 0.949984C8.0837 0.838377 8.21205 0.782573 8.35714 0.782573C8.50223 0.782573 8.63058 0.838377 8.74219 0.949984L16.5435 8.75132C16.6551 8.86293 16.7109 8.99128 16.7109 9.13637C16.7109 9.28146 16.6551 9.40981 16.5435 9.52141L15.7065 10.3585C15.5949 10.4701 15.4665 10.5259 15.3214 10.5259C15.1763 10.5259 15.048 10.4701 14.9364 10.3585L8.35714 3.77923L1.7779 10.3585C1.6663 10.4701 1.53795 10.5259 1.39286 10.5259C1.24777 10.5259 1.11942 10.4701 1.00781 10.3585L0.170757 9.52142Z"
                fill={color}
            />
        </svg>
    );
};

export { ArrowUpIcon };
