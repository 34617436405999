const IFIcon = function () {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            id="Icon_set"
            data-name="Icon set"
            viewBox="0 2 100 97"
            height="25"
            width="25"
        >
            <path d="M14.88,20.27a1.82,1.82,0,0,0,2.06-1.53,15.56,15.56,0,0,1,30.85.49,1.81,1.81,0,0,0,1.8,1.61h.21a1.81,1.81,0,0,0,1.59-2,19.19,19.19,0,0,0-38-.61A1.81,1.81,0,0,0,14.88,20.27Z" />
            <path d="M52.43,24.05a8.34,8.34,0,0,1,4.16,4.16,1.81,1.81,0,0,0,2.4.91,1.82,1.82,0,0,0,.91-2.4,12,12,0,0,0-6-6,1.81,1.81,0,1,0-1.49,3.3Z" />
            <path d="M16,41h-4a8.31,8.31,0,0,1,0-16.61h.12a1.82,1.82,0,0,0,0-3.63h-.17a11.94,11.94,0,0,0,0,23.87h4A1.82,1.82,0,0,0,16,41Z" />
            <path d="M73.4,34.85a8.78,8.78,0,0,1,8.77,8.77v2.26H85.8V43.62a12.42,12.42,0,0,0-12.4-12.4H31a12.42,12.42,0,0,0-12.4,12.4v52.9h3.63V43.62A8.78,8.78,0,0,1,31,34.85Z" />
            <rect x="70.54" y="43.04" width="4.84" height="4.84" rx="1.61" />
            <rect x="56.98" y="43.04" width="4.84" height="4.84" rx="1.61" />
            <rect x="43.44" y="43.04" width="4.84" height="4.84" rx="1.61" />
            <rect x="29.96" y="43.04" width="4.84" height="4.84" rx="1.61" />
            <rect x="56.98" y="55.23" width="4.84" height="4.84" rx="1.61" />
            <rect x="43.44" y="55.23" width="4.84" height="4.84" rx="1.61" />
            <rect x="29.96" y="55.23" width="4.84" height="4.84" rx="1.61" />
            <rect x="56.98" y="67.7" width="4.84" height="4.84" rx="1.61" />
            <rect x="43.44" y="67.7" width="4.84" height="4.84" rx="1.61" />
            <rect x="29.96" y="67.7" width="4.84" height="4.84" rx="1.61" />
            <rect x="56.98" y="79.99" width="4.84" height="4.84" rx="1.61" />
            <rect x="43.44" y="79.99" width="4.84" height="4.84" rx="1.61" />
            <rect x="29.96" y="79.99" width="4.84" height="4.84" rx="1.61" />
            <rect x="56.98" y="91.68" width="4.84" height="4.84" rx="1.61" />
            <rect x="43.44" y="91.68" width="4.84" height="4.84" rx="1.61" />
            <rect x="29.96" y="91.68" width="4.84" height="4.84" rx="1.61" />
            <circle
                cx="83.03"
                cy="92.37"
                r="1.74"
                transform="translate(-6.34 6.12) rotate(-4.07)"
            />
            <path d="M92.56,50H74.9a7.45,7.45,0,0,0-7.44,7.44V90.71a7.45,7.45,0,0,0,7.44,7.44H92.56A7.45,7.45,0,0,0,100,90.71V57.44A7.45,7.45,0,0,0,92.56,50Zm4.18,10.86V86.59h-26V60.86ZM74.9,53.26H92.56a4.18,4.18,0,0,1,4.18,4.18v.16h-26v-.16A4.18,4.18,0,0,1,74.9,53.26ZM92.56,94.89H74.9a4.18,4.18,0,0,1-4.18-4.18v-.85h26v.85A4.18,4.18,0,0,1,92.56,94.89Z" />
        </svg>
    );
};

export { IFIcon };
