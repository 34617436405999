const ProfAccessIcon = function () {
    return (
        <svg
            width="20"
            height="21"
            viewBox="0 0 20 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0)">
                <path
                    d="M12.9169 6.56527L15.8336 3.6486M17.5002 1.98193L15.8336 3.6486L17.5002 1.98193ZM9.4919 9.99027C9.92218 10.4148 10.2642 10.9203 10.4984 11.4776C10.7325 12.0349 10.8541 12.633 10.8561 13.2375C10.8581 13.8419 10.7405 14.4408 10.5102 14.9997C10.2798 15.5585 9.94111 16.0663 9.51368 16.4937C9.08625 16.9211 8.5785 17.2598 8.01965 17.4902C7.4608 17.7206 6.86189 17.8381 6.25742 17.8361C5.65295 17.8341 5.05485 17.7125 4.49755 17.4784C3.94026 17.2443 3.43478 16.9022 3.01023 16.4719C2.17534 15.6075 1.71336 14.4497 1.72381 13.248C1.73425 12.0463 2.21627 10.8967 3.06606 10.0469C3.91585 9.19714 5.0654 8.71512 6.26714 8.70468C7.46887 8.69423 8.62663 9.15621 9.49106 9.9911L9.4919 9.99027ZM9.4919 9.99027L12.9169 6.56527L9.4919 9.99027ZM12.9169 6.56527L15.4169 9.06527L18.3336 6.1486L15.8336 3.6486L12.9169 6.56527Z"
                    stroke="#272B30"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0">
                    <rect width="20" height="20" fill="white" transform="translate(0 0.31543)" />
                </clipPath>
            </defs>
        </svg>
    );
};

export { ProfAccessIcon };
