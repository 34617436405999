import ClayForm, { ClayInput } from "@clayui/form";
import { Field, ErrorMessage } from "formik";
import { IText } from "../interfaces/interfaces";
import TextError from "./TextError";

function Text(props: IText) {
    const { label, id, name, placeholder, type, validate, isMandatory } = props;
    return (
        <div>
            <label htmlFor={name}>
                {label}
                {isMandatory && <span className="text-red text-size-lg">* </span>}
            </label>
            <Field name={name} validate={validate}>
                {({ form, field }) => {
                    const { value } = field;
                    return (
                        <ClayForm.Group
                            className={
                                "form-group-sm0 " +
                                (form.errors[name] && form.touched[name] !== undefined
                                    ? "has-error"
                                    : "")
                            }
                        >
                            <ClayInput
                                type={type}
                                id={id}
                                value={value}
                                placeholder={placeholder}
                                onChange={field.onChange}
                                {...field}
                            />
                            <ErrorMessage name={name} component={TextError} />
                        </ClayForm.Group>
                    );
                }}
            </Field>
        </div>
    );
}

export default Text;
