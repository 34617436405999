import React from "react";
import ClayLayout from "@clayui/layout";

const RowDetail = (props: any) => {
    const {label, value} = props;
    return (
        <React.Fragment>
            <ClayLayout.Row justify="start" key={`random_${label}`}>
                <ClayLayout.Col size={3}>
                    <h3>{label}:</h3>
                </ClayLayout.Col>
                <ClayLayout.Col size={9}>
                    {value}
                </ClayLayout.Col>
            </ClayLayout.Row>
        </React.Fragment>
    );
};

export default RowDetail;