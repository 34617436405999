const ProfUserIcon = function () {
    return (
        <svg
            width="20"
            height="21"
            viewBox="0 0 20 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M16.6663 17.8149V16.1483C16.6663 15.2642 16.3152 14.4164 15.69 13.7913C15.0649 13.1661 14.2171 12.8149 13.333 12.8149H6.66634C5.78229 12.8149 4.93444 13.1661 4.30932 13.7913C3.6842 14.4164 3.33301 15.2642 3.33301 16.1483V17.8149"
                stroke="#272B30"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M9.99935 9.48161C11.8403 9.48161 13.3327 7.98922 13.3327 6.14828C13.3327 4.30733 11.8403 2.81494 9.99935 2.81494C8.1584 2.81494 6.66602 4.30733 6.66602 6.14828C6.66602 7.98922 8.1584 9.48161 9.99935 9.48161Z"
                stroke="#272B30"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export { ProfUserIcon };
