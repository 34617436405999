import { apiCallBegan } from "../../common-ui-modules/service/redux-helper/api-action-creator";
import { createSlice } from "@reduxjs/toolkit";
import { COMPANY_MANAGEMENT_API_URL } from "./constants";
import { companyListToDropdownFormat } from "../../modules/CompanyManagement/util/util";

const slice = createSlice({
    name: "companyManagement",
    initialState: {
        companyList: [],
        domainList: [],
        domainDataAvailable: false,
        domainAddUpdateSuccess: false,
        domainDeleteSuccess: false,
    },
    reducers: {
        setCompanyList: (companyManagement, action) => {
            companyManagement.companyList = action.payload?.data?.result;
        },
        domainList: (companyManagement, action) => {
            companyManagement.domainList = action.payload?.data?.result;
            companyManagement.domainDataAvailable = true;
        },
        resetCompanyList: (companyManagement, action) => {
            companyManagement.companyList = action.payload;
        },
        resetDomainList: (companyManagement, action) => {
            companyManagement.domainList = action.payload;
        },
        noDomainData: (companyManagement, action) => {
            companyManagement.domainDataAvailable = false;
        },
        domainAddUpdateSuccessful: (companyManagement, action) => {
            companyManagement.domainAddUpdateSuccess = action.payload.success;
        },
        domainDeleteSuccessful: (companyManagement, action) => {
            companyManagement.domainDeleteSuccess = action.payload.success;
        },
        updateDomainList: (companyManagement, action) => {
            const domainData = action.payload;
            companyManagement.domainList.forEach((item: any) => {
                if (item.domainName === domainData.domainName) {
                    item.recordActive = domainData.domainStatus;
                }
            });
        },
    },
});

export const getCompanyList = (searchText: string) => (dispatch: any) => {
    return dispatch(
        apiCallBegan({
            url: COMPANY_MANAGEMENT_API_URL.COMPANY_LIST,
            method: "POST",
            data: {
                searchString: searchText
            },
            onSuccess: setCompanyList.type,
        }),
    );
};

export const getDomainList = (companyName: string) => (dispatch: any) => {
    return dispatch(
        apiCallBegan({
            url: COMPANY_MANAGEMENT_API_URL.DOMAIN_LIST,
            method: "POST",
            data: {
                companyName: companyName
            },
            onSuccess: domainList.type,
            skipErrorHandling: true,
            onError: noDomainData.type,
        }),
    );
};

export const addUpdateDomainList =
    (companyId: number, domainName: string, activeStatus: string, createdBy: string) =>
    (dispatch: any) => {
        return dispatch(
            apiCallBegan({
                url: COMPANY_MANAGEMENT_API_URL.ADD_UPDATE_DOMAIN,
                method: "POST",
                data: {
                    companyId: companyId,
                    domainName: domainName,
                    recordActive: activeStatus,
                    createdBy: createdBy,
                },
                onSuccess: domainAddUpdateSuccessful.type,
                skipErrorHandling: true,
            }),
        );
    };

export const deleteDomain = (domainId: number) => (dispatch: any) => {
    return dispatch(
        apiCallBegan({
            url: `${COMPANY_MANAGEMENT_API_URL.DELETE_DOMAIN}${domainId}`,
            method: "DELETE",
            onSuccess: domainDeleteSuccessful.type,
            skipErrorHandling: true,
        }),
    );
};

export const resetCompanyListData = () => (dispatch: any) => {
    dispatch(resetCompanyList([]));
};

export const resetDomainListData = () => (dispatch: any) => {
    dispatch(resetDomainList([]));
};

export const resetAddUpdateDomainStatus = () => (dispatch: any) => {
    dispatch(domainAddUpdateSuccessful({ success: false }));
};

export const resetDomainDeleteStatus = () => (dispatch: any) => {
    dispatch(domainDeleteSuccessful({ success: false }));
};

export const updateDomainListData =
    (domainName: string, domainStatus: string) => (dispatch: any) => {
        dispatch(updateDomainList({ domainName, domainStatus }));
    };

const {
    setCompanyList,
    domainList,
    resetCompanyList,
    resetDomainList,
    noDomainData,
    domainAddUpdateSuccessful,
    domainDeleteSuccessful,
    updateDomainList,
} = slice.actions;

export const getCompanyListData = (state: any) => {
    const list = state?.companyManagement?.companyList || [];
    return companyListToDropdownFormat(list, "companyName", "companyName");
};

export const getDomainListData = (state: any) => {
    return state.companyManagement.domainList || [];
};

export const domainDataAvilable = (state: any) => {
    return state.companyManagement.domainDataAvailable;
};

export const domainAddUpdateStatus = (state: any) => {
    return state.companyManagement.domainAddUpdateSuccess;
};

export const domainDeleteStatus = (state: any) => {
    return state.companyManagement.domainDeleteSuccess;
};

export const companyManagementReducer = slice.reducer;
