const MaximizeIcon = function (props: any) {
    let color = "";
    switch (props.color) {
        case "light-grey":
            color = "#ADB5BD";
            break;
        case "dark-grey":
            color = "#4F575E";
            break;
        case "white":
            color = "white";
            break;
        default:
            color = "#4F575E";
            break;
    }
    return (
        <svg
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fill={color}
                d="M8 1H11.5V4.5"
                stroke={color}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                fill={color}
                d="M4.5 11.5H1V8"
                stroke={color}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                fill={color}
                d="M11.5001 1L7.41675 5.08333"
                stroke={color}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                fill={color}
                d="M1 11.5L5.08333 7.41666"
                stroke={color}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export { MaximizeIcon };
