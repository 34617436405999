import { Suspense } from "react";

import { PageNotFound, ErrorBoundary, GlobalAPILoader } from "./common-ui-modules/components/index";

import { Route, Routes } from "react-router-dom";
import { routesConfig } from "./utils/constants/routeConstants";
import { ExternalBanner } from "./common-ui-modules/components/ExternalBanner/ExternalBanner";
function Routing() {
    const ProtectedRoutes = routesConfig.map((route, key) => (
        <Route
            path={route.path}
            key={key}
            element={
                <>
                    <ErrorBoundary>
                        <ExternalBanner />
                    </ErrorBoundary>
                    <ErrorBoundary>
                        <Suspense fallback={<GlobalAPILoader showLoader={true} />}>
                            <route.component />
                        </Suspense>
                    </ErrorBoundary>
                </>
            }
        />
    ));
    return (
        <Routes>
            {ProtectedRoutes}
            <Route path="*" element={<PageNotFound />} />
        </Routes>
    );
}

export default Routing;
