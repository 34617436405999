const CounterLicenseIcon = function () {
    return (
        <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle opacity="0.5" cx="20" cy="20" r="20" fill="#101213" />
            <path
                d="M20 23C23.866 23 27 19.866 27 16C27 12.134 23.866 9 20 9C16.134 9 13 12.134 13 16C13 19.866 16.134 23 20 23Z"
                stroke="#ADB5BD"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M16.21 21.8899L15 30.9999L20 27.9999L25 30.9999L23.79 21.8799"
                stroke="#ADB5BD"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export { CounterLicenseIcon };
