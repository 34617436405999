import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ClayDropDown from "@clayui/drop-down";
import { ROUTE_PATHS } from "../../../utils/constants/routeConstants";
import { getEnvVariables, getUserData } from "../../../store/global/global.slice";
import { ProfLogoutIcon } from "../icons/profileLogout";
import "./header.scss";
import { ArrowDownIcon } from "../icons/arrowDownIcon";
import { ArrowUpIcon } from "../icons/arrowUpIcon";

function Header(props: any) {
    const [showMenuInResponsiveView, setShowMenuInResponsiveView] = useState(false);

    const [active, setActive] = useState(false);
    const [isSignedIn, setIsSignedIn] = useState(false);

    const userData = useSelector(getUserData);
    const envVariables = useSelector(getEnvVariables);

    useEffect(() => {
        if (userData && userData.userVO) {
            setIsSignedIn(true);
        }
    }, [userData]);

    const login = async () => {
        window.location.href = envVariables.ui.supportPortal + envVariables.ui.spLoginUrl;
    };
    const logout = async (event: any) => {
        if (event) {
            event.preventDefault();
        }
        window.location.href = ROUTE_PATHS.LOGOUT;
    };
    const registerOktaUser: string = ROUTE_PATHS.REGISTRATION;

    return (
        <React.Fragment>
            <header className="brdcm-standard-header brdcm-fade-in">
                <div className="brdcm-header-top">
                    <div className="brdcm-container brdcm-navigation-primary">
                        <div className="brdcm-branding brdcm-tablet-hidden">
                            <a
                                className="brdcm-brand-logo"
                                target="_blank"
                                href="https://broadcom.com"
                                rel="noreferrer noopener"
                            ></a>
                        </div>

                        <div className="brdcm-primary-nav-container">
                            <div
                                className="brdcm-primary-menu brdcm-tablet-visible pl-4 pl-lg-0"
                                onClick={() =>
                                    setShowMenuInResponsiveView(!showMenuInResponsiveView)
                                }
                            >
                                <button>
                                    <div></div>
                                </button>
                                <div className="brdcm-primary-menu-label">Menu</div>
                            </div>

                            <div className="brdcm-branding d-block d-lg-none pl-4 pl-lg-0">
                                <a
                                    className="brdcm-brand-logo"
                                    target="_blank"
                                    href="https://broadcom.com"
                                    rel="noreferrer noopener"
                                ></a>
                            </div>

                            <div
                                className={`brdcm-primary-nav-links-wrapper brdcm-fade-in ${
                                    showMenuInResponsiveView ? "d-block" : "brdcm-tablet-hidden"
                                }`}
                            >
                                <ul className="brdcm-primary-nav-links">
                                    <li className="brdcm-primary-nav-item">
                                        <a
                                            className="brdcm-primary-nav-link"
                                            target="_blank"
                                            href="https://www.broadcom.com/products/"
                                            rel="noreferrer noopener"
                                        >
                                            Products
                                        </a>
                                    </li>
                                    <li className="brdcm-primary-nav-item">
                                        <a
                                            className="brdcm-primary-nav-link"
                                            target="_blank"
                                            href="https://www.broadcom.com/solutions"
                                            rel="noreferrer noopener"
                                        >
                                            Solutions
                                        </a>
                                    </li>
                                    <li className="brdcm-primary-nav-item">
                                        <a
                                            className="brdcm-primary-nav-link"
                                            target="_blank"
                                            href="https://www.broadcom.com/support"
                                            rel="noreferrer noopener"
                                        >
                                            Support and Services
                                        </a>
                                    </li>
                                    <li className="brdcm-primary-nav-item">
                                        <a
                                            className="brdcm-primary-nav-link"
                                            target="_blank"
                                            href="https://www.broadcom.com/company/about-us/"
                                            rel="noreferrer noopener"
                                        >
                                            Company
                                        </a>
                                    </li>
                                    <li className="brdcm-primary-nav-item">
                                        <a
                                            className="brdcm-primary-nav-link"
                                            target="_blank"
                                            href="https://www.broadcom.com/how-to-buy/#sales"
                                            rel="noreferrer noopener"
                                        >
                                            How To Buy
                                        </a>
                                    </li>
                                </ul>

                                <div className="secondary_nav brdcm-tablet-visible"></div>
                            </div>

                            <div className="brdcm-nav-logged-in-details pr-md-4">
                                <div className="brdcm-profile-container brdcm-modal-dropdown-wrapper">
                                    {isSignedIn ? (
                                        <ClayDropDown
                                            trigger={
                                                <div className="profile-user-name pe-cursor d-flex">
                                                    <span
                                                        className="text-truncate d-md-inline-block pr-2 font-weight-bold d-none header-dropdown-text-container"
                                                        id="user-name"
                                                        data-toggle="dropdown"
                                                        aria-haspopup="true"
                                                        aria-expanded="false"
                                                    >
                                                        {userData.userVO.firstName
                                                            ? userData.userVO.firstName +
                                                              " " +
                                                              (userData.userVO.lastName
                                                                  ? userData.userVO.lastName
                                                                  : "")
                                                            : ""}
                                                        &emsp;
                                                        {active ? (
                                                            <ArrowUpIcon />
                                                        ) : (
                                                            <ArrowDownIcon />
                                                        )}
                                                    </span>
                                                </div>
                                            }
                                            active={active}
                                            onActiveChange={setActive}
                                            menuElementAttrs={{
                                                className: "my-custom-dropdown-menu",
                                                containerProps: {
                                                    className: "dropdown-menu-react-portal-div",
                                                    id: "dropdownMenuReactPortalDiv",
                                                },
                                            }}
                                        >
                                            <ClayDropDown.ItemList>
                                                <ClayDropDown.Group>
                                                    <ClayDropDown.Item href={""} onClick={logout}>
                                                        <ProfLogoutIcon />
                                                        &nbsp; &nbsp;
                                                        <span className="btnStyle">Logout</span>
                                                    </ClayDropDown.Item>
                                                </ClayDropDown.Group>
                                            </ClayDropDown.ItemList>
                                        </ClayDropDown>
                                    ) : (
                                        <>
                                            <a
                                                className="btn btn-secondary brdcm-login-register-btn m-1"
                                                href={registerOktaUser}
                                            >
                                                Register
                                            </a>
                                            <a
                                                className="btn btn-primary brdcm-login-register-btn"
                                                onClick={login}
                                            >
                                                Login
                                            </a>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div></div>
                    </div>
                </div>
            </header>
        </React.Fragment>
    );
}

export { Header };
