export const ResetIcon = (props: any) => {
    let color = "#005C8A";
    switch (props.color) {
        case "blue":
            color = "#005C8A";
            break;
        case "grey":
            color = "#ADB5BD";
            break;
        default:
            color = "#005C8A";
            break;
    }
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M7.236 2.964C7.40454 3.13275 7.49921 3.3615 7.49921 3.6C7.49921 3.8385 7.40454 4.06725 7.236 4.236L5.772 5.7H13.2C14.6834 5.7 16.1334 6.13987 17.3668 6.96398C18.6001 7.78809 19.5614 8.95943 20.1291 10.3299C20.6968 11.7003 20.8453 13.2083 20.5559 14.6632C20.2665 16.118 19.5522 17.4544 18.5033 18.5033C17.4544 19.5522 16.118 20.2665 14.6632 20.5559C13.2083 20.8453 11.7003 20.6968 10.3299 20.1291C8.95943 19.5614 7.78809 18.6001 6.96398 17.3668C6.13987 16.1334 5.7 14.6834 5.7 13.2C5.7 12.9613 5.79482 12.7324 5.9636 12.5636C6.13239 12.3948 6.36131 12.3 6.6 12.3C6.83869 12.3 7.06761 12.3948 7.2364 12.5636C7.40518 12.7324 7.5 12.9613 7.5 13.2C7.5 14.3274 7.8343 15.4294 8.46062 16.3667C9.08695 17.3041 9.97717 18.0347 11.0187 18.4661C12.0602 18.8975 13.2063 19.0104 14.312 18.7905C15.4177 18.5705 16.4333 18.0277 17.2305 17.2305C18.0277 16.4333 18.5705 15.4177 18.7905 14.312C19.0104 13.2063 18.8975 12.0602 18.4661 11.0187C18.0347 9.97716 17.3041 9.08695 16.3668 8.46062C15.4294 7.8343 14.3274 7.5 13.2 7.5H5.772L7.236 8.964C7.39498 9.13461 7.48152 9.36026 7.47741 9.59343C7.4733 9.82659 7.37884 10.049 7.21395 10.2139C7.04905 10.3788 6.82659 10.4733 6.59343 10.4774C6.36027 10.4815 6.13461 10.395 5.964 10.236L2.964 7.236C2.79546 7.06725 2.70079 6.8385 2.70079 6.6C2.70079 6.3615 2.79546 6.13275 2.964 5.964L5.964 2.964C6.13275 2.79546 6.3615 2.70079 6.6 2.70079C6.8385 2.70079 7.06725 2.79546 7.236 2.964V2.964Z"
                fill={color}
            />
        </svg>
    );
};
